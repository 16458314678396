<template>
  <div v-if="!this.$func.checkPermission('ระบบคูปองกงล้อ')" class="row">
    <div class="col">
      <div class="card">
        <div class="card-body">
          <div class="alert alert-danger" role="alert">
            <strong>คุณไม่มีสิทธิ์เข้าถึงหน้านี้</strong>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div v-else>
    <div class="row">
      <div class="col-12">
        <h3 class="mb-3 mt-0">ระบบคูปองกงล้อ</h3>
        <card>
          <div slot="header">
            <button class="btn btn-primary btn-fill mb-0" @click="addSpin()">
              <i class="fa fa-plus" aria-hidden="true"></i>
              สร้างกิจกรรม
            </button>
          </div>
          <div class="row">
            <div class="col-sm-3 d-inline-block">
              <el-select
                class="select-default mb-3 w-100"
                v-model="pagination.perPage"
                placeholder="Per page"
              >
                <el-option
                  class="select-default"
                  v-for="item in pagination.perPageOptions"
                  :key="item"
                  :label="item"
                  :value="item"
                >
                </el-option>
              </el-select>
            </div>
            <div class="col-sm-3 offset-sm-6 d-inline-block text-right">
              <!-- <el-input
                type="search"
                class="mb-3"
                placeholder="ค้นหา"
                v-model="searchQuery"
                aria-controls="datatables"
              /> -->
              <el-button
                type="danger"
                size="medium"
                @click="getCouponSpin()"
                :loading="loading"
                ><i class="fa fa-refresh" aria-hidden="true"></i
              ></el-button>
            </div>
          </div>
          <div class="row">
            <div class="col-sm-12">
              <el-table stripe :data="tableData" border v-loading="loading">
                <el-table-column :min-width="50" label="ลำดับ">
                  <template v-slot="props">
                    <span v-html="props ? props.row.D1 : ''"></span>
                  </template>
                </el-table-column>
                <el-table-column :min-width="100" label="ชื่อกิจกรรม">
                  <template v-slot="props">
                    <span
                      v-html="props ? props.row.D2 : ''"
                      class="mr-2"
                    ></span>
                    <span
                      @click="showCouponCode(props.row.spin_id)"
                      class="bg-info p-1 text-white rounded overflow-hidden"
                      style="cursor: pointer"
                    >
                      <i class="fa fa-search"></i>
                    </span>
                  </template>
                </el-table-column>
                <el-table-column
                  :min-width="100"
                  label="จำนวนที่หมุนได้ (ต่อ 1 คูปอง)"
                >
                  <template v-slot="props">
                    <span v-html="props ? props.row.D3 : ''"> </span>
                  </template>
                </el-table-column>
                <el-table-column :min-width="100" label="ใช้ไปแล้ว (คูปอง)">
                  <template v-slot="props">
                    <span v-html="props ? props.row.D4 : ''"> </span>
                  </template>
                </el-table-column>
                <el-table-column :min-width="100" label="เปอร์เซ็นต์">
                  <template v-slot="props">
                    <span v-html="props ? props.row.D5 : ''"></span>
                  </template>
                </el-table-column>
                <el-table-column :min-width="100" label="วันที่สร้าง">
                  <template v-slot="props">
                    <span v-html="props ? props.row.D6 : ''"></span>
                  </template>
                </el-table-column>

                <el-table-column :min-width="150" fixed="right" label="จัดการ">
                  <template v-slot="props">
                    <div v-if="props" class="d-flex flex-wrap">
                      <button
                        @click="addCouponSpinCode(props.row.spin_id)"
                        type="button"
                        class="btn btn-primary btn-sm mr-1"
                      >
                        <i class="fa fa-plus" aria-hidden="true"></i>
                        <span>เพิ่มคูปอง</span>
                      </button>
                      <button
                        @click="editCoupon(props.row.spin_id)"
                        type="button"
                        class="btn btn-warning btn-sm mr-1"
                      >
                        <i class="fa fa-edit" aria-hidden="true"></i>
                        <span>แก้ไข</span>
                      </button>
                      <button
                        @click="deleteCoupon(props.row.spin_name)"
                        type="button"
                        class="btn btn-danger btn-sm mr-1"
                      >
                        <i class="fa fa-trash" aria-hidden="true"></i>
                        <span>ลบ</span>
                      </button>
                    </div>
                  </template>
                </el-table-column>
              </el-table>
            </div>
          </div>
          <div
            slot="footer"
            class="col-12 d-flex justify-content-center justify-content-sm-between flex-wrap"
          >
            <div class="">
              <p class="card-category">
                Showing {{ from + 1 }} to {{ to }} of {{ total }} entries
              </p>
            </div>
            <l-pagination
              class="pagination-no-border"
              v-model="pagination.currentPage"
              :per-page="pagination.perPage"
              :total="pagination.total"
            >
            </l-pagination>
          </div>
        </card>
      </div>
    </div>
    <el-dialog :visible.sync="modals.modalAddSpin" width="30%">
      <span slot="title">
        <i class="fa fa-plus" aria-hidden="true"></i>
        สร้างกิจกรรมกงล้อ
      </span>
      <div>
        <form>
          <div>
            <fg-input
              v-model="formAddSpin.spin_name"
              type="text"
              placeholder="กรอกชื่อกิจกรรม"
            >
              <span slot="label"> ชื่อกิจกรรม </span>
            </fg-input>
          </div>
          <div>
            <fg-input
              v-model="formAddSpin.spin_point"
              type="number"
              placeholder="กรอกจำนวนที่หมุนได้ (ต่อ 1 คูปอง)"
            >
              <span slot="label"> จำนวนที่หมุนได้ (ต่อ 1 คูปอง) </span>
            </fg-input>
          </div>
          <div class="card">
            <div class="card-header">เปอร์เซ็นต์ที่ออก</div>
            <div class="card-body">
              <fg-input
                v-model="formAddSpin.spin_0"
                type="number"
                placeholder="กรอกเปอร์เซ็นต์"
                @change="getPercent()"
                :min="0"
                :max="100"
              >
                <span slot="label"> 0 เครดิต (%) </span>
              </fg-input>
              <fg-input
                v-model="formAddSpin.spin_free"
                type="number"
                placeholder="กรอกเปอร์เซ็นต์"
                @change="getPercent()"
                :min="0"
                :max="100"
              >
                <span slot="label"> หมุนฟรี 1 ครั้ง (%) </span>
              </fg-input>
              <fg-input
                v-model="formAddSpin.spin_5"
                type="number"
                placeholder="กรอกเปอร์เซ็นต์"
                @change="getPercent()"
                :min="0"
                :max="100"
              >
                <span slot="label"> 5 เครดิต (%) </span>
              </fg-input>
              <fg-input
                v-model="formAddSpin.spin_10"
                type="number"
                placeholder="กรอกเปอร์เซ็นต์"
                @change="getPercent()"
                :min="0"
                :max="100"
              >
                <span slot="label"> 10 เครดิต (%) </span>
              </fg-input>
              <div v-if="percent <= 100 && percent != 0" class="text-success">
                เปอร์เซ็นต์รวม: {{ percent }}%
              </div>
              <div v-else class="text-danger">
                เปอร์เซ็นต์รวม: {{ percent }}%
              </div>
            </div>
          </div>
        </form>
      </div>
      <span slot="footer" class="dialog-footer" v-loading="loadingSubmit">
        <button class="btn btn-success mr-2" @click="submitAddSpin()">
          เพิ่ม
        </button>
        <button class="btn btn-danger" @click="closeModal('modalAddSpin')">
          ยกเลิก
        </button>
      </span>
    </el-dialog>
    <el-dialog :visible.sync="modals.modalAddSpinCode" width="30%">
      <span slot="title">
        <i class="fa fa-plus" aria-hidden="true"></i>
        เพิ่มคูปอง
      </span>
      <div>
        <form>
          <input type="hidden" v-model="formAddSpinCode.spin_id" />
          <div class="form-group">
            <label for="">รหัสคูปอง</label>
            <div class="d-flex">
              <input
                v-model="formAddSpinCode.spin_code"
                type="text"
                class="form-control mr-2 my-auto"
                name=""
                id=""
                aria-describedby="helpId"
                placeholder=""
                disabled
              />
              <button
                @click="createCouponSpinCode()"
                type="button"
                class="btn btn-primary my-auto"
              >
                <i class="fa fa-refresh" aria-hidden="true"></i>
              </button>
            </div>
          </div>
        </form>
      </div>
      <span slot="footer" class="dialog-footer" v-loading="loadingSubmit">
        <button class="btn btn-success mr-2" @click="submitAddSpinCode()">
          เพิ่มและคัดลอก
        </button>
        <button class="btn btn-danger" @click="closeModal('modalAddSpinCode')">
          ยกเลิก
        </button>
      </span>
    </el-dialog>
    <el-dialog :visible.sync="modals.modalShowSpinCode" width="60%">
      <span slot="title">
        <i class="fa fa-search" aria-hidden="true"></i>
        รายละเอียด
      </span>
      <div>
        <card>
          <div class="row">
            <div class="col-sm-3 d-inline-block">
              <el-select
                class="select-default mb-3 w-100"
                v-model="paginationCouponHistory.perPage"
                placeholder="Per page"
              >
                <el-option
                  class="select-default"
                  v-for="item in paginationCouponHistory.perPageOptions"
                  :key="item"
                  :label="item"
                  :value="item"
                >
                </el-option>
              </el-select>
            </div>
            <div class="col-sm-3 offset-sm-6 d-inline-block text-right">
              <!-- <el-input
                type="search"
                class="mb-3"
                placeholder="ค้นหา"
                v-model="searchQuery"
                aria-controls="datatables"
              /> -->
              <button
                @click="getCouponCodeHistory()"
                type="button"
                class="btn btn-danger my-auto"
              >
                <i class="fa fa-refresh" aria-hidden="true"></i>
              </button>
            </div>
          </div>
          <div class="row">
            <div class="col-sm-12">
              <el-table
                stripe
                :data="tableDataSpinHistory"
                border
                v-loading="loadingCode"
              >
                <el-table-column :min-width="75" label="เวลาสร้าง">
                  <template v-slot="props">
                    <span v-html="props ? props.row.D1 : ''"> </span>
                  </template>
                </el-table-column>
                <el-table-column :min-width="100" label="รหัสคูปอง">
                  <template v-slot="props">
                    <span
                      v-html="props ? props.row.spin_code : ''"
                      :id="`copy_` + props.row.spin_code_id"
                    >
                    </span>
                  </template>
                </el-table-column>
                <el-table-column :min-width="75" label="สถานะ">
                  <template v-slot="props">
                    <span v-html="props ? props.row.D3 : ''"> </span>
                  </template>
                </el-table-column>
                <el-table-column :min-width="75" label="วันหมดอายุ">
                  <template v-slot="props">
                    <span v-html="props ? props.row.D4 : ''"> </span>
                  </template>
                </el-table-column>
                <el-table-column :min-width="75" label="ผู้ใช้">
                  <template v-slot="props">
                    <span v-html="props.row.D5 ? props.row.D5 : '-'"> </span>
                  </template>
                </el-table-column>
                <el-table-column :min-width="75" label="เวลาใช้">
                  <template v-slot="props">
                    <span v-html="props.row.D6 ? props.row.D6 : '-'"> </span>
                  </template>
                </el-table-column>
                <el-table-column :min-width="100" fixed="right" label="คัดลอก">
                  <template v-slot="props">
                    <div v-if="props">
                      <button
                        @click="copySpinCode(props.row.spin_code_id)"
                        type="button"
                        class="btn btn-success btn-sm"
                      >
                        <i class="fa fa-copy"></i>
                        <span>คัดลอก</span>
                      </button>
                    </div>
                  </template>
                </el-table-column>
              </el-table>
            </div>
          </div>
          <div
            slot="footer"
            class="col-12 d-flex justify-content-center justify-content-sm-between flex-wrap"
          >
            <div class="">
              <p class="card-category">
                Showing {{ fromCouponHistory + 1 }} to {{ toCouponHistory }} of
                {{ totalCouponHistory }} entries
              </p>
            </div>
            <l-pagination
              class="pagination-no-border"
              v-model="paginationCouponHistory.currentPage"
              :per-page="paginationCouponHistory.perPage"
              :total="paginationCouponHistory.total"
            >
            </l-pagination>
          </div>
        </card>
      </div>
      <span slot="footer" class="dialog-footer">
        <button class="btn btn-danger" @click="closeModal('modalShowSpinCode')">
          ปิด
        </button>
      </span>
    </el-dialog>
    <el-dialog :visible.sync="modals.modalEditCoupon" width="30%">
      <span slot="title">
        <i class="fa fa-edit" aria-hidden="true"></i>
        แก้ไขกิจกรรม
      </span>
      <div>
        <form>
          <div>
            <fg-input
              v-model="formAddSpin.spin_name"
              type="text"
              placeholder="กรอกชื่อกิจกรรม"
            >
              <span slot="label"> ชื่อกิจกรรม </span>
            </fg-input>
          </div>
          <div>
            <fg-input
              v-model="formAddSpin.spin_point"
              type="number"
              placeholder="กรอกจำนวนที่หมุนได้ (ต่อ 1 คูปอง)"
            >
              <span slot="label"> จำนวนที่หมุนได้ (ต่อ 1 คูปอง) </span>
            </fg-input>
          </div>
          <div class="card">
            <div class="card-header">เปอร์เซ็นต์ที่ออก</div>
            <div class="card-body">
              <fg-input
                v-model="formAddSpin.spin_0"
                type="number"
                placeholder="กรอกเปอร์เซ็นต์"
                @change="getPercent()"
                :min="0"
                :max="100"
              >
                <span slot="label"> 0 เครดิต (%) </span>
              </fg-input>
              <fg-input
                v-model="formAddSpin.spin_free"
                type="number"
                placeholder="กรอกเปอร์เซ็นต์"
                @change="getPercent()"
                :min="0"
                :max="100"
              >
                <span slot="label"> หมุนฟรี 1 ครั้ง (%) </span>
              </fg-input>
              <fg-input
                v-model="formAddSpin.spin_5"
                type="number"
                placeholder="กรอกเปอร์เซ็นต์"
                @change="getPercent()"
                :min="0"
                :max="100"
              >
                <span slot="label"> 5 เครดิต (%) </span>
              </fg-input>
              <fg-input
                v-model="formAddSpin.spin_10"
                type="number"
                placeholder="กรอกเปอร์เซ็นต์"
                @change="getPercent()"
                :min="0"
                :max="100"
              >
                <span slot="label"> 10 เครดิต (%) </span>
              </fg-input>
              <div v-if="percent <= 100 && percent != 0" class="text-success">
                เปอร์เซ็นต์รวม: {{ percent }}%
              </div>
              <div v-else class="text-danger">
                เปอร์เซ็นต์รวม: {{ percent }}%
              </div>
            </div>
          </div>
        </form>
      </div>
      <span slot="footer" class="dialog-footer">
        <button class="btn btn-success mr-2" @click="submitUpdateCoupon()">
          บันทึก
        </button>
        <button class="btn btn-danger" @click="closeModal('modalEditCoupon')">
          ยกเลิก
        </button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
import {
  Dialog,
  Table,
  TableColumn,
  Select,
  Option,
  Button,
  Form,
} from "element-ui";
import Swal from "sweetalert2";

import { Pagination as LPagination } from "src/components/index";
import axios from "axios";
export default {
  components: {
    LPagination,
    [Form.name]: Form,
    [Button.name]: Button,
    [Dialog.name]: Dialog,
    [Select.name]: Select,
    [Option.name]: Option,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
  },
  data() {
    return {
      loading: true,
      loadingCode: true,
      loadingSubmit: false,
      formAddSpin: {
        spin_id: "",
        spin_name: "",
        spin_point: "",
        spin_0: "",
        spin_free: "",
        spin_5: "",
        spin_10: "",
      },
      percent: 0,
      formAddSpinCode: {
        spin_id: "",
        spin_code: "",
      },

      showCouponCodeId: "",
      modals: {
        modalShowSpinCode: false,
        modalAddSpin: false,
        modalAddSpinCode: false,
        modalAddSpinCodeMulti: false,
        modalEditCoupon: false,
        centered: false,
        custom: false,
        confirm: false,
      },

      pagination: {
        perPage: 10,
        currentPage: 1,
        perPageOptions: [10, 20, 30, 50],
        total: 0,
      },

      paginationCouponHistory: {
        perPage: 10,
        currentPage: 1,
        perPageOptions: [10, 20, 30, 50],
        total: 0,
      },
      searchQuery: "",
      propsToSearch: ["username"],
      tableColumns: [],
      tableData: [],
      tableDataSpinHistory: [],
      fuseSearch: null,
    };
  },
  computed: {
    pagedData() {
      return this.tableData.slice(this.from, this.to);
    },

    queriedData() {
      let result = this.tableData;
      if (this.searchQuery !== "") {
        result = this.fuseSearch.search(this.searchQuery);
        this.paginationTotal(result.length);
      }
      return result.slice(this.from, this.to);
    },
    to() {
      let highBound = this.from + this.pagination.perPage;
      if (this.total < highBound) {
        highBound = this.total;
      }
      return highBound;
    },
    from() {
      this.getCouponSpin();
      return this.pagination.perPage * (this.pagination.currentPage - 1);
    },
    total() {
      return this.pagination.total;
    },

    toCouponHistory() {
      let highBound =
        this.fromCouponHistory + this.paginationCouponHistory.perPage;
      if (this.totalCouponHistory < highBound) {
        highBound = this.totalCouponHistory;
      }
      return highBound;
    },
    fromCouponHistory() {
      if (this.showCouponCodeId) {
        // this.getCouponCodeHistory();
      }
      return (
        this.paginationCouponHistory.perPage *
        (this.paginationCouponHistory.currentPage - 1)
      );
    },
    totalCouponHistory() {
      return this.paginationCouponHistory.total;
    },
  },
  methods: {
    createCouponSpinCode() {
      this.formAddSpinCode.spin_code = this.makeCouponCode();
    },
    makeCouponCode() {
      let result = "";
      const characters =
        "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
      const charactersLength = characters.length;
      let counter = 0;
      while (counter < 10) {
        result += characters.charAt(
          Math.floor(Math.random() * charactersLength)
        );
        counter += 1;
      }
      return result;
    },
    paginationTotal(value) {
      this.pagination.total = value;
    },

    openModal(name) {
      this.modals[name] = true;
    },
    closeModal(name) {
      this.modals[name] = false;
    },
    async getCouponSpin() {
      this.loading = true;
      let config = {
        method: "get",
        maxBodyLength: Infinity,
        url: `${this.$hostname}/coupon_spin?search=&sort=&order=desc&offset=0&limit=10&spin_id=`,
        headers: {
          authorization: this.$cookies.get("token"),
        },
      };

      await axios
        .request(config)
        .then((res) => {
          this.loading = false;
          this.tableData = [];
          const data = res.data;
          if (data.res_code === "00") {
            this.tableData = data.res_result.rows;
            this.pagination.total = data.res_result.total;
          }
        })
        .catch((error) => {
          this.loading = false;
        });
    },

    getPercent() {
      let percent =
        Number(this.formAddSpin.spin_0) +
        Number(this.formAddSpin.spin_free) +
        Number(this.formAddSpin.spin_5) +
        Number(this.formAddSpin.spin_10);
      this.percent = percent;
    },
    addSpin() {
      this.formAddSpin.spin_name = "";
      this.formAddSpin.spin_point = "";
      this.formAddSpin.spin_0 = "";
      this.formAddSpin.spin_free = "";
      this.formAddSpin.spin_5 = "";
      this.formAddSpin.spin_10 = "";

      this.modals["modalAddSpin"] = true;
    },
    async submitAddSpin() {
      if (
        !this.formAddSpin.spin_name ||
        !this.formAddSpin.spin_point ||
        !this.formAddSpin.spin_0 ||
        !this.formAddSpin.spin_free ||
        !this.formAddSpin.spin_5 ||
        !this.formAddSpin.spin_10
      ) {
        return Swal.fire("แจ้งเตือน", "กรุณากรอกข้อมูลให้ครบ", "error");
      }

      if (this.percent !== 100) {
        return Swal.fire("แจ้งเตือน", "ผลรวมเปอร์เซ็นต์ต้องเต็ม 100%", "error");
      }

      let data = JSON.stringify({
        spin_name: this.formAddSpin.spin_name,
        spin_point: this.formAddSpin.spin_point,
        spin_0: this.formAddSpin.spin_0,
        spin_free: this.formAddSpin.spin_free,
        spin_5: this.formAddSpin.spin_5,
        spin_10: this.formAddSpin.spin_10,
      });

      this.loadingSubmit = true;

      let config = {
        method: "post",
        maxBodyLength: Infinity,
        url: `${this.$hostname}/addspin`,
        headers: {
          authorization: this.$cookies.get("token"),
          "Content-Type": "application/json",
        },
        data: data,
      };

      await axios
        .request(config)
        .then((res) => {
          this.loadingSubmit = false;
          this.tableData = [];
          let data = res.data;
          if (data.res_code == "00") {
            this.getCouponSpin();

            Swal.fire({
              title: "แจ้งเตือน",
              text: data.res_text,
              icon: "success",
              timer: 1500,
              showConfirmButton: false,
            });

            this.formAddSpin.spin_name = "";
            this.formAddSpin.spin_point = "";
            this.formAddSpin.spin_0 = "";
            this.formAddSpin.spin_free = "";
            this.formAddSpin.spin_5 = "";
            this.formAddSpin.spin_10 = "";

            this.modals["modalAddSpin"] = false;
          } else {
            return Swal.fire("แจ้งเตือน", data.res_text, "error");
          }
        })
        .catch((error) => {
          this.loadingSubmit = false;
        });
    },
    addCouponSpinCode(id) {
      this.formAddSpinCode.spin_id = id;
      this.createCouponSpinCode();
      this.modals["modalAddSpinCode"] = true;
    },
    async submitAddSpinCode() {
      if (!this.formAddSpinCode.spin_id || !this.formAddSpinCode.spin_code) {
        return Swal.fire("แจ้งเตือน", "กรุณากรอกข้อมูลให้ครบ", "error");
      }
      let data = JSON.stringify({
        spin_id: this.formAddSpinCode.spin_id,
        spin_code: this.formAddSpinCode.spin_code,
      });

      this.loadingSubmit = true;

      let config = {
        method: "post",
        maxBodyLength: Infinity,
        url: `${this.$hostname}/insert_spin_code`,
        headers: {
          authorization: this.$cookies.get("token"),
          "Content-Type": "application/json",
        },
        data: data,
      };

      await axios
        .request(config)
        .then((res) => {
          this.loadingSubmit = false;
          this.tableData = [];
          let data = res.data;
          if (data.res_code == "00") {
            this.getCouponSpin();
            Swal.fire({
              title: "แจ้งเตือน",
              text: data.res_text,
              icon: "success",
              timer: 1500,
              showConfirmButton: false,
            });
            try {
              navigator.clipboard.writeText(this.formAddSpinCode.spin_code);
            } catch (err) {}

            this.formAddSpinCode.spin_id = "";
            this.formAddSpinCode.spin_code = "";
            this.modals["modalAddSpinCode"] = false;
          } else {
            return Swal.fire("แจ้งเตือน", data.res_text, "error");
          }
        })
        .catch((error) => {
          this.loadingSubmit = false;
        });
    },

    async showCouponCode(id) {
      this.showCouponCodeId = id;
      await this.getCouponCodeHistory();
      this.modals["modalShowSpinCode"] = true;
    },
    async getCouponCodeHistory() {
      this.loadingCode = true;
      let offset =
        this.paginationCouponHistory.perPage *
        (this.paginationCouponHistory.currentPage - 1);

      let config = {
        method: "get",
        maxBodyLength: Infinity,
        url: `${this.$hostname}/coupon_spin_history?search=&sort=&order=desc&offset=${offset}&limit=${this.paginationCouponHistory.perPage}&spin_id=${this.showCouponCodeId}`,
        headers: {
          authorization: this.$cookies.get("token"),
        },
      };

      await axios
        .request(config)
        .then((res) => {
          this.loadingCode = false;
          this.tableDataSpinHistory = [];
          let data = res.data;
          if (data.res_code === "00") {
            this.tableDataSpinHistory = data.res_result.rows;
            this.paginationCouponHistory.total = data.res_result.total;
          }
        })
        .catch((error) => {
          this.loadingCode = false;
        });
    },
    async editCoupon(id) {
      this.formAddSpin.spin_id = id;
      let config = {
        method: "get",
        maxBodyLength: Infinity,
        url: `${this.$hostname}/coupon_spin?search=&sort=&order=desc&offset=0&limit=10&spin_id=${id}`,
        headers: {
          authorization: this.$cookies.get("token"),
        },
      };

      await axios
        .request(config)
        .then((res) => {
          const data = res.data;
          if (data.res_code === "00") {
            this.formAddSpin.spin_id = data.res_result.rows[0].spin_id;
            this.formAddSpin.spin_name = data.res_result.rows[0].spin_name;
            this.formAddSpin.spin_0 = data.res_result.rows[0].spin_0;
            this.formAddSpin.spin_free = data.res_result.rows[0].spin_free;
            this.formAddSpin.spin_5 = data.res_result.rows[0].spin_5;
            this.formAddSpin.spin_10 = data.res_result.rows[0].spin_10;
            this.formAddSpin.spin_point = data.res_result.rows[0].spin_point;

            this.getPercent();

            this.modals["modalEditCoupon"] = true;
          }
        })
        .catch((error) => {});
    },
    async submitUpdateCoupon() {
      if (
        !this.formAddSpin.spin_id ||
        !this.formAddSpin.spin_name ||
        !this.formAddSpin.spin_point
      ) {
        return Swal.fire("แจ้งเตือน", "กรุณากรอกข้อมูลให้ครบ", "error");
      }

      if (this.percent !== 100) {
        return Swal.fire("แจ้งเตือน", "ผลรวมเปอร์เซ็นต์ต้องเต็ม 100%", "error");
      }

      this.loadingSubmit = true;

      let data = JSON.stringify({
        spin_id: this.formAddSpin.spin_id,
        spin_name: this.formAddSpin.spin_name,
        spin_point: this.formAddSpin.spin_point,
        spin_0: this.formAddSpin.spin_0,
        spin_free: this.formAddSpin.spin_free,
        spin_5: this.formAddSpin.spin_5,
        spin_10: this.formAddSpin.spin_10,
      });

      let config = {
        method: "post",
        maxBodyLength: Infinity,
        url: `${this.$hostname}/update_coupon_spin`,
        headers: {
          authorization: this.$cookies.get("token"),
          "Content-Type": "application/json",
        },
        data: data,
      };

      await axios
        .request(config)
        .then((res) => {
          this.loadingSubmit = false;
          this.tableData = [];
          let data = res.data;
          if (data.res_code == "00") {
            this.getCouponSpin();
            Swal.fire({
              title: "แจ้งเตือน",
              text: data.res_text,
              icon: "success",
              timer: 1500,
              showConfirmButton: false,
            });
            this.formAddSpin.spin_id = "";
            this.formAddSpin.spin_name = "";
            this.formAddSpin.spin_point = "";
            this.formAddSpin.spin_0 = "";
            this.formAddSpin.spin_free = "";
            this.formAddSpin.spin_5 = "";
            this.formAddSpin.spin_10 = "";
            this.modals["modalEditCoupon"] = false;
          } else {
            return Swal.fire("แจ้งเตือน", data.res_text, "error");
          }
        })
        .catch((error) => {
          this.loadingSubmit = false;
        });
    },
    deleteCoupon(spin_name) {
      Swal.fire({
        title: "แจ้งเตือน",
        text: "ยืนยันลบกิจกรรม: " + spin_name,
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "ยืนยัน",
        cancelButtonText: "ยกเลิก",
      }).then(async (result) => {
        if (result.isConfirmed) {
          let data = JSON.stringify({
            spin_name: spin_name,
          });

          let config = {
            method: "post",
            maxBodyLength: Infinity,
            url: `${this.$hostname}/delete_coupon_spin`,
            headers: {
              authorization: this.$cookies.get("token"),
              "Content-Type": "application/json",
            },
            data: data,
          };

          await axios
            .request(config)
            .then((res) => {
              this.tableData = [];
              let data = res.data;
              if (data.res_code == "00") {
                this.getCouponSpin();
                Swal.fire({
                  title: "แจ้งเตือน",
                  text: data.res_text,
                  icon: "success",
                  timer: 1500,
                  showConfirmButton: false,
                });
              } else {
                return Swal.fire("แจ้งเตือน", data.res_text, "error");
              }
            })
            .catch((error) => {});
        }
      });
    },
    copySpinCode(id) {
      let couponSpinCode = document.getElementById("copy_" + id);
      navigator.clipboard.writeText(couponSpinCode.innerHTML);
      Swal.fire({
        title: "แจ้งเตือน",
        text: "คัดลอก: " + couponSpinCode.innerHTML + " สำเร็จ",
        icon: "success",
        timer: 1500,
        showConfirmButton: false,
      });
    },
    confirmClose() {
      Swal.fire({
        title: "แจ้งเตือน",
        text: "ต้องการยกเลิกการทำรายการ?",
        icon: "question",
        showCancelButton: true,
        confirmButtonClass: "btn btn-danger",
        cancelButtonClass: "btn btn-warning",
        confirmButtonText: "ยืนยัน",
        cancelButtonText: "ดำเนินการต่อ",
        buttonsStyling: false,
      }).then((result) => {
        if (result.isConfirmed) {
          this.modals["modalAddSpin"] = false;
          this.modals["modalAddSpinCode"] = false;
        }
      });
    },
  },
  mounted() {
    this.createCouponSpinCode();
  },
  watch: {},
};
</script>
<style></style>
