<template>
  <div v-if="!this.$func.checkPermission('สมาชิก')" class="row">
    <div class="col">
      <div class="card">
        <div class="card-body">
          <div class="alert alert-danger" role="alert">
            <strong>คุณไม่มีสิทธิ์เข้าถึงหน้านี้</strong>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div v-else>
    <div class="row">
      <div class="col-12">
        <card>
          <button
            class="btn btn-primary btn-fill mb-0"
            @click="addMember('modalAddMember')"
          >
            <i class="fa fa-user-plus" aria-hidden="true"></i>
            เพิ่มสมาชิก
          </button>
          <hr />
          <div class="row">
            <div class="col-sm-3 d-inline-block">
              <el-select
                class="select-default mb-3 w-100"
                v-model="pagination.perPage"
                placeholder="Per page"
              >
                <el-option
                  class="select-default"
                  v-for="item in pagination.perPageOptions"
                  :key="item"
                  :label="item"
                  :value="item"
                >
                </el-option>
              </el-select>
            </div>
            <div class="col-sm-3 offset-sm-6 d-flex mb-3">
              <el-input
                type="search"
                class="my-auto mr-2"
                placeholder="ค้นหา"
                v-model="searchQuery"
                aria-controls="datatables"
              />
              <el-button
                type="danger"
                size="medium"
                @click="getMember()"
                :loading="loading"
                ><i class="fa fa-refresh" aria-hidden="true"></i
              ></el-button>
            </div>
          </div>
          <div class="row">
            <div class="col-sm-12">
              <el-table
                stripe
                style="width: 100%"
                :data="tableData"
                border
                v-loading="loading"
              >
                <el-table-column :min-width="75" label="ลำดับ">
                  <template v-slot="props">
                    <span v-html="props ? props.row.D0 : ''"> </span>
                  </template>
                </el-table-column>
                <el-table-column :min-width="100" label="วันที่เข้าร่วม">
                  <template v-slot="props">
                    <span v-html="props ? props.row.D1 : ''"></span>
                  </template>
                </el-table-column>
                <el-table-column :min-width="170" label="ข้อมูลพันธมิตร">
                  <template v-slot="props">
                    <span v-html="props ? props.row.D2 : ''"> </span>
                  </template>
                </el-table-column>
                <el-table-column :min-width="140" label="ฝาก">
                  <template v-slot="props">
                    <span v-html="props ? props.row.D3 : ''"></span>
                  </template>
                </el-table-column>
                <el-table-column :min-width="140" label="ถอน">
                  <template v-slot="props">
                    <span v-html="props ? props.row.D4 : ''"></span>
                  </template>
                </el-table-column>
                <el-table-column :min-width="100" label="โบนัส">
                  <template v-slot="props">
                    <span v-html="props.row.D12 ? props.row.D12 : '-'"></span>
                  </template>
                </el-table-column>
                <el-table-column :min-width="120" label="ยอดเสีย">
                  <template v-slot="props">
                    <span v-html="props ? props.row.D13 : ''"></span>
                  </template>
                </el-table-column>
                <el-table-column :min-width="100" label="ยอดรวม">
                  <template v-slot="props">
                    <span v-html="props ? props.row.D5 : ''"></span>
                  </template>
                </el-table-column>
                <el-table-column :min-width="100" label="หัก1% (เกิน35%)">
                  <template v-slot="props">
                    <span v-html="props ? props.row.D11 : ''"></span>
                  </template>
                </el-table-column>
                <el-table-column :min-width="100" label="รายได้">
                  <template v-slot="props">
                    <span v-html="props ? props.row.D6 : ''"></span>
                  </template>
                </el-table-column>
                <el-table-column :min-width="100" label="สมาชิกทั้งหมด">
                  <template v-slot="props">
                    <span
                      v-html="
                        props ? Number(props.row.D7).toLocaleString() : ''
                      "
                    ></span>
                  </template>
                </el-table-column>
                <el-table-column :min-width="100" label="สมัครแล้วเติม">
                  <template v-slot="props">
                    <span
                      v-html="
                        props ? Number(props.row.D14).toLocaleString() : ''
                      "
                    ></span>
                  </template>
                </el-table-column>
                <el-table-column :min-width="100" label="ลิ้งเชิญ">
                  <template v-slot="props">
                    <span v-html="props ? props.row.D8 : ''"></span>
                  </template>
                </el-table-column>
                <el-table-column :min-width="100" label="สร้างโดย">
                  <template v-slot="props">
                    <span v-html="props ? props.row.D10 : ''"></span>
                  </template>
                </el-table-column>
                <el-table-column :min-width="120" fixed="right" label="จัดการ">
                  <template slot-scope="props" v-loading="loadingSubmit">
                    <button
                      class="btn btn-warning btn-sm mr-2"
                      @click="editMember(props.row.name_aff)"
                    >
                      <i class="fa fa-edit"></i>
                    </button>
                    <button
                      class="btn btn-info btn-sm mr-2"
                      @click="changePassword(props.row.name_aff)"
                    >
                      <i class="fa fa-key" aria-hidden="true"></i>
                    </button>
                    <button
                      class="btn btn-danger btn-sm mr-2"
                      @click="deleteMember(props.row.id)"
                    >
                      <i class="fa fa-trash" aria-hidden="true"></i>
                    </button>
                  </template>
                </el-table-column>
              </el-table>
            </div>
          </div>
          <div
            slot="footer"
            class="col-12 d-flex justify-content-center justify-content-sm-between flex-wrap"
          >
            <div class="">
              <p class="card-category">
                Showing {{ from + 1 }} to {{ to }} of {{ total }} entries
              </p>
            </div>
            <l-pagination
              class="pagination-no-border"
              v-model="pagination.currentPage"
              :per-page="pagination.perPage"
              :total="pagination.total"
            >
            </l-pagination>
          </div>
        </card>
      </div>
    </div>
    <el-dialog :visible.sync="modals.modalAddMember" width="30%">
      <span slot="title">
        <i class="fa fa-user-plus" aria-hidden="true"></i>
        เพิ่มผู้ใช้งาน
      </span>
      <div>
        <form>
          <div>
            <fg-input v-model="form.id" type="hidden" placeholder="">
            </fg-input>
          </div>
          <div>
            <fg-input
              v-model="form.name_aff"
              type="text"
              placeholder="รหัสพันธมิตร"
              disabled
            >
              <span slot="label"> รหัสพันธมิตร </span>
            </fg-input>
          </div>
          <div>
            <fg-input
              v-model="form.name"
              type="text"
              placeholder="Username"
              :disabled="disabled"
            >
              <span slot="label"> Username </span>
            </fg-input>
          </div>
          <div v-if="!disabled">
            <fg-input
              v-model="form.pass"
              type="password"
              placeholder="รหัสผ่าน"
            >
              <span slot="label"> รหัสผ่าน </span>
            </fg-input>
          </div>
          <div>
            <fg-input v-model="form.phone" type="text" placeholder="เบอร์โทร">
              <span slot="label"> เบอร์โทร </span>
            </fg-input>
          </div>
          <div>
            <fg-input
              v-model="form.percent"
              type="text"
              placeholder="จำนวนถือหุ้น %"
              :min="0"
              :max="100"
            >
              <span slot="label"> จำนวนถือหุ้น % </span>
            </fg-input>
          </div>
          <div>
            <fg-input
              v-model="form.bank_number"
              type="text"
              placeholder="เลขบัญชี"
            >
              <span slot="label"> เลขบัญชี </span>
            </fg-input>
          </div>
          <div class="mb-3">
            <div class="title">เลือกธนาคาร</div>
            <el-select
              class="select-default"
              size="large"
              placeholder="เลือกธนาคาร"
              v-model="form.bank_name"
            >
              <el-option
                v-for="option in selects.bank_name"
                class="select-default"
                :value="option.value"
                :label="option.label"
                :key="option.label"
              >
              </el-option>
            </el-select>
          </div>
          <div>
            <fg-input
              v-model="form.line_noti"
              type="text"
              placeholder="Line แจ้งเตือน"
            >
              <span slot="label"> Line แจ้งเตือน </span>
            </fg-input>
          </div>
        </form>
      </div>
      <span slot="footer" class="dialog-footer" v-loading="loadingSubmit">
        <button class="btn btn-success mr-2" @click="submitAddMember()">
          บันทึก
        </button>
        <button class="btn btn-danger" @click="closeModal('modalAddMember')">
          ยกเลิก
        </button>
      </span>
    </el-dialog>
    <el-dialog :visible.sync="modals.editMember" width="30%">
      <span slot="title">
        <i class="fa fa-edit" aria-hidden="true"></i>
        แก้ไขสมาชิก
      </span>
      <div>
        <form>
          <div>
            <fg-input v-model="form.id" type="hidden" placeholder="">
            </fg-input>
          </div>
          <div>
            <fg-input
              v-model="form.name_aff"
              type="text"
              placeholder="รหัสพันธมิตร"
              disabled
            >
              <span slot="label"> รหัสพันธมิตร </span>
            </fg-input>
          </div>
          <div>
            <fg-input
              v-model="form.name"
              type="text"
              placeholder="Username"
              :disabled="disabled"
            >
              <span slot="label"> Username </span>
            </fg-input>
          </div>
          <div>
            <fg-input v-model="form.phone" type="text" placeholder="เบอร์โทร">
              <span slot="label"> เบอร์โทร </span>
            </fg-input>
          </div>
          <div>
            <fg-input
              v-model="form.percent"
              type="text"
              placeholder="จำนวนถือหุ้น %"
              :min="0"
              :max="100"
            >
              <span slot="label"> จำนวนถือหุ้น % </span>
            </fg-input>
          </div>
          <div>
            <fg-input
              v-model="form.bank_number"
              type="text"
              placeholder="เลขบัญชี"
            >
              <span slot="label"> เลขบัญชี </span>
            </fg-input>
          </div>
          <div class="mb-3">
            <div class="title">เลือกธนาคาร</div>
            <el-select
              class="select-default"
              size="large"
              placeholder="เลือกธนาคาร"
              v-model="form.bank_name"
            >
              <el-option
                v-for="option in selects.bank_name"
                class="select-default"
                :value="option.value"
                :label="option.label"
                :key="option.label"
              >
              </el-option>
            </el-select>
          </div>
          <div>
            <fg-input
              v-model="form.line_noti"
              type="text"
              placeholder="Line แจ้งเตือน"
            >
              <span slot="label"> Line แจ้งเตือน </span>
            </fg-input>
          </div>
        </form>
      </div>
      <span slot="footer" class="dialog-footer" v-loading="loadingSubmit">
        <button class="btn btn-success mr-2" @click="submitUpdateAdmin()">
          บันทึก
        </button>
        <button class="btn btn-danger" @click="closeModal('editMember')">
          ยกเลิก
        </button>
      </span>
    </el-dialog>
    <el-dialog :visible.sync="modals.changePassword" width="30%">
      <span slot="title">
        <i class="fa fa-key" aria-hidden="true"></i>
        เปลี่ยนรหัสผ่าน
      </span>
      <div>
        <div>
          <fg-input
            v-model="form.pass"
            type="text"
            placeholder="กรอกรหัสผ่านใหม่"
          >
            <span slot="label"> รหัสผ่านใหม่ </span>
          </fg-input>
        </div>
      </div>
      <span slot="footer" class="dialog-footer" v-loading="loadingSubmit">
        <button class="btn btn-success mr-2" @click="saveChangePassword()">
          บันทึก
        </button>
        <button class="btn btn-danger" @click="closeModal('changePassword')">
          ยกเลิก
        </button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
import {
  DatePicker,
  Table,
  TableColumn,
  Select,
  Option,
  Dialog,
  Button,
} from "element-ui";
import { Pagination as LPagination } from "src/components/index";
import axios from "axios";
import Swal from "sweetalert2";
import moment from "moment";
export default {
  name: "member",
  components: {
    [DatePicker.name]: DatePicker,
    LPagination,
    [Select.name]: Select,
    [Option.name]: Option,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    [Dialog.name]: Dialog,
    [Button.name]: Button,
  },
  data() {
    return {
      loading: true,
      loadingMember: false,
      loadingSubmit: false,
      disabled: false,
      startDate: "",
      endDate: "",
      bonus: 0,
      deposit: 0,
      withdraw: 0,
      income: 0,
      form: {
        id: "",
        name_aff: "",
        name: "",
        pass: "",
        phone: "",
        percent: "",
        bank_number: "",
        bank_name: "",
        line_noti: "",
      },
      historyMemberPhone: "",
      name_aff: "",
      password: "",
      total_member: 0,
      total_member_month: 0,
      total_member_today: 0,
      status_pending: 0,
      modals: {
        modalAddMember: false,
        historyMember: false,
        addMember: false,
        changePassword: false,
        modalQrcode: false,
        editMember: false,
        centered: false,
        custom: false,
        confirm: false,
      },
      selects: {
        bank_name: [
          { value: "ไทยพาณิชย์", label: "ไทยพาณิชย์" },
          { value: "กรุงเทพ", label: "กรุงเทพ" },
          { value: "กรุงไทย", label: "กรุงไทย" },
          { value: "ทหารไทยธนชาต", label: "ทหารไทยธนชาต" },
          { value: "กรุงศรีอยุธยา", label: "กรุงศรีอยุธยา" },
          { value: "เกียรตินาคิน", label: "เกียรตินาคิน" },
          { value: "ออมสิน", label: "ออมสิน" },
          { value: "ซีไอเอ็มบีไทย", label: "ซีไอเอ็มบีไทย" },
          { value: "ยูโอบี", label: "ยูโอบี" },
          { value: "แลนด์ แอนด์ เฮาส์", label: "แลนด์ แอนด์ เฮาส์" },
          { value: "ธกส", label: "ธกส" },
          { value: "อาคารสงเคราะห์", label: "อาคารสงเคราะห์" },
          { value: "อิสลามแห่งประเทศไทย", label: "อิสลามแห่งประเทศไทย" },
        ],
      },
      pagination: {
        perPage: 10,
        currentPage: 1,
        perPageOptions: [10, 50, 100, 200],
        total: 0,
      },
      paginationHistoryMember: {
        perPage: 10,
        currentPage: 1,
        perPageOptions: [10, 50, 100, 200],
        total: 0,
      },
      searchQuery: "",
      searchQueryHistoryMember: "",
      tableData: [],
      tableDataHistoryMember: [],
      qrcodevalue: "",
      timeData: "",
      timeDataMember: "",
    };
  },
  computed: {
    to() {
      let highBound = this.from + this.pagination.perPage;
      if (this.total < highBound) {
        highBound = this.total;
      }
      return highBound;
    },
    from() {
      if (this.modals["historyMember"] === true) return;
      this.getMember();
      return this.pagination.perPage * (this.pagination.currentPage - 1);
    },
    total() {
      return this.pagination.total;
    },

    toMember() {
      let highBound = this.fromMember + this.paginationHistoryMember.perPage;
      if (this.totalMember < highBound) {
        highBound = this.totalMember;
      }
      return highBound;
    },
    fromMember() {
      if (this.modals["historyMember"] === false) return;
      this.getDashboardAllByPhone();
      return (
        this.paginationHistoryMember.perPage *
        (this.paginationHistoryMember.currentPage - 1)
      );
    },
    totalMember() {
      return this.paginationHistoryMember.total;
    },
  },
  methods: {
    async countMember() {
      let config = {
        method: "get",
        maxBodyLength: Infinity,
        url: `${this.$hostname}/countmember`,
        headers: {
          authorization: this.$cookies.get("token"),
        },
      };

      axios
        .request(config)
        .then((res) => {
          let data = res.data.res_result[0];
          this.total_member = data.total_member;
          this.total_member_month = data.total_member_month;
          this.total_member_today = data.total_member_today;
          this.status_pending = data.status_pending;
        })
        .catch((error) => {
          // console.log(error);
        });
    },
    async getMember() {
      if (this.modals["historyMember"] == true) return;

      this.loading = true;

      let offset = this.pagination.perPage * (this.pagination.currentPage - 1);

      let config = {
        method: "get",
        maxBodyLength: Infinity,
        url: `${this.$hostname}/get_aff?search=${this.searchQuery}&sort=&order=desc&offset=${offset}&limit=${this.pagination.perPage}&action=&start_date=${this.startDate}&end_date=${this.endDate}`,
        headers: {
          authorization: this.$cookies.get("token"),
          "Content-Type": "application/json",
        },
      };
      clearTimeout(this.timeData);
      this.timeData = setTimeout(async () => {
        await axios
          .request(config)
          .then((res) => {
            let data = res.data.res_result;
            this.pagination.total = data.total;
            this.tableData = data.rows;
            this.loading = false;
          })
          .catch((error) => {
            this.loading = false;
          });
      }, 500);
    },

    makeCode() {
      let result = "";
      const characters =
        "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
      const charactersLength = characters.length;
      let counter = 0;
      while (counter < 5) {
        result += characters.charAt(
          Math.floor(Math.random() * charactersLength)
        );
        counter += 1;
      }
      return result;
    },

    addMember() {
      this.disabled = false;
      this.form.id = "";
      this.form.name_aff = this.makeCode();
      this.form.name = "";
      this.form.phone = "";
      this.form.percent = "";
      this.form.bank_number = "";
      this.form.bank_name = "";
      this.form.line_noti = "";
      this.modals["modalAddMember"] = true;
    },

    async submitAddMember() {
      if (
        !this.form.name_aff ||
        !this.form.name ||
        !this.form.pass ||
        !this.form.percent
      ) {
        return Swal.fire("แจ้งเตือน", "กรุณากรอกข้อมูลให้ครบ", "error");
      }
      this.loadingSubmit = true;
      let data = JSON.stringify({
        name_aff: this.form.name_aff,
        name: this.form.name,
        pass: this.form.pass,
        bank_number: this.form.bank_number,
        bank_name: this.form.bank_name,
        percent: this.form.percent,
        phone: this.form.phone,
        line_noti: this.form.line_noti,
      });

      let config = {
        method: "post",
        maxBodyLength: Infinity,
        url: `${this.$hostname}/insertaff`,
        headers: {
          authorization: this.$cookies.get("token"),
          "Content-Type": "application/json",
        },
        data: data,
      };

      await axios
        .request(config)
        .then((res) => {
          this.loadingSubmit = false;
          const data = res.data;
          if (data.res_code === "00") {
            this.getMember();

            Swal.fire({
              title: "แจ้งเตือน",
              text: data.res_text,
              icon: "success",
              timer: 1500,
              showConfirmButton: false,
            }).then(() => {
              this.form.id = "";
              this.form.name_aff = "";
              this.form.name = "";
              this.form.phone = "";
              this.form.percent = "";
              this.form.bank_number = "";
              this.form.bank_name = "";
              this.form.line_noti = "";

              this.modals["modalAddMember"] = false;
            });
          } else {
            Swal.fire("แจ้งเตือน", data.res_text, "error");
          }
        })
        .catch((error) => {
          this.loadingSubmit = false;
        });
    },

    async editMember(name_aff) {
      let data = JSON.stringify({
        name_aff: name_aff,
      });

      this.disabled = true;

      let config = {
        method: "post",
        maxBodyLength: Infinity,
        url: `${this.$hostname}/aff_byid`,
        headers: {
          authorization: this.$cookies.get("token"),
          "Content-Type": "application/json",
        },
        data: data,
      };

      axios
        .request(config)
        .then((res) => {
          const data = res.data;

          if (data.res_code === "00") {
            this.form.id = data.res_result.id;
            this.form.name_aff = data.res_result.name_aff;
            this.form.name = data.res_result.name;
            this.form.phone = data.res_result.phone;
            this.form.percent = data.res_result.percent;
            this.form.bank_number = data.res_result.bank_number;
            this.form.bank_name = data.res_result.bank_name;
            this.form.line_noti = data.res_result.line_noti;

            this.modals["editMember"] = true;
          }
        })
        .catch((error) => {
          // console.log(error);
        });
    },
    async submitUpdateAdmin() {
      let data = {
        id: this.form.id,
        name_aff: this.form.name_aff,
        name: this.form.name,
        pass: this.form.pass,
        bank_number: this.form.bank_number,
        bank_name: this.form.bank_name,
        percent: this.form.percent,
        phone: this.form.phone,
        line_noti: this.form.line_noti,
      };

      if (!this.form.name_aff || !this.form.name || !this.form.percent) {
        return Swal.fire("แจ้งเตือน", "กรุณากรอกข้อมูลให้ครบ", "error");
      }

      this.loadingSubmit = true;

      let config = {
        method: "post",
        maxBodyLength: Infinity,
        url: `${this.$hostname}/update_aff`,
        headers: {
          authorization: this.$cookies.get("token"),
          "Content-Type": "application/json",
        },
        data: data,
      };
      await axios
        .request(config)
        .then((res) => {
          this.loadingSubmit = false;
          const data = res.data;
          if (data.res_code === "00") {
            this.getMember();

            Swal.fire({
              title: "แจ้งเตือน",
              text: data.res_text,
              icon: "success",
              timer: 1500,
              showConfirmButton: false,
            }).then(() => {
              this.form.id = "";
              this.form.name_aff = "";
              this.form.name = "";
              this.form.phone = "";
              this.form.percent = "";
              this.form.bank_number = "";
              this.form.bank_name = "";
              this.form.line_noti = "";

              this.modals["editMember"] = false;
            });
          } else {
            Swal.fire("แจ้งเตือน", data.res_text, "error");
          }
        })
        .catch((error) => {
          this.loadingSubmit = false;
        });
    },

    changePassword(id) {
      this.form.id = id;
      this.modals["changePassword"] = true;
    },

    async saveChangePassword() {
      let data = JSON.stringify({
        id: this.form.id,
        pass: this.form.pass,
      });

      if (!this.form.id || !this.form.pass) {
        return Swal.fire("แจ้งเตือน", "กรุณากรอกข้อมูลให้ครบ", "error");
      }

      this.loadingSubmit = true;

      let config = {
        method: "post",
        maxBodyLength: Infinity,
        url: `${this.$hostname}/changepass_aff`,
        headers: {
          authorization: this.$cookies.get("token"),
          "Content-Type": "application/json",
        },
        data: data,
      };

      await axios
        .request(config)
        .then((res) => {
          this.loadingSubmit = false;
          const data = res.data;
          if (data.res_code === "00") {
            Swal.fire({
              title: "แจ้งเตือน",
              text: data.res_text,
              icon: "success",
              timer: 1500,
              showConfirmButton: false,
            }).then(() => {
              this.form.id = "";
              this.form.pass = "";
              this.modals["changePassword"] = false;
            });
          } else {
            Swal.fire("แจ้งเตือน", data.res_text, "error");
          }
        })
        .catch((error) => {
          this.loadingSubmit = false;
        });
    },

    deleteMember(id) {
      Swal.fire({
        title: "แจ้งเตือน",
        text: "ยืนยันการลบสมาชิก: " + id,
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "ยืนยัน",
        cancelButtonText: "ยกเลิก",
        confirmButtonClass: "btn btn-danger btn-fill",
        cancelButtonClass: "btn btn-default btn-fill",
        buttonsStyling: false,
      }).then(async (result) => {
        if (result.isConfirmed) {
          this.loadingSubmit = true;
          let data = JSON.stringify({
            id: id,
          });

          let config = {
            method: "post",
            maxBodyLength: Infinity,
            url: `${this.$hostname}/delete_aff`,
            headers: {
              authorization: this.$cookies.get("token"),
              "Content-Type": "application/json",
            },
            data: data,
          };

          await axios
            .request(config)
            .then((res) => {
              this.loadingSubmit = false;
              let data = res.data;
              if (data.res_code == "00") {
                this.getMember();
                Swal.fire({
                  title: "แจ้งเตือน",
                  text: data.res_text,
                  icon: "success",
                  timer: 1500,
                  showConfirmButton: false,
                });
              } else {
                Swal.fire("แจ้งเตือน", data.res_text, "error");
              }
            })
            .catch((error) => {
              this.loadingSubmit = false;
            });
        }
      });
    },
    confirmClose() {
      Swal.fire({
        title: "แจ้งเตือน",
        text: "ต้องการยกเลิกการทำรายการ?",
        icon: "question",
        showCancelButton: true,
        confirmButtonClass: "btn btn-danger",
        cancelButtonClass: "btn btn-warning",
        confirmButtonText: "ยืนยัน",
        cancelButtonText: "ดำเนินการต่อ",
        buttonsStyling: false,
      }).then((result) => {
        if (result.isConfirmed) {
          this.modals["editMember"] = false;
          this.modals["changePassword"] = false;
        }
      });
    },

    qrshow(value) {
      this.qrcodevalue = value;
      this.modals["modalQrcode"] = true;
    },
    qrhide() {
      this.modals["modalQrcode"] = false;
    },
    openModal(name) {
      this.modals[name] = true;
    },
    closeModal(name) {
      this.modals[name] = false;
    },
  },
  mounted() {
    this.countMember();
  },
};
</script>
