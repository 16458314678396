<template>
  <div>
    <div class="row d-flex justify-content-center align-items-center">
      <div v-if="formVerifyAuth === false" class="col-lg-4 col-md-6 col-sm-8">
        <Transition>
          <card class="py-2">
            <div>
              <ValidationObserver v-slot="{ handleSubmit }">
                <!--You can specify transitions on initial render. The `card-hidden` class will be present initially and then it will be removed-->
                <form @submit.prevent="handleSubmit(submitRegisterAuth)">
                  <div>
                    <div class="text-center mb-3">
                      <img
                        src="/static/img/Google_Authenticator_for_Android_icon.svg.png"
                        alt=""
                        class="mw-100"
                        width="150"
                      />
                    </div>
                    <h4 class="card-title text-center mb-3">
                      Google Authenticator Registration
                    </h4>
                    <ValidationProvider
                      name="username"
                      rules="required"
                      v-slot="{ passed, failed }"
                    >
                      <fg-input
                        type="text"
                        :error="
                          failed ? 'The Username field is required' : null
                        "
                        :hasSuccess="passed"
                        label="Username"
                        name="username"
                        v-model="username"
                        class="mb-2"
                      >
                      </fg-input>
                    </ValidationProvider>
                    <ValidationProvider
                      name="password"
                      rules="required|min:5"
                      v-slot="{ passed, failed }"
                    >
                      <fg-input
                        type="password"
                        :error="
                          failed ? 'The Password field is required' : null
                        "
                        :hasSuccess="passed"
                        name="password"
                        label="Password"
                        v-model="password"
                        autocomplete="false"
                      >
                      </fg-input>
                    </ValidationProvider>
                  </div>
                  <div class="text-center" v-loading="loading">
                    <button
                      type="submit"
                      class="btn btn-fill btn-primary btn-round btn-wd"
                    >
                      <i class="fa fa-user-plus" aria-hidden="true"></i>
                      ลงทะเบียน
                    </button>
                  </div>
                </form>
              </ValidationObserver>
            </div>
            <hr />
            <div class="text-center">
              <router-link to="/login" class="text-warning">
                <i class="fa fa-chevron-left" aria-hidden="true"></i>
                เข้าสู่ระบบ
              </router-link>
            </div>
          </card>
        </Transition>
      </div>
      <div v-else class="col-lg-6 col-md-6 col-sm-8">
        <Transition>
          <card class="py-2">
            <div>
              <ValidationObserver v-slot="{ handleSubmit }">
                <!--You can specify transitions on initial render. The `card-hidden` class will be present initially and then it will be removed-->
                <form @submit.prevent="handleSubmit(submitVerifyAuth)">
                  <div>
                    <h4 class="card-title text-center mb-3">
                      Google Authenticator Verify
                    </h4>
                    <div class="text-center">
                      <h5 class="m-0">QR Code</h5>
                      <img :src="QRCode" alt="" class="mw-100" />
                    </div>
                    <p class="text-center">
                      Secret Key:<br />
                      <b class="text-success">{{ secretkey }}</b>
                    </p>
                    <p class="text-danger">
                      <i>*สแกน QR Code หรือ กรอก Secret Key บนแอพ</i>
                    </p>
                    <ValidationProvider
                      name="Authenticator"
                      rules="required"
                      v-slot="{ passed, failed }"
                    >
                      <div class="mb-3">
                        <div class="input-group">
                          <div class="input-group-prepend">
                            <span class="input-group-text" id="code"
                              >รหัส 6 หลัก</span
                            >
                          </div>
                          <input
                            v-model="Authenticator"
                            type="text"
                            class="form-control"
                            placeholder="กรอกรหัส  Google Authenticator (6 หลัก)"
                          />
                        </div>
                        <small
                          v-if="failed"
                          :hasSuccess="passed"
                          id="helpId"
                          class="form-text text-danger"
                          >กรอกรหัส Google Authenticator (6 หลัก)</small
                        >
                      </div>

                      <!-- <fg-input
                        type="text"
                        :error="
                          failed ? 'The Authenticator field is required' : null
                        "
                        :hasSuccess="passed"
                        label="กรอกรหัส  Google Authenticator (6หลัก)"
                        name="Authenticator"
                        v-model="Authenticator"
                        class="mb-2"
                      >
                      </fg-input> -->
                    </ValidationProvider>
                  </div>
                  <div class="text-center" v-loading="loading">
                    <button
                      type="submit"
                      class="btn btn-fill btn-primary btn-round btn-wd"
                    >
                      <i class="fa fa-check" aria-hidden="true"></i>
                      ยืนยัน
                    </button>
                  </div>
                </form>
              </ValidationObserver>
            </div>
            <hr />
            <div class="text-center">
              <a
                @click="formVerifyAuth = false"
                role="button"
                class="text-warning"
              >
                <i class="fa fa-chevron-left" aria-hidden="true"></i>
                กลับ
              </a>
            </div>
          </card>
        </Transition>
      </div>
    </div>
  </div>
</template>
<script>
import { extend } from "vee-validate";
import { required, min } from "vee-validate/dist/rules";
import Swal from "sweetalert2";

import axios from "axios";
// import axios from "axios";
extend("required", required);
extend("min", min);

export default {
  components: {},
  data() {
    return {
      formVerifyAuth: false,
      loading: false,
      username: "",
      password: "",
      QRCode: "",
      secretkey: "",
      Authenticator: "",
    };
  },
  methods: {
    async submitRegisterAuth() {
      if (!this.username || !this.password) {
        alert("กรุณากรอกข้อมูลให้ครบ");
        return;
      }
      let data = JSON.stringify({
        username: this.username,
        password: this.password,
      });

      let config = {
        method: "post",
        maxBodyLength: Infinity,
        url: `${this.$hostname}/registwo`,
        headers: {
          "Content-Type": "application/json",
        },
        data: data,
        timeout: 10000,
      };

      this.loading = true;

      await axios
        .request(config)
        .then((response) => {
          this.loading = false;
          let data = response.data;

          if (data.res_code === "00") {
            Swal.fire({
              title: "แจ้งเตือน",
              text: "สมัคร Google Authenticator สำเร็จ กรุณายืนยันตัวตนบนแอพ",
              icon: "success",
              showConfirmButton: true,
            }).then(() => {
              this.secretkey = data.res_result.secretkey;
              this.QRCode = data.res_result.QRCode;
              this.formVerifyAuth = true;
            });
          } else {
            Swal.fire({
              title: "แจ้งเตือน",
              text: data.res_text,
              icon: "error",
              showConfirmButton: true,
            }).then(() => {
              this.password = "";
            });
          }
        })
        .catch((error) => {
          this.loading = false;

          return Swal.fire(
            "แจ้งเตือน",
            "ผิดพลาดกรุณาติดต่อผู้ให้บริการ",
            "error"
          );
        });
    },
    async submitVerifyAuth() {
      if (!this.Authenticator) {
        return Swal.fire("แจ้งเตือน", "กรุณากรอกข้อมูลให้ครบ", "error");
      }
      let data = {
        username: this.username,
        secretkey: this.secretkey,
        Authenticator: this.Authenticator,
      };

      let config = {
        method: "post",
        maxBodyLength: Infinity,
        url: `${this.$hostname}/registergoogle`,
        headers: {
          "Content-Type": "application/json",
        },
        data: data,
      };

      this.loading = true;

      axios
        .request(config)
        .then((response) => {
          this.loading = false;

          let data = response.data;
          if (data.res_code === "00") {
            Swal.fire({
              title: "แจ้งเตือน",
              text: "ลงทะเบียน Google Authenticator สำเร็จ",
              icon: "success",
              timer: 1000,
              showConfirmButton: false,
            }).then(() => {
              window.location.href = "/";
            });
          } else {
            return Swal.fire({
              title: "แจ้งเตือน",
              text: res.res_text,
              icon: "error",
              showConfirmButton: true,
            });
          }
        })
        .catch((error) => {
          this.loading = false;

          return Swal.fire(
            "แจ้งเตือน",
            "ผิดพลาดกรุณาติดต่อผู้ให้บริการ",
            "error"
          );
        });
    },
    toggleNavbar() {
      document.body.classList.toggle("nav-open");
    },
    closeMenu() {
      document.body.classList.remove("nav-open");
      document.body.classList.remove("off-canvas-sidebar");
    },
  },
  beforeDestroy() {
    this.closeMenu();
  },
};
</script>
<style>
.navbar-nav .nav-item p {
  line-height: inherit;
  margin-left: 5px;
}

.v-modal {
  z-index: 3 !important;
}
</style>
