<template>
  <div v-if="!this.$func.checkPermission('ตั้งค่าแจ้งเตือน')" class="row">
    <div class="col">
      <div class="card">
        <div class="card-body">
          <div class="alert alert-danger" role="alert">
            <strong>คุณไม่มีสิทธิ์เข้าถึงหน้านี้</strong>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div v-else>
    <div class="row">
      <div class="col-12">
        <card v-loading="loading">
          <div slot="header">
            <a name="" id="" class="btn btn-success" href="#" role="button">
              <i class="fab fa-line"></i>
              รับ Token
            </a>
          </div>
          <div class="row">
            <div class="col-12 col-lg-5">
              <form method="#" action="#" @submit.prevent>
                <div>
                  <fg-input
                    :disabled="disabled"
                    v-model="member_withdraw"
                    type="text"
                    placeholder="กรอก TOKEN ลูกค้าแจ้งถอน"
                  >
                    <span slot="label">TOKEN ลูกค้าแจ้งถอน</span>
                  </fg-input>
                </div>
                <div>
                  <fg-input
                    :disabled="disabled"
                    v-model="member_deposit"
                    type="text"
                    placeholder="กรอก TOKEN ลูกค้าฝากเงิน"
                  >
                    <span slot="label">TOKEN ลูกค้าฝากเงิน</span>
                  </fg-input>
                </div>
                <div>
                  <fg-input
                    :disabled="disabled"
                    v-model="member_register"
                    type="text"
                    placeholder="กรอก TOKEN ลูกค้าสมัครใหม่"
                  >
                    <span slot="label">TOKEN ลูกค้าสมัครใหม่</span>
                  </fg-input>
                </div>

                <div>
                  <fg-input
                    :disabled="disabled"
                    v-model="bank_balance"
                    type="text"
                    placeholder="กรอก TOKEN แจ้งเตือนยอดเงินฝาก ถอน"
                  >
                    <span slot="label">TOKEN แจ้งเตือนยอดเงินฝาก ถอน</span>
                  </fg-input>
                </div>

                <hr />
                <div>
                  <div v-if="disabled === false">
                    <button
                      @click="updateNotify()"
                      class="btn btn-primary"
                      type="submit"
                    >
                      <i class="fa fa-save"></i>
                      บันทึก
                    </button>
                  </div>
                  <div v-if="disabled === true">
                    <button
                      @click="disabled = !disabled"
                      type="button"
                      class="btn btn-warning mr-2"
                    >
                      <i class="fa fa-edit"></i>
                      แก้ไข
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </card>
      </div>
    </div>
  </div>
</template>
<script>
import { Button } from "element-ui";
import { FormGroupInput as FgInput } from "src/components/index";
import axios from "axios";
import Swal from "sweetalert2";
export default {
  components: {
    [Button.name]: Button,
    FgInput,
  },
  data() {
    return {
      loading: true,
      member_withdraw: "",
      member_deposit: "",
      member_register: "",
      bank_balance: "",
      disabled: true,
    };
  },
  methods: {
    async getNotify() {
      this.loading = true;
      let config = {
        method: "get",
        maxBodyLength: Infinity,
        url: `${this.$hostname}/get_notify`,
        headers: {
          Authorization: this.$cookies.get("token"),
        },
      };

      await axios
        .request(config)
        .then((res) => {
          this.loading = false;
          let data = res.data;
          if (data.res_code === "00") {
            this.member_withdraw = data.res_result[0].member_withdraw;
            this.member_deposit = data.res_result[0].member_deposit;
            this.member_register = data.res_result[0].member_register;
            this.bank_balance = data.res_result[0].bank_balance;
          }
        })
        .catch((error) => {
          this.loading = false;
        });
    },
    updateNotify() {
      let data = JSON.stringify({
        member_withdraw: this.member_withdraw,
        member_deposit: this.member_deposit,
        member_register: this.member_register,
        bank_balance: this.bank_balance,
      });

      let config = {
        method: "post",
        maxBodyLength: Infinity,
        url: `${this.$hostname}/notify_update`,
        headers: {
          Authorization: this.$cookies.get("token"),
          "Content-Type": "application/json",
        },
        data: data,
      };

      axios
        .request(config)
        .then((res) => {
          const data = res.data;
          if (data.res_code === "00") {
            this.getNotify();
            this.disabled = true;
            Swal.fire({
              title: "แจ้งเตือน",
              text: data.res_text,
              icon: "success",
              timer: 1500,
              showConfirmButton: false,
            });
          } else {
            return Swal.fire("แจ้งเตือน", data.res_text, "error");
          }
        })
        .catch((error) => {});
    },
  },
  mounted() {
    this.getNotify();
  },
};
</script>
<style lang=""></style>
