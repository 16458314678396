<template>
  <div v-if="!this.$func.checkPermission('เทเลแกรม')" class="row">
    <div class="col">
      <div class="card">
        <div class="card-body">
          <div class="alert alert-danger" role="alert">
            <strong>คุณไม่มีสิทธิ์เข้าถึงหน้านี้</strong>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div v-else>
    <div class="row">
      <div class="col-12">
        <card class="mb-3">
          <div slot="header">
            <h4 class="m-0">เทเลแกรม</h4>
          </div>
          <div>
            <el-button
              :type="cardtype === 1 ? 'primary' : 'default'"
              size="default"
              @click="changeCard(1)"
              style="width: 100px"
              :loading="loading"
              >ส่งข้อความ</el-button
            >
            <el-button
              :type="cardtype === 2 ? 'primary' : 'default'"
              size="default"
              @click="changeCard(2)"
              style="width: 100px"
              :loading="loading"
              >ตั้งค่า</el-button
            >
          </div>
          <hr />
          <div v-if="cardtype === 1" v-loading="loading">
            <h5>ส่งข้อความ</h5>
            <div class="row">
              <div class="col-12 col-lg-6">
                <div class="form-group">
                  <small for="coupon_type">ประเภทคูปอง</small>
                  <select
                    v-model="coupon_type"
                    class="form-control"
                    name="coupon_type"
                    id="coupon_type"
                    @change="getActivity()"
                  >
                    <option :selected="coupon_type === 0" value="0">
                      เลือกประเภทคูปอง
                    </option>
                    <option value="1">คูปอง</option>
                    <option value="2">คูปองกงล้อ</option>
                  </select>
                </div>
                <div class="form-group">
                  <small for="activity_id">กิจกรรม</small>
                  <select
                    v-model="activity_id"
                    class="form-control"
                    name="activity_id"
                    id="activity_id"
                  >
                    <option v-if="!activity_id" value="">เลือกกิจกรรม</option>
                    <template>
                      <option
                        v-for="item in activityData"
                        :key="item.value"
                        :value="item.value"
                        :selected="item.value === activity_id"
                      >
                        {{ item.label }}
                      </option>
                    </template>
                  </select>
                </div>
                <div class="form-group">
                  <small for="message">ข้อความ</small>
                  <textarea
                    v-model="message"
                    class="form-control"
                    name="message"
                    id="message"
                    rows="3"
                  ></textarea>
                </div>
                <div class="form-group">
                  <small for="coupon_qty">จำนวน</small>
                  <input
                    v-model="coupon_qty"
                    type="text"
                    class="form-control"
                    name="coupon_qty"
                    id="coupon_qty"
                  />
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-12 col-lg-6">
                <div class="">
                  <el-button
                    class="w-100"
                    type="success"
                    size="default"
                    :loading="loading"
                    @click="submitSendMessage()"
                    >ส่งข้อความ</el-button
                  >
                </div>
              </div>
            </div>
          </div>
          <div v-if="cardtype === 2" v-loading="loading">
            <h5>ตั้งค่าเทเแกรม</h5>
            <div class="row">
              <div class="col-12 col-lg-6">
                <div class="form-group">
                  <small for="coupon_type">ประเภทคูปอง</small>
                  <select
                    v-model="coupon_type"
                    class="form-control"
                    name="coupon_type"
                    id="coupon_type"
                    :disabled="configDisabled"
                    @change="getActivity()"
                  >
                    <option v-if="coupon_type > 0" value="">
                      เลือกประเภทคูปอง
                    </option>
                    <option value="1" :selected="coupon_type === 1">
                      คูปอง
                    </option>
                    <option value="2" :selected="coupon_type === 2">
                      คูปองกงล้อ
                    </option>
                  </select>
                </div>
                <div class="form-group">
                  <small for="activity_id">กิจกรรม</small>
                  <select
                    v-model="activity_id"
                    class="form-control"
                    name="activity_id"
                    id="activity_id"
                    :disabled="configDisabled"
                  >
                    <option v-if="!activity_id" value="">เลือกกิจกรรม</option>
                    <template>
                      <option
                        v-for="item in activityData"
                        :key="item.value"
                        :value="item.value"
                        :selected="item.value === activity_id"
                      >
                        {{ item.label }}
                      </option>
                    </template>
                  </select>
                </div>
                <div class="form-group">
                  <small for="message">ข้อความ</small>
                  <textarea
                    v-model="message"
                    class="form-control"
                    name="message"
                    id="message"
                    rows="3"
                    :disabled="configDisabled"
                  ></textarea>
                </div>
                <div class="form-group">
                  <small for="coupon_qty">จำนวน</small>
                  <input
                    v-model="coupon_qty"
                    type="text"
                    class="form-control"
                    name="coupon_qty"
                    id="coupon_qty"
                    :disabled="configDisabled"
                  />
                </div>
              </div>
              <div class="col-12 col-lg-6">
                <div class="form-group">
                  <small for="token_bot">TOKEN</small>
                  <input
                    v-model="token_bot"
                    type="text"
                    class="form-control"
                    name="token_bot"
                    id="token_bot"
                    :disabled="configDisabled"
                  />
                </div>
                <div class="form-group">
                  <small for="channel_id">CHANNEL ID</small>
                  <input
                    v-model="channel_id"
                    type="text"
                    class="form-control"
                    name="channel_id"
                    id="channel_id"
                    :disabled="configDisabled"
                  />
                </div>
                <div class="form-group">
                  <label for="status">สถานะการใช้งาน</label>
                  <select
                    v-model="status"
                    class="form-control"
                    name="status"
                    id="status"
                    :disabled="configDisabled"
                  >
                    <option value="0">ปิด</option>
                    <option value="1">เปิด</option>
                  </select>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-12 col-lg-6">
                <el-button
                  v-if="configDisabled === true"
                  class="w-100"
                  type="warning"
                  size="default"
                  @click="configDisabled = !configDisabled"
                  >แก้ไข</el-button
                >
                <div v-else>
                  <div class="mb-2">
                    <el-button
                      class="w-100"
                      type="success"
                      size="default"
                      @click="submitUpdateConfig()"
                      :loading="loading"
                      >บันทึก</el-button
                    >
                  </div>
                  <div>
                    <el-button
                      class="w-100"
                      type="danger"
                      size="default"
                      @click="configDisabled = !configDisabled"
                      >ยกเลิก</el-button
                    >
                  </div>
                </div>
              </div>
            </div>
          </div>
        </card>
      </div>
    </div>
    <div class="row mb-2">
      <div class="col-12 col-sm-6 col-md-4 col-lg-3">
        <stats-card
          :title="sumtype1"
          subTitle="คูปอง"
          class="mb-2"
          v-loading="loading"
        >
          <div slot="header" class="icon-success">
            <i class="nc-icon nc-chart-bar-32 text-success"></i>
          </div>
        </stats-card>
      </div>

      <div class="col-12 col-sm-6 col-md-4 col-lg-3">
        <stats-card
          :title="sumtype2"
          subTitle="คูปองกงล้อ"
          class="mb-2"
          v-loading="loading"
        >
          <div slot="header" class="icon-danger">
            <i class="nc-icon nc-chart-bar-32 text-danger"></i>
          </div>
        </stats-card>
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <card>
          <div slot="header">
            <h4 class="m-0 mr-2">ประวัติส่งข้อความ</h4>
          </div>
          <div class="row">
            <div class="col-12 col-sm-4 col-md-3 col-lg-2 d-inline-block">
              <el-select
                class="select-default mb-3 w-100"
                v-model="pagination.perPage"
                @change="getLogTelegram()"
              >
                <el-option
                  class="select-default"
                  v-for="item in pagination.perPageOptions"
                  :key="item"
                  :small="item"
                  :value="item"
                >
                </el-option>
              </el-select>
            </div>
            <div class="col-12 col-sm-4 col-md-3 col-lg-2 d-inline-block">
              <el-date-picker
                v-model="start_date"
                class="mb-3"
                type="date"
                size="normal"
                placeholder="เลือกวันที่"
                format="dd/MM/yyyy"
                value-format="yyyy-MM-dd"
                @change="getLogTelegram()"
              >
              </el-date-picker>
            </div>

            <!-- <div class="col-12 col-sm-6 col-md-4 col-lg-3 ml-auto d-flex mb-3">
              <el-input
                type="search"
                class="my-auto mr-2"
                v-model="searchQuery"
                aria-controls="datatables"
              />
              <button
                @click="getLog()"
                type="button"
                class="btn btn-danger my-auto"
              >
                <i class="fa fa-refresh" aria-hidden="true"></i>
              </button>
            </div> -->
          </div>
          <div class="row">
            <div class="col-sm-12">
              <el-table
                stripe
                style="width: 100%"
                :data="tableData"
                border
                v-loading="loading"
              >
                <el-table-column :min-width="50" small="ลำดับ">
                  <template v-slot="props">
                    <span v-html="props ? props.row.D0 : ''"> </span>
                  </template>
                </el-table-column>
                <el-table-column :min-width="100" small="D1">
                  <template v-slot="props">
                    <span v-html="props ? props.row.D1 : ''"> </span>
                  </template>
                </el-table-column>
                <el-table-column :min-width="100" small="D2">
                  <template v-slot="props">
                    <span v-html="props ? props.row.D2 : ''"> </span>
                  </template>
                </el-table-column>
              </el-table>
            </div>
          </div>
          <div
            slot="footer"
            class="col-12 d-flex justify-content-center justify-content-sm-between flex-wrap"
          >
            <div class="">
              <p class="card-category">
                Showing {{ from + 1 }} to {{ to }} of {{ total }} entries
              </p>
            </div>
            <l-pagination
              class="pagination-no-border"
              v-model="pagination.currentPage"
              :per-page="pagination.perPage"
              :total="pagination.total"
            >
            </l-pagination>
          </div>
        </card>
      </div>
    </div>
  </div>
</template>
<script>
import {
  Button,
  DatePicker,
  Option,
  Select,
  Table,
  TableColumn,
} from "element-ui";
import { StatsCard, Pagination as LPagination } from "src/components/index";

import axios from "axios";
import Swal from "sweetalert2";
import moment from "moment";
export default {
  components: {
    [Button.name]: Button,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    [Select.name]: Select,
    [Option.name]: Option,
    [DatePicker.name]: DatePicker,
    LPagination,
    StatsCard,
  },
  data() {
    return {
      cardtype: 1,
      activityData: [],
      start_date: moment(new Date()).format("YYYY-MM-DD"),

      coupon_type: 0,
      activity_id: "",
      message: "",
      coupon_qty: "",
      channel_id: "",
      token_bot: "",

      status: 0,

      pagination: {
        perPage: 5,
        currentPage: 1,
        perPageOptions: [5, 10, 25, 50],
        total: 0,
      },
      searchQuery: "",
      tableData: [],

      loading: false,

      configDisabled: true,

      sumtype1: "",
      sumtype2: "",
    };
  },

  computed: {
    to() {
      let highBound = this.from + this.pagination.perPage;
      if (this.total < highBound) {
        highBound = this.total;
      }
      return highBound;
    },
    from() {
      this.getLogTelegram();
      return this.pagination.perPage * (this.pagination.currentPage - 1);
    },
    total() {
      return this.pagination.total;
    },
  },
  mounted() {
    this.getTelegram();
  },
  methods: {
    changeCard(val) {
      this.cardtype = val;
      this.coupon_type = 0;
      this.activity_id = "";
      this.message = "";
      this.coupon_qty = "";
      this.channel_id = "";
      this.token_bot = "";
      this.status = 0;

      this.getTelegram();
    },
    async getTelegram() {
      if (this.cardtype != 2) {
        return;
      }

      this.loading = true;
      let offset = this.pagination.perPage * (this.pagination.currentPage - 1);
      let config = {
        method: "get",
        maxBodyLength: Infinity,
        url: `${this.$hostname}/get_telegram?search=&sort&order=desc&offset=${offset}&limit=${this.pagination.perPage}&action&start_date=&end_date=&id=${this.status}`,
        headers: {
          authorization: this.$cookies.get("token"),
        },
      };

      await axios
        .request(config)
        .then((res) => {
          this.loading = false;
          let data = res.data;
          if (data.res_code === "00") {
            this.coupon_type = data.res_result.coupon_type;
            this.activity_id = data.res_result.activity_id;
            this.message = data.res_result.message;
            this.coupon_qty = data.res_result.coupon_qty;
            this.token_bot = data.res_result.token_bot;
            this.channel_id = data.res_result.channel_id;
            this.status = data.res_result.status;
            this.configDisabled = true;

            this.getLogTelegram();

            if (this.cardtype == 2) {
              this.getActivity();
            }
          }
        })
        .catch((error) => {
          this.loading = false;
        });
    },
    async getActivity() {
      if (this.coupon_type == 0) {
        return;
      }
      let url = "";

      if (this.coupon_type == 1) {
        url = `${this.$hostname}/coupon?search=&sort=&order=desc&offset=0&limit=10`;
      } else {
        url = `${this.$hostname}/coupon_spin?search=&sort=&order=desc&offset=0&limit=10&spin_id=`;
      }

      const config = {
        method: "get",
        maxBodyLength: Infinity,
        url: url,
        headers: {
          authorization: this.$cookies.get("token"),
        },
      };

      await axios
        .request(config)
        .then((res) => {
          this.loading = false;
          const data = res.data;
          if (data.res_code === "00") {
            this.activityData = [];
            const activity = data.res_result.rows;
            activity.forEach((row) => {
              if (this.coupon_type == 1) {
                this.activityData.push({
                  value: row.coupon_id,
                  label: row.coupon_key,
                });
              } else {
                this.activityData.push({
                  value: row.spin_id,
                  label: row.spin_name,
                });
              }
            });
          }
        })
        .catch((error) => {
          this.loading = false;
        });
    },
    async getLogTelegram() {
      if (this.status != "") {
        return;
      }
      this.loading = true;
      let offset = this.pagination.perPage * (this.pagination.currentPage - 1);
      let config = {
        method: "get",
        maxBodyLength: Infinity,
        url: `${this.$hostname}/get_logtelegram?search=&sort&order=desc&offset=${offset}&limit=${this.pagination.perPage}&action&start_date=${this.start_date}&end_date=&id=${this.status}`,
        headers: {
          authorization: this.$cookies.get("token"),
        },
      };

      await axios
        .request(config)
        .then((res) => {
          this.loading = false;
          let data = res.data;
          this.tableData = [];
          this.sumtype1 = "0/0";
          this.sumtype2 = "0/0";
          if (data.res_code === "00") {
            this.tableData = data.res_result.rows;
            this.pagination.total = data.res_result.total;
            this.sumtype1 = data.res_result.sumtype1;
            this.sumtype2 = data.res_result.sumtype2;
          }
        })
        .catch((error) => {
          this.loading = false;
        });
    },
    async submitUpdateConfig() {
      if (
        !this.activity_id ||
        !this.coupon_type ||
        !this.message ||
        !this.coupon_qty ||
        !this.token_bot ||
        !this.channel_id
      ) {
        return Swal.fire("แจ้งเตือน", "กรุณากรอกข้อมูลให้ครบ", "info");
      }

      let data = {
        activity_id: this.activity_id,
        coupon_type: this.coupon_type,
        message: this.message,
        coupon_qty: this.coupon_qty,
        token_bot: this.token_bot,
        channel_id: this.channel_id,
        status: this.status,
      };

      let config = {
        method: "post",
        maxBodyLength: Infinity,
        url: `${this.$hostname}/configtelegram`,
        headers: {
          Authorization: this.$cookies.get("token"),
          "Content-Type": "application/json",
        },
        data: data,
      };

      Swal.fire({
        title: "แจ้งเตือน",
        text: "ยืนยันการแก้ไขข้อมูล",
        icon: "info",
        showCancelButton: true,
        confirmButtonColor: "green",
        cancelButtonColor: "#d33",
        confirmButtonText: "ยืนยัน",
        cancelButtonText: "ยกเลิก",
      }).then(async (result) => {
        if (result.isConfirmed) {
          this.loading = true;

          await axios
            .request(config)
            .then((res) => {
              this.loading = false;
              const data = res.data;
              if (data.res_code === "00") {
                this.getTelegram();
                this.configDisabled = true;
                return Swal.fire({
                  title: "แจ้งเตือน",
                  text: data.res_text,
                  icon: "success",
                  timer: 1500,
                  showConfirmButton: false,
                });
              } else {
                this.loading = false;
                return Swal.fire("แจ้งเตือน", data.res_text, "error");
              }
            })
            .catch((error) => {});
        }
      });
    },
    async submitSendMessage() {
      if (
        !this.activity_id ||
        !this.coupon_type ||
        !this.message ||
        !this.coupon_qty
      ) {
        return Swal.fire("แจ้งเตือน", "กรุณากรอกข้อมูลให้ครบ", "info");
      }

      let data = {
        activity_id: this.activity_id,
        coupon_type: this.coupon_type,
        message: this.message,
        coupon_qty: this.coupon_qty,
      };

      let config = {
        method: "post",
        maxBodyLength: Infinity,
        url: `${this.$hostname}/send_telegram`,
        headers: {
          Authorization: this.$cookies.get("token"),
          "Content-Type": "application/json",
        },
        data: data,
      };

      Swal.fire({
        title: "แจ้งเตือน",
        text: "ยืนยันการส่งข้อความ",
        icon: "info",
        showCancelButton: true,
        confirmButtonColor: "green",
        cancelButtonColor: "#d33",
        confirmButtonText: "ยืนยัน",
        cancelButtonText: "ยกเลิก",
      }).then(async (result) => {
        if (result.isConfirmed) {
          this.loading = true;
          await axios
            .request(config)
            .then((res) => {
              this.loading = false;
              const data = res.data;
              if (data.res_code === "00") {
                this.changeCard(1);
                // this.getLogTelegram();
                return Swal.fire({
                  title: "แจ้งเตือน",
                  text: data.res_text,
                  icon: "success",
                  timer: 1500,
                  showConfirmButton: false,
                });
              } else {
                this.loading = false;
                return Swal.fire("แจ้งเตือน", data.res_text, "error");
              }
            })
            .catch((error) => {});
        }
      });
    },
  },
};
</script>
<style lang=""></style>
