<template>
  <div v-if="!this.$func.checkPermission('ระบบคูปอง')" class="row">
    <div class="col">
      <div class="card">
        <div class="card-body">
          <div class="alert alert-danger" role="alert">
            <strong>คุณไม่มีสิทธิ์เข้าถึงหน้านี้</strong>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div v-else>
    <div class="row">
      <div class="col-12">
        <h3 class="mb-3 mt-0">ระบบคูปอง</h3>
        <card>
          <div slot="header">
            <button class="btn btn-primary btn-fill mb-0" @click="addCoupon()">
              <i class="fa fa-plus" aria-hidden="true"></i>
              สร้างกิจกรรม
            </button>
          </div>
          <div class="row">
            <div class="col-sm-3 d-inline-block">
              <el-select
                class="select-default mb-3 w-100"
                v-model="pagination.perPage"
                placeholder="Per page"
              >
                <el-option
                  class="select-default"
                  v-for="item in pagination.perPageOptions"
                  :key="item"
                  :label="item"
                  :value="item"
                >
                </el-option>
              </el-select>
            </div>
            <div class="col-sm-3 offset-sm-6 d-inline-block text-right">
              <!-- <el-input
                type="search"
                class="mb-3"
                placeholder="ค้นหา"
                v-model="searchQuery"
                aria-controls="datatables"
              /> -->
              <el-button
                type="danger"
                size="medium"
                @click="getCouponList()"
                :loading="loading"
                ><i class="fa fa-refresh" aria-hidden="true"></i
              ></el-button>
            </div>
          </div>
          <div class="row">
            <div class="col-sm-12">
              <el-table stripe :data="queriedData" border v-loading="loading">
                <el-table-column :min-width="50" label="ลำดับ">
                  <template v-slot="props">
                    <span v-html="props ? props.$index + 1 : ''"></span>
                  </template>
                </el-table-column>
                <el-table-column :min-width="100" label="ชื่อกิจกรรม">
                  <template v-slot="props">
                    <span
                      v-html="props ? props.row.D2 : ''"
                      class="mr-2"
                    ></span>
                    <span
                      @click="showCouponCode(props.row.coupon_id)"
                      class="bg-info p-1 text-white rounded overflow-hidden"
                      style="cursor: pointer"
                    >
                      <i class="fa fa-search"></i>
                    </span>
                  </template>
                </el-table-column>
                <el-table-column :min-width="100" label="จำนวนเครดิต">
                  <template v-slot="props">
                    <span v-html="props ? props.row.D3 : ''"> </span>
                  </template>
                </el-table-column>
                <el-table-column :min-width="100" label="ใช้ไปแล้ว">
                  <template v-slot="props">
                    <span
                      v-html="
                        props.row.D5
                          ? Number(props.row.D5).toLocaleString()
                          : '0'
                      "
                    >
                    </span>
                  </template>
                </el-table-column>
                <el-table-column :min-width="100" label="วันที่สร้าง">
                  <template v-slot="props">
                    <span v-html="props ? props.row.D4 : ''"></span>
                  </template>
                </el-table-column>
                <el-table-column :min-width="100" fixed="right" label="จัดการ">
                  <template v-slot="props">
                    <div v-if="props" class="d-flex flex-wrap">
                      <button
                        @click="addCouponCode(props.row.coupon_id)"
                        type="button"
                        class="btn btn-primary btn-sm mr-1"
                      >
                        <i class="fa fa-plus" aria-hidden="true"></i>
                        <span>เพิ่มคูปอง</span>
                      </button>
                      <button
                        @click="addCouponCodeMulti(props.row.coupon_id)"
                        type="button"
                        class="btn btn-info btn-sm mr-1"
                      >
                        <i class="fa fa-plus" aria-hidden="true"></i>
                        <span>เพิ่มหลายคูปอง</span>
                      </button>
                      <button
                        @click="editCoupon(props.row.coupon_id)"
                        type="button"
                        class="btn btn-warning btn-sm mr-1"
                      >
                        <i class="fa fa-edit" aria-hidden="true"></i>
                        <span>แก้ไข</span>
                      </button>
                      <button
                        @click="deleteCoupon(props.row.coupon_key)"
                        type="button"
                        class="btn btn-danger btn-sm mr-1"
                      >
                        <i class="fa fa-trash" aria-hidden="true"></i>
                        <span>ลบ</span>
                      </button>
                    </div>
                    <span v-html="props ? props.row.D6 : ''"></span>
                  </template>
                </el-table-column>
              </el-table>
            </div>
          </div>
          <div
            slot="footer"
            class="col-12 d-flex justify-content-center justify-content-sm-between flex-wrap"
          >
            <div class="">
              <p class="card-category">
                Showing {{ from + 1 }} to {{ to }} of {{ total }} entries
              </p>
            </div>
            <l-pagination
              class="pagination-no-border"
              v-model="pagination.currentPage"
              :per-page="pagination.perPage"
              :total="pagination.total"
            >
            </l-pagination>
          </div>
        </card>
      </div>
    </div>
    <el-dialog :visible.sync="modals.modalAddCoupon" width="30%">
      <span slot="title">
        <i class="fa fa-plus" aria-hidden="true"></i>
        สร้างกิจกรรม
      </span>
      <div>
        <form>
          <div>
            <fg-input
              v-model="formAddCoupon.coupon_key"
              type="text"
              placeholder="กรอกชื่อกิจกรรม"
            >
              <span slot="label"> ชื่อกิจกรรม </span>
            </fg-input>
          </div>
          <div>
            <fg-input
              v-model="formAddCoupon.coupon_credit"
              type="number"
              placeholder="กรอกเครดิตที่แจก"
            >
              <span slot="label"> เครดิตที่แจก </span>
            </fg-input>
          </div>
        </form>
      </div>
      <span slot="footer" class="dialog-footer" v-loading="loadingSubmit">
        <button class="btn btn-success mr-2" @click="submitAddCoupon()">
          เพิ่ม
        </button>
        <button class="btn btn-danger" @click="closeModal('modalAddCoupon')">
          ยกเลิก
        </button>
      </span>
    </el-dialog>
    <el-dialog :visible.sync="modals.modalAddCouponCode" width="30%">
      <span slot="title">
        <i class="fa fa-plus" aria-hidden="true"></i>
        เพิ่มคูปอง
      </span>
      <div>
        <form>
          <input type="hidden" v-model="formAddCouponCode.couponid" />
          <div class="form-group">
            <label for="">รหัสคูปอง</label>
            <div class="d-flex">
              <input
                v-model="formAddCouponCode.history_coupon_key"
                type="text"
                class="form-control mr-2 my-auto"
                name=""
                id=""
                aria-describedby="helpId"
                placeholder=""
                disabled
              />
              <button
                @click="createCouponCode()"
                type="button"
                class="btn btn-primary my-auto"
              >
                <i class="fa fa-refresh" aria-hidden="true"></i>
              </button>
            </div>
          </div>
        </form>
      </div>
      <span slot="footer" class="dialog-footer" v-loading="loadingSubmit">
        <button class="btn btn-success mr-2" @click="submitAddCouponCode()">
          เพิ่มและคัดลอก
        </button>
        <button
          class="btn btn-danger"
          @click="closeModal('modalAddCouponCode')"
        >
          ยกเลิก
        </button>
      </span>
    </el-dialog>
    <el-dialog :visible.sync="modals.modalAddCouponCodeMulti" width="30%">
      <span slot="title">
        <i class="fa fa-plus" aria-hidden="true"></i>
        เพิ่มหลายคูปอง
      </span>
      <div>
        <form>
          <input type="hidden" v-model="formAddCouponCodeMulti.couponid" />
          <div class="form-group">
            <label for="">จำนวนคูปอง</label>
            <div class="d-flex">
              <input
                v-model="formAddCouponCodeMulti.couponAmount"
                type="number"
                class="form-control mr-2 my-auto"
                name=""
                id=""
                aria-describedby="helpId"
                placeholder="กรอกจำนวนคูปอง"
                @click="formAddCouponCodeMulti.couponAmount = ''"
              />
              <button
                @click="createCouponCodeMulti()"
                type="button"
                class="btn btn-primary my-auto"
              >
                <i class="fa fa-plus" aria-hidden="true"></i>
              </button>
            </div>
          </div>
          <div
            class="form-group"
            v-show="formAddCouponCodeMulti.history_coupon_key"
          >
            <label for="">รหัสคูปอง</label>
            <div
              v-for="(item, index) in formAddCouponCodeMulti.history_coupon_key"
              :key="index"
              class="d-flex mb-2"
            >
              <input
                :value="item"
                type="text"
                class="form-control mr-2 my-auto"
                :id="`history_coupon_key_` + index"
                aria-describedby="helpId"
                placeholder=""
                disabled
              />
              <button
                @click="changeCouponCode(index)"
                type="button"
                class="btn btn-info my-auto"
              >
                <i class="fa fa-refresh" aria-hidden="true"></i>
              </button>
            </div>
          </div>
        </form>
      </div>
      <span slot="footer" class="dialog-footer" v-loading="loadingSubmit">
        <button
          class="btn btn-success mr-2"
          @click="submitAddCouponCodeMulti()"
        >
          บันทึกและคัดลอกทั้งหมด
        </button>
        <button
          class="btn btn-danger"
          @click="closeModal('modalAddCouponCodeMulti')"
        >
          ยกเลิก
        </button>
      </span>
    </el-dialog>
    <el-dialog :visible.sync="modals.modalShowCouponCode" width="50%">
      <span slot="title">
        <i class="fa fa-search" aria-hidden="true"></i>
        รายละเอียด
      </span>
      <div>
        <card>
          <div class="row">
            <div class="col-sm-3 d-inline-block">
              <el-select
                class="select-default mb-3 w-100"
                v-model="paginationCouponHistory.perPage"
                placeholder="Per page"
              >
                <el-option
                  class="select-default"
                  v-for="item in paginationCouponHistory.perPageOptions"
                  :key="item"
                  :label="item"
                  :value="item"
                >
                </el-option>
              </el-select>
            </div>
            <div class="col-sm-3 offset-sm-6 d-inline-block text-right">
              <!-- <el-input
                type="search"
                class="mb-3"
                placeholder="ค้นหา"
                v-model="searchQuery"
                aria-controls="datatables"
              /> -->
              <button
                @click="getCouponCodeHistory()"
                type="button"
                class="btn btn-danger my-auto"
              >
                <i class="fa fa-refresh" aria-hidden="true"></i>
              </button>
            </div>
          </div>
          <div class="row">
            <div class="col-sm-12">
              <el-table
                stripe
                :data="tableDataCouponHistory"
                border
                v-loading="loadingCode"
              >
                <el-table-column :min-width="75" label="วัน/เวลา">
                  <template v-slot="props">
                    <span v-html="props ? props.row.D1 : ''"> </span>
                  </template>
                </el-table-column>
                <el-table-column :min-width="100" label="รหัสคูปอง">
                  <template v-slot="props">
                    <span
                      v-html="props ? props.row.D2 : ''"
                      :id="`copy_` + props.row.history_coupon_id"
                    >
                    </span>
                  </template>
                </el-table-column>

                <el-table-column :min-width="50" fixed="right" label="จัดการ">
                  <template v-slot="props">
                    <div v-if="props">
                      <button
                        @click="copyCoupon(props.row.history_coupon_id)"
                        type="button"
                        class="btn btn-success btn-sm"
                      >
                        <i class="fa fa-copy"></i>
                        <span>คัดลอก</span>
                      </button>
                    </div>
                  </template>
                </el-table-column>
              </el-table>
            </div>
          </div>
          <div
            slot="footer"
            class="col-12 d-flex justify-content-center justify-content-sm-between flex-wrap"
          >
            <div class="">
              <p class="card-category">
                Showing {{ fromCouponHistory + 1 }} to {{ toCouponHistory }} of
                {{ totalCouponHistory }} entries
              </p>
            </div>
            <l-pagination
              class="pagination-no-border"
              v-model="paginationCouponHistory.currentPage"
              :per-page="paginationCouponHistory.perPage"
              :total="paginationCouponHistory.total"
            >
            </l-pagination>
          </div>
        </card>
      </div>
      <span slot="footer" class="dialog-footer">
        <button
          class="btn btn-danger"
          @click="closeModal('modalShowCouponCode')"
        >
          ปิด
        </button>
      </span>
    </el-dialog>
    <el-dialog :visible.sync="modals.modalEditCoupon" width="30%">
      <span slot="title">
        <i class="fa fa-edit" aria-hidden="true"></i>
        แก้ไขกิจกรรม
      </span>
      <div>
        <form>
          <div>
            <fg-input
              v-model="formAddCoupon.coupon_key"
              type="text"
              placeholder="กรอกชื่อกิจกรรม"
            >
              <span slot="label"> ชื่อกิจกรรม </span>
            </fg-input>
          </div>
          <div>
            <fg-input
              v-model="formAddCoupon.coupon_credit"
              type="number"
              placeholder="กรอกเครดิตที่แจก"
            >
              <span slot="label"> เครดิตที่แจก </span>
            </fg-input>
          </div>
        </form>
      </div>
      <span slot="footer" class="dialog-footer">
        <button class="btn btn-success mr-2" @click="submitUpdateCoupon()">
          บันทึก
        </button>
        <button class="btn btn-danger" @click="closeModal('modalEditCoupon')">
          ยกเลิก
        </button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
import {
  Dialog,
  Table,
  TableColumn,
  Select,
  Option,
  Button,
  Form,
} from "element-ui";
import Swal from "sweetalert2";

import { Pagination as LPagination } from "src/components/index";
import axios from "axios";
export default {
  components: {
    LPagination,
    [Form.name]: Form,
    [Button.name]: Button,
    [Dialog.name]: Dialog,
    [Select.name]: Select,
    [Option.name]: Option,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
  },
  data() {
    return {
      loading: true,
      loadingCode: true,
      loadingSubmit: false,
      formAddCoupon: {
        coupon_id: "",
        coupon_key: "",
        coupon_credit: "",
      },
      formAddCouponCode: {
        couponid: "",
        history_coupon_key: "",
      },
      formAddCouponCodeMulti: {
        couponAmount: 0,
        couponid: "",
        history_coupon_key: "",
      },
      showCouponCodeId: "",
      modals: {
        modalShowCouponCode: false,
        modalAddCoupon: false,
        modalAddCouponCode: false,
        modalAddCouponCodeMulti: false,
        modalEditCoupon: false,
        centered: false,
        custom: false,
        confirm: false,
      },

      pagination: {
        perPage: 10,
        currentPage: 1,
        perPageOptions: [10, 20, 30, 50],
        total: 0,
      },

      paginationCouponHistory: {
        perPage: 10,
        currentPage: 1,
        perPageOptions: [10, 20, 30, 50],
        total: 0,
      },
      searchQuery: "",
      propsToSearch: ["username"],
      tableColumns: [],
      tableData: [],
      tableDataCouponHistory: [],
      fuseSearch: null,
    };
  },
  computed: {
    pagedData() {
      return this.tableData.slice(this.from, this.to);
    },

    queriedData() {
      let result = this.tableData;
      if (this.searchQuery !== "") {
        result = this.fuseSearch.search(this.searchQuery);
        this.paginationTotal(result.length);
      }
      return result.slice(this.from, this.to);
    },
    to() {
      let highBound = this.from + this.pagination.perPage;
      if (this.total < highBound) {
        highBound = this.total;
      }
      return highBound;
    },
    from() {
      this.getCouponList();
      return this.pagination.perPage * (this.pagination.currentPage - 1);
    },
    total() {
      return this.pagination.total;
    },

    toCouponHistory() {
      let highBound =
        this.fromCouponHistory + this.paginationCouponHistory.perPage;
      if (this.totalCouponHistory < highBound) {
        highBound = this.totalCouponHistory;
      }
      return highBound;
    },
    fromCouponHistory() {
      if (this.showCouponCodeId) {
        this.getCouponCodeHistory();
      }
      return (
        this.paginationCouponHistory.perPage *
        (this.paginationCouponHistory.currentPage - 1)
      );
    },
    totalCouponHistory() {
      return this.paginationCouponHistory.total;
    },
  },
  methods: {
    addCoupon() {
      this.formAddCoupon.coupon_id = "";
      this.formAddCoupon.coupon_key = "";
      this.formAddCoupon.coupon_credit = "";

      this.modals["modalAddCoupon"] = true;
    },
    createCouponCode() {
      this.formAddCouponCode.history_coupon_key = this.makeCouponCode();
    },
    makeCouponCode() {
      let result = "";
      const characters =
        "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
      const charactersLength = characters.length;
      let counter = 0;
      while (counter < 10) {
        result += characters.charAt(
          Math.floor(Math.random() * charactersLength)
        );
        counter += 1;
      }
      return result;
    },
    paginationTotal(value) {
      this.pagination.total = value;
    },

    openModal(name) {
      this.modals[name] = true;
    },
    closeModal(name) {
      this.modals[name] = false;
    },
    async getCouponList() {
      this.loading = true;
      let config = {
        method: "get",
        maxBodyLength: Infinity,
        url: `${this.$hostname}/coupon?search=&sort=&order=desc&offset=0&limit=10`,
        headers: {
          authorization: this.$cookies.get("token"),
        },
      };

      await axios
        .request(config)
        .then((res) => {
          this.loading = false;
          const data = res.data;
          if (data.res_code === "00") {
            this.tableData = [];
            this.tableData = data.res_result.rows;
            this.pagination.total = data.res_result.total;
          }
        })
        .catch((error) => {
          this.loading = false;
        });
    },
    async submitAddCoupon() {
      if (!this.formAddCoupon.coupon_key || !this.formAddCoupon.coupon_credit) {
        return Swal.fire("แจ้งเตือน", "กรุณากรอกข้อมูลให้ครบ", "error");
      }

      let data = JSON.stringify({
        coupon_key: this.formAddCoupon.coupon_key,
        coupon_credit: this.formAddCoupon.coupon_credit,
      });

      let config = {
        method: "post",
        maxBodyLength: Infinity,
        url: `${this.$hostname}/addcoupon`,
        headers: {
          authorization: this.$cookies.get("token"),
          "Content-Type": "application/json",
        },
        data: data,
      };

      await axios
        .request(config)
        .then((res) => {
          let data = res.data;
          if (data.res_code == "00") {
            this.tableData = [];
            this.getCouponList();
            Swal.fire({
              title: "แจ้งเตือน",
              text: data.res_text,
              icon: "success",
              timer: 1500,
              showConfirmButton: false,
            });
            this.modals["modalAddCoupon"] = false;
          } else {
            return Swal.fire("แจ้งเตือน", data.res_text, "error");
          }
        })
        .catch((error) => {});
    },
    addCouponCode(id) {
      this.formAddCouponCode.couponid = id;
      this.createCouponCode();
      this.modals["modalAddCouponCode"] = true;
    },
    async submitAddCouponCode() {
      if (
        !this.formAddCouponCode.couponid ||
        !this.formAddCouponCode.history_coupon_key
      ) {
        return Swal.fire("แจ้งเตือน", "กรุณากรอกข้อมูลให้ครบ", "error");
      }
      let data = JSON.stringify({
        couponid: this.formAddCouponCode.couponid,
        history_coupon_key: this.formAddCouponCode.history_coupon_key,
      });

      this.loadingSubmit = true;

      let config = {
        method: "post",
        maxBodyLength: Infinity,
        url: `${this.$hostname}/insertcouponcode`,
        headers: {
          authorization: this.$cookies.get("token"),
          "Content-Type": "application/json",
        },
        data: data,
      };

      await axios
        .request(config)
        .then((res) => {
          this.loadingSubmit = false;
          let data = res.data;
          if (data.res_code == "00") {
            this.tableData = [];
            this.getCouponList();
            Swal.fire({
              title: "แจ้งเตือน",
              text: data.res_text,
              icon: "success",
              timer: 1500,
              showConfirmButton: false,
            });
            try {
              navigator.clipboard.writeText(
                this.formAddCouponCode.history_coupon_key
              );
            } catch (err) {}
            this.modals["modalAddCouponCode"] = false;

            this.formAddCouponCode.couponid = "";
            this.formAddCouponCode.history_coupon_key = "";
          } else {
            return Swal.fire("แจ้งเตือน", data.res_text, "error");
          }
        })
        .catch((error) => {
          this.loadingSubmit = false;
        });
    },
    createCouponCodeMulti() {
      if (this.formAddCouponCodeMulti.couponAmount < 1) {
        return Swal.fire("แจ้งเตือน", "กรุณากรอกจำนวน", "warning");
      }
      let history_coupon_key = [];
      for (let i = 0; i < this.formAddCouponCodeMulti.couponAmount; i++) {
        history_coupon_key.push(this.makeCouponCode());
      }
      this.formAddCouponCodeMulti.history_coupon_key = history_coupon_key;
    },
    addCouponCodeMulti(id) {
      this.formAddCouponCodeMulti.couponAmount = 0;
      this.formAddCouponCodeMulti.couponid = id;
      this.modals["modalAddCouponCodeMulti"] = true;
    },
    changeCouponCode(index) {
      let history_coupon_key = document.getElementById(
        `history_coupon_key_` + index
      );
      history_coupon_key.value = this.makeCouponCode();
    },
    async submitAddCouponCodeMulti() {
      let history_coupon_key_array = [];
      let copyCoupon = "";
      for (
        let i = 0;
        i < this.formAddCouponCodeMulti.history_coupon_key.length;
        i++
      ) {
        history_coupon_key_array.push(
          document.getElementById(`history_coupon_key_` + i).value
        );
        copyCoupon +=
          document.getElementById(`history_coupon_key_` + i).value + `\n`;
      }

      if (
        history_coupon_key_array.length === 0 ||
        this.history_coupon_key === ""
      ) {
        return Swal.fire("แจ้งเตือน", "กรุณาเพิ่มคูปอง", "error");
      }

      let history_coupon_key = history_coupon_key_array.join(",");

      let data = JSON.stringify({
        couponid: this.formAddCouponCodeMulti.couponid,
        history_coupon_key: history_coupon_key,
      });

      this.loadingSubmit = true;

      let config = {
        method: "post",
        maxBodyLength: Infinity,
        url: `${this.$hostname}/insertcouponcodes`,
        headers: {
          authorization: this.$cookies.get("token"),
          "Content-Type": "application/json",
        },
        data: data,
      };

      await axios
        .request(config)
        .then((res) => {
          this.loadingSubmit = false;
          let data = res.data;
          if (data.res_code == "00") {
            this.tableData = [];
            this.getCouponList();

            navigator.clipboard.writeText(copyCoupon);

            Swal.fire({
              title: "แจ้งเตือน",
              text: data.res_text,
              icon: "success",
              timer: 1500,
              showConfirmButton: false,
            });
            this.modals["modalAddCouponCodeMulti"] = false;
            this.formAddCouponCodeMulti.couponid = "";
            this.formAddCouponCodeMulti.history_coupon_key = "";
          } else {
            return Swal.fire("แจ้งเตือน", data.res_text, "error");
          }
        })
        .catch((error) => {
          this.loadingSubmit = false;
        });
    },
    async showCouponCode(id) {
      this.showCouponCodeId = id;
      await this.getCouponCodeHistory();
      this.modals["modalShowCouponCode"] = true;
    },
    async getCouponCodeHistory() {
      this.loadingCode = true;
      let offset =
        this.paginationCouponHistory.perPage *
        (this.paginationCouponHistory.currentPage - 1);

      let config = {
        method: "get",
        maxBodyLength: Infinity,
        url: `${this.$hostname}/coupon_history?search=&sort=&order=desc&offset=${offset}&limit=${this.paginationCouponHistory.perPage}&coupon_id=${this.showCouponCodeId}`,
        headers: {
          authorization: this.$cookies.get("token"),
        },
      };

      await axios
        .request(config)
        .then((res) => {
          this.loadingCode = false;
          let data = res.data;
          if (data.res_code === "00") {
            this.tableDataCouponHistory = [];
            this.tableDataCouponHistory = data.res_result.rows;
            this.paginationCouponHistory.total = data.res_result.total;
          }
        })
        .catch((error) => {
          this.loadingCode = false;
        });
    },
    async editCoupon(id) {
      this.formAddCoupon.coupon_id = id;
      let config = {
        method: "get",
        maxBodyLength: Infinity,
        url: `${this.$hostname}/coupon?search=&sort=&order=desc&offset=0&limit=10&coupon_id=${id}`,
        headers: {
          authorization: this.$cookies.get("token"),
        },
      };

      await axios
        .request(config)
        .then((res) => {
          const data = res.data;
          if (data.res_code === "00") {
            this.formAddCoupon.coupon_key = data.res_result.rows[0].coupon_key;
            this.formAddCoupon.coupon_credit =
              data.res_result.rows[0].coupon_credit;

            this.modals["modalEditCoupon"] = true;
          }
        })
        .catch((error) => {});
    },
    async submitUpdateCoupon() {
      if (!this.formAddCoupon.coupon_key || !this.formAddCoupon.coupon_credit) {
        return Swal.fire("แจ้งเตือน", "กรุณากรอกข้อมูลให้ครบ", "error");
      }
      let data = JSON.stringify({
        coupon_id: this.formAddCoupon.coupon_id,
        coupon_key: this.formAddCoupon.coupon_key,
        coupon_credit: this.formAddCoupon.coupon_credit,
      });

      this.loadingSubmit = true;

      let config = {
        method: "post",
        maxBodyLength: Infinity,
        url: `${this.$hostname}/update_coupon`,
        headers: {
          authorization: this.$cookies.get("token"),
          "Content-Type": "application/json",
        },
        data: data,
      };

      await axios
        .request(config)
        .then((res) => {
          this.loadingSubmit = false;
          this.tableData = [];
          let data = res.data;
          if (data.res_code == "00") {
            this.getCouponList();
            Swal.fire({
              title: "แจ้งเตือน",
              text: data.res_text,
              icon: "success",
              timer: 1500,
              showConfirmButton: false,
            });
            this.modals["modalEditCoupon"] = false;
            this.formAddCoupon.coupon_id = "";
            this.formAddCoupon.coupon_key = "";
            this.formAddCoupon.coupon_credit = "";
          } else {
            return Swal.fire("แจ้งเตือน", data.res_text, "error");
          }
        })
        .catch((error) => {
          this.loadingSubmit = false;
        });
    },
    deleteCoupon(coupon_key) {
      Swal.fire({
        title: "แจ้งเตือน",
        text: "ยืนยันลบกิจกรรม: " + coupon_key,
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "ยืนยัน",
        cancelButtonText: "ยกเลิก",
      }).then(async (result) => {
        if (result.isConfirmed) {
          let data = JSON.stringify({
            coupon_key: coupon_key,
          });

          let config = {
            method: "post",
            maxBodyLength: Infinity,
            url: `${this.$hostname}/delete_coupon`,
            headers: {
              authorization: this.$cookies.get("token"),
              "Content-Type": "application/json",
            },
            data: data,
          };

          await axios
            .request(config)
            .then((res) => {
              let data = res.data;
              if (data.res_code == "00") {
                this.tableData = [];
                this.getCouponList();
                Swal.fire({
                  title: "แจ้งเตือน",
                  text: data.res_text,
                  icon: "success",
                  timer: 1500,
                  showConfirmButton: false,
                });
              } else {
                return Swal.fire("แจ้งเตือน", data.res_text, "error");
              }
            })
            .catch((error) => {});
        }
      });
    },
    copyCoupon(id) {
      let couponCode = document.getElementById("copy" + id);
      navigator.clipboard.writeText(couponCode.innerHTML);
      Swal.fire(
        "แจ้งเตือน",
        "คัดลอก: " + couponCode.innerHTML + " สำเร็จ",
        "success"
      );
    },
    confirmClose() {
      Swal.fire({
        title: "แจ้งเตือน",
        text: "ต้องการยกเลิกการทำรายการ?",
        icon: "question",
        showCancelButton: true,
        confirmButtonClass: "btn btn-danger",
        cancelButtonClass: "btn btn-warning",
        confirmButtonText: "ยืนยัน",
        cancelButtonText: "ดำเนินการต่อ",
        buttonsStyling: false,
      }).then((result) => {
        if (result.isConfirmed) {
          this.modals["modalAddCoupon"] = false;
          this.modals["modalAddCouponCode"] = false;
          this.modals["modalAddCouponCodeMulti"] = false;
          this.modals["modalEditCoupon"] = false;
        }
      });
    },
  },
  mounted() {
    this.createCouponCode();
  },
  watch: {},
};
</script>
<style></style>
