<template>
  <div>
    <Card>
      <h4 class="m-0">ตั้งค่าธนาคาร</h4>
      <div class="row">
        <div class="col"></div>
      </div>
    </Card>
  </div>
</template>
<script>
import { Card } from "element-ui";

export default { components: { Card } };
</script>
<style lang=""></style>
