<template>
  <div>
    <div class="row">
      <div class="col-12">
        <h3 class="mb-3 mt-0">บันทึกโยกเงิน</h3>
        <card>
          <div class="row">
            <div class="col-6 col-sm-2 d-inline-block">
              <el-select
                class="select-default mb-3 w-100"
                v-model="pagination.perPage"
                placeholder="Per page"
              >
                <el-option
                  class="select-default"
                  v-for="item in pagination.perPageOptions"
                  :key="item"
                  :label="item"
                  :value="item"
                >
                </el-option>
              </el-select>
            </div>
            <div class="col text-right">
              <el-button
                type="danger"
                size="default"
                @click="getWithdrawautoLog()"
                :loading="loading"
              >
                <i class="fa fa-refresh" aria-hidden="true"></i>
              </el-button>
            </div>
          </div>
          <div class="row">
            <div class="col-sm-12">
              <el-table
                stripe
                style="width: 100%"
                :data="tableData"
                border
                v-loading="loading"
              >
                <el-table-column :min-width="50" label="ลำดับ">
                  <template v-slot="props">
                    <span v-html="props ? props.row.D0 : ''"> </span>
                  </template>
                </el-table-column>
                <el-table-column :min-width="100" label="จำนวนเงิน">
                  <template v-slot="props">
                    <span
                      v-html="
                        props
                          ? Number(props.row.D1).toLocaleString(undefined, {
                              minimumFractionDigits: 2,
                            })
                          : ''
                      "
                    >
                    </span>
                  </template>
                </el-table-column>
                <el-table-column :min-width="50" label="วันที่">
                  <template v-slot="props">
                    <span v-html="props ? props.row.D2 : ''"> </span>
                  </template>
                </el-table-column>
              </el-table>
            </div>
          </div>
          <div
            slot="footer"
            class="col-12 d-flex justify-content-center justify-content-sm-between flex-wrap"
          >
            <div class="">
              <p class="card-category">
                Showing {{ from + 1 }} to {{ to }} of {{ total }} entries
              </p>
            </div>
            <l-pagination
              class="pagination-no-border"
              v-model="pagination.currentPage"
              :per-page="pagination.perPage"
              :total="pagination.total"
            >
            </l-pagination>
          </div>
        </card>
      </div>
    </div>
  </div>
</template>
<script>
import {
  Table,
  TableColumn,
  Select,
  Option,
  Dialog,
  DatePicker,
  Button,
} from "element-ui";
import { Pagination as LPagination } from "src/components/index";
import axios from "axios";

export default {
  components: {
    LPagination,
    [Dialog.name]: Dialog,
    [Select.name]: Select,
    [Option.name]: Option,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    [DatePicker.name]: DatePicker,
    [Button.name]: Button,
  },
  data() {
    return {
      loading: true,

      pagination: {
        perPage: 5,
        currentPage: 1,
        perPageOptions: [5, 10, 25, 50],
        total: 0,
      },

      searchQuery: "",
      tableData: [],

      timeOut: "",
    };
  },
  computed: {
    to() {
      let highBound = this.from + this.pagination.perPage;
      if (this.total < highBound) {
        highBound = this.total;
      }
      return highBound;
    },
    from() {
      this.getWithdrawautoLog();
      return this.pagination.perPage * (this.pagination.currentPage - 1);
    },
    total() {
      return this.pagination.total;
    },
  },

  methods: {
    async getWithdrawautoLog() {
      let offset = this.pagination.perPage * (this.pagination.currentPage - 1);

      let config = {
        method: "get",
        maxBodyLength: Infinity,
        url: `${this.$hostname}/withdrawauto_log?search=${this.searchQuery}&sort&order=desc&offset=${offset}&limit=${this.pagination.perPage}&action&start_date=&end_date=`,
        headers: {
          authorization: this.$cookies.get("token"),
        },
        timeout: 2000,
      };
      this.loading = true;

      clearTimeout(this.timeOut);

      this.timeOut = setTimeout(async () => {
        await axios
          .request(config)
          .then((res) => {
            this.loading = false;
            const data = res.data;
            this.tableData = data.res_result.rows;
            this.pagination.total = data.res_result.total;
          })
          .catch((error) => {
            this.loading = false;
          });
      }, 1000);
    },
  },
};
</script>
<style></style>
