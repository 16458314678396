<template>
  <div v-if="!this.$func.checkPermission('บัญชีดำ')" class="row">
    <div class="col">
      <div class="card">
        <div class="card-body">
          <div class="alert alert-danger" role="alert">
            <strong>คุณไม่มีสิทธิ์เข้าถึงหน้านี้</strong>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div v-else>
    <div class="row">
      <div class="col-12">
        <h3 class="mb-3 mt-0">บัญชีดำ</h3>
        <card>
          <div slot="header">
            <button
              @click="addBlackList()"
              type="button"
              class="btn btn-primary"
            >
              เพิ่มรายชื่อ
            </button>
          </div>
          <div class="row">
            <div class="col-sm-12">
              <el-table
                stripe
                style="width: 100%"
                :data="tableData"
                border
                v-loading="loading"
              >
                <el-table-column :min-width="75" label="ลำดับ">
                  <template v-slot="props">
                    <span v-html="props ? props.row.D0 : ''"> </span>
                  </template>
                </el-table-column>
                <el-table-column :min-width="150" label="ชื่อ">
                  <template v-slot="props">
                    <span class="mr-2" v-html="props ? props.row.D1 : ''">
                    </span>
                  </template>
                </el-table-column>
                <el-table-column :min-width="100" label="เบอร์โทร">
                  <template v-slot="props">
                    <span v-html="props ? props.row.D2 : ''"> </span>
                  </template>
                </el-table-column>
                <el-table-column :min-width="100" label="เลขบัญี">
                  <template v-slot="props">
                    <span v-html="props ? props.row.D3 : ''"> </span>
                  </template>
                </el-table-column>
                <el-table-column :min-width="100" label="สถานะปัจจุบัน">
                  <template v-slot="props">
                    <div v-if="props.row.status === 1">
                      <button
                        @click="updateStatus(props.row.id, 0)"
                        type="button"
                        class="btn btn-danger btn-sm"
                      >
                        <i class="fa fa-ban" aria-hidden="true"></i>
                        บล็อค
                      </button>
                    </div>
                    <div v-else>
                      <button
                        @click="updateStatus(props.row.id, 1)"
                        type="button"
                        class="btn btn-success btn-sm"
                      >
                        <i class="fa fa-check" aria-hidden="true"></i>
                        ปลดบล็อค
                      </button>
                    </div>
                  </template>
                </el-table-column>
                <el-table-column :min-width="150" label="รายละเอียด">
                  <template v-slot="props">
                    <span v-html="props ? props.row.D5 : ''"> </span>
                  </template>
                </el-table-column>

                <el-table-column :min-width="100" fixed="right" label="จัดการ">
                  <template v-slot="props">
                    <div v-if="props">
                      <button
                        @click="editBlackList(props.row.id)"
                        class="btn btn-warning btn-sm mr-1"
                      >
                        <i class="fas fa-edit"></i>
                        แก้ไข
                      </button>
                      <button
                        @click="deleteBlackList(props.row.id, 3)"
                        class="btn btn-danger btn-sm mr-1"
                      >
                        <i class="fa fa-trash" aria-hidden="true"></i>
                        ลบ
                      </button>
                    </div>
                  </template>
                </el-table-column>
              </el-table>
            </div>
          </div>
          <div
            slot="footer"
            class="col-12 d-flex justify-content-center justify-content-sm-between flex-wrap"
          >
            <div class="">
              <p class="card-category">
                Showing {{ from + 1 }} to {{ to }} of {{ total }} entries
              </p>
            </div>
            <l-pagination
              class="pagination-no-border"
              v-model="pagination.currentPage"
              :per-page="pagination.perPage"
              :total="pagination.total"
            >
            </l-pagination>
          </div>
        </card>
      </div>
    </div>
    <el-dialog :visible.sync="modals.modalAddBlackList" width="30%">
      <span slot="title">
        <i class="fa fa-user-plus" aria-hidden="true"></i>
        เพิ่มรายชื่อ
      </span>
      <div>
        <div class="mb-3">
          <label for="name" class="form-label"
            >ชื่อ<span class="text-danger">*</span></label
          >
          <input
            v-model="formBlackList.name"
            type="text"
            class="form-control"
            name="name"
            id="name"
            aria-describedby="helpId"
            placeholder="กรอกชื่อ"
          />
        </div>
        <div class="mb-3">
          <label for="phone" class="form-label"
            >เบอร์โทร<span class="text-danger">*</span></label
          >
          <input
            v-model="formBlackList.phone"
            type="tel"
            class="form-control"
            name="phone"
            id="phone"
            aria-describedby="helpId"
            placeholder="กรอกเบอร์โทร"
          />
        </div>
        <div class="mb-3">
          <label for="bank_number" class="form-label">หมายเลขบัญชี</label>
          <input
            v-model="formBlackList.bank_number"
            type="tel"
            class="form-control"
            name="bank_number"
            id="bank_number"
            aria-describedby="helpId"
            placeholder="กรอกหมายเลขบัญชี"
          />
        </div>
        <div class="mb-3">
          <label for="note" class="form-label">รายละเอียด</label>
          <textarea
            v-model="formBlackList.note"
            class="form-control"
            name="note"
            id="note"
            rows="3"
            placeholder="กรอกรายละเอียด"
          ></textarea>
        </div>
      </div>
      <span slot="footer" class="dialog-footer" v-loading="loadingSubmit">
        <button class="btn btn-success mr-2" @click="submitAddBlackList()">
          เพิ่ม
        </button>
        <button class="btn btn-danger" @click="closeModal('modalAddBlackList')">
          ปิด
        </button>
      </span>
    </el-dialog>
    <el-dialog :visible.sync="modals.modalUpdateBlackList" width="30%">
      <span slot="title">
        <i class="fa fa-edit" aria-hidden="true"></i>
        แก้ไขข้อมูล
      </span>
      <div>
        <div class="mb-3">
          <label for="name" class="form-label"
            >ชื่อ<span class="text-danger">*</span></label
          >
          <input
            v-model="formBlackList.name"
            type="text"
            class="form-control"
            name="name"
            id="name"
            aria-describedby="helpId"
            placeholder="กรอกชื่อ"
          />
        </div>
        <div class="mb-3">
          <label for="phone" class="form-label"
            >เบอร์โทร<span class="text-danger">*</span></label
          >
          <input
            v-model="formBlackList.phone"
            type="tel"
            class="form-control"
            name="phone"
            id="phone"
            aria-describedby="helpId"
            placeholder="กรอกเบอร์โทร"
          />
        </div>
        <div class="mb-3">
          <label for="bank_number" class="form-label">หมายเลขบัญชี</label>
          <input
            v-model="formBlackList.bank_number"
            type="tel"
            class="form-control"
            name="bank_number"
            id="bank_number"
            aria-describedby="helpId"
            placeholder="กรอกหมายเลขบัญชี"
          />
        </div>
        <div class="mb-3">
          <label for="note" class="form-label">รายละเอียด</label>
          <textarea
            v-model="formBlackList.note"
            class="form-control"
            name="note"
            id="note"
            rows="3"
            placeholder="กรอกรายละเอียด"
          ></textarea>
        </div>
      </div>
      <span slot="footer" class="dialog-footer" v-loading="loadingSubmit">
        <button class="btn btn-success mr-2" @click="submitUpdateBlackList()">
          บันทึก
        </button>
        <button
          class="btn btn-danger"
          @click="closeModal('modalUpdateBlackList')"
        >
          ปิด
        </button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
import {
  DatePicker,
  Table,
  TableColumn,
  Select,
  Option,
  Button,
  Dialog,
} from "element-ui";
import {
  Pagination as LPagination,
  FormGroupInput as FgInput,
} from "src/components/index";
import axios from "axios";
import moment from "moment";
import Swal from "sweetalert2";

export default {
  components: {
    [Button.name]: Button,
    [DatePicker.name]: DatePicker,
    LPagination,
    [Select.name]: Select,
    [Option.name]: Option,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    [Dialog.name]: Dialog,
  },
  data() {
    return {
      loading: true,
      loadingSubmit: false,
      formBlackList: {
        id: "",
        name: "",
        phone: "",
        bank_number: "",
        note: "",
      },
      modals: {
        modalAddBlackList: false,
        modalUpdateBlackList: false,
        centered: false,
        custom: false,
        confirm: false,
      },
      pagination: {
        perPage: 10,
        currentPage: 1,
        perPageOptions: [10, 50, 100, 200],
        total: 0,
      },
      searchQuery: "",

      tableData: [],
    };
  },
  computed: {
    to() {
      let highBound = this.from + this.pagination.perPage;
      if (this.total < highBound) {
        highBound = this.total;
      }
      return highBound;
    },
    from() {
      this.getBlackList();
      return this.pagination.perPage * (this.pagination.currentPage - 1);
    },
    total() {
      return this.pagination.total;
    },
  },
  methods: {
    async getBlackList() {
      this.loading = true;
      let offset = this.pagination.perPage * (this.pagination.currentPage - 1);
      let config = {
        method: "get",
        maxBodyLength: Infinity,
        url: `${this.$hostname}/get_blacklist?search=&sort&order=desc&offset=${offset}&limit=${this.pagination.perPage}&action&start_date=&end_date=&id=`,
        headers: {
          authorization: this.$cookies.get("token"),
          "Content-Type": "application/json",
        },
      };

      await axios
        .request(config)
        .then((res) => {
          this.loading = false;
          let data = res.data;
          if (data.res_code === "00") {
            this.tableData = [];
            this.tableData = data.res_result.rows;
            this.pagination.total = data.res_result.total;
          }
        })
        .catch((error) => {
          this.loading = false;
        });
    },
    addBlackList() {
      this.formBlackList.id = "";
      this.formBlackList.name = "";
      this.formBlackList.phone = "";
      this.formBlackList.bank_number = "";
      this.formBlackList.note = "";
      this.modals["modalAddBlackList"] = true;
    },
    async submitAddBlackList() {
      if (!this.formBlackList.name || !this.formBlackList.phone) {
        return Swal.fire("แจ้งเตือน", "กรุณากรอกข้อมูลให้ครบ", "error");
      }
      let data = JSON.stringify({
        name: this.formBlackList.name,
        phone: this.formBlackList.phone,
        bank_number: this.formBlackList.bank_number,
        note: this.formBlackList.note,
      });
      this.loadingSubmit = true;

      let config = {
        method: "post",
        maxBodyLength: Infinity,
        url: `${this.$hostname}/blacklist_insert`,
        headers: {
          authorization: this.$cookies.get("token"),
          "Content-Type": "application/json",
        },
        data: data,
      };

      await axios
        .request(config)
        .then((res) => {
          this.loadingSubmit = false;
          let data = res.data;
          if (data.res_code === "00") {
            this.tableData = [];
            this.getBlackList();
            Swal.fire({
              title: "แจ้งเตือน",
              text: data.res_text,
              icon: "success",
              timer: 1500,
              showConfirmButton: false,
            });
            this.modals["modalAddBlackList"] = false;
          } else {
            return Swal.fire("แจ้งเตือน", data.res_text, "error");
          }
        })
        .catch((error) => {
          this.loadingSubmit = false;
        });
    },
    async editBlackList(id) {
      let config = {
        method: "get",
        maxBodyLength: Infinity,
        url: `${this.$hostname}/get_blacklist?search=&sort&order=desc&offset=&limit=&action&start_date=&end_date=&id=${id}`,
        headers: {
          authorization: this.$cookies.get("token"),
          "Content-Type": "application/json",
        },
      };

      await axios
        .request(config)
        .then((res) => {
          let data = res.data;
          if (data.res_code === "00") {
            this.formBlackList.id = data.res_result.rows[0].id;
            this.formBlackList.name = data.res_result.rows[0].name;
            this.formBlackList.phone = data.res_result.rows[0].phone;
            this.formBlackList.bank_number =
              data.res_result.rows[0].bank_number;
            this.formBlackList.note = data.res_result.rows[0].note;
            this.modals["modalUpdateBlackList"] = true;
          }
        })
        .catch((error) => {
          // console.log(error);
        });
    },
    async submitUpdateBlackList() {
      if (!this.formBlackList.name || !this.formBlackList.phone) {
        return Swal.fire("แจ้งเตือน", "กรุณากรอกข้อมูลให้ครบ", "error");
      }
      let data = JSON.stringify({
        id: this.formBlackList.id,
        name: this.formBlackList.name,
        phone: this.formBlackList.phone,
        bank_number: this.formBlackList.bank_number,
        note: this.formBlackList.note,
      });

      this.loadingSubmit = true;

      let config = {
        method: "post",
        maxBodyLength: Infinity,
        url: `${this.$hostname}/blacklist_update`,
        headers: {
          authorization: this.$cookies.get("token"),
          "Content-Type": "application/json",
        },
        data: data,
      };

      await axios
        .request(config)
        .then((res) => {
          this.loadingSubmit = false;
          let data = res.data;
          if (data.res_code === "00") {
            this.tableData = [];
            this.getBlackList();
            Swal.fire({
              title: "แจ้งเตือน",
              text: data.res_text,
              icon: "success",
              timer: 1500,
              showConfirmButton: false,
            });
            this.modals["modalUpdateBlackList"] = false;
          } else {
            return Swal.fire("แจ้งเตือน", data.res_text, "error");
          }
        })
        .catch((error) => {
          this.loadingSubmit = false;
        });
    },
    updateStatus(id, status) {
      Swal.fire({
        title: "แจ้งเตือน",
        text: "ยืนยันเปลี่ยนสถานะ?",
        icon: "question",
        showCancelButton: true,
        confirmButtonClass: "btn btn-success",
        cancelButtonClass: "btn btn-danger",
        confirmButtonText: "ยืนยัน",
        cancelButtonText: "ยกเลิก",
        buttonsStyling: false,
      }).then(async (result) => {
        if (result.isConfirmed) {
          let data = JSON.stringify({
            id: id,
            status: status,
          });

          let config = {
            method: "post",
            maxBodyLength: Infinity,
            url: `${this.$hostname}/blacklist_status`,
            headers: {
              authorization: this.$cookies.get("token"),
              "Content-Type": "application/json",
            },
            data: data,
          };

          await axios
            .request(config)
            .then((res) => {
              let data = res.data;
              if (data.res_code === "00") {
                this.tableData = [];
                this.getBlackList();
                Swal.fire({
                  title: "แจ้งเตือน",
                  text: data.res_text,
                  icon: "success",
                  timer: 1500,
                  showConfirmButton: false,
                });
              } else {
                return Swal.fire("แจ้งเตือน", data.res_text, "error");
              }
            })
            .catch((error) => {
              //   console.log(error);
            });
        }
      });
    },
    deleteBlackList(id, status) {
      Swal.fire({
        title: "แจ้งเตือน",
        text: "ยืนยันการลบรายการ",
        icon: "warning",
        showCancelButton: true,
        confirmButtonClass: "btn btn-success",
        cancelButtonClass: "btn btn-danger",
        confirmButtonText: "ยืนยัน",
        cancelButtonText: "ยกเลิก",
        buttonsStyling: false,
      }).then(async (result) => {
        if (result.isConfirmed) {
          let data = JSON.stringify({
            id: id,
            status: status,
          });

          let config = {
            method: "post",
            maxBodyLength: Infinity,
            url: `${this.$hostname}/blacklist_status`,
            headers: {
              authorization: this.$cookies.get("token"),
              "Content-Type": "application/json",
            },
            data: data,
          };

          await axios
            .request(config)
            .then((res) => {
              let data = res.data;
              if (data.res_code === "00") {
                this.tableData = [];
                this.getBlackList();
                Swal.fire({
                  title: "แจ้งเตือน",
                  text: data.res_text,
                  icon: "success",
                  timer: 1500,
                  showConfirmButton: false,
                });
              } else {
                return Swal.fire("แจ้งเตือน", data.res_text, "error");
              }
            })
            .catch((error) => {
              //   console.log(error);
            });
        }
      });
    },
    openModal(name) {
      this.modals[name] = true;
    },
    closeModal(name) {
      this.historyMemberPhone = "";
      this.modals[name] = false;
    },
    confirmClose() {
      Swal.fire({
        title: "แจ้งเตือน",
        text: "ต้องการยกเลิกการทำรายการ?",
        icon: "question",
        showCancelButton: true,
        confirmButtonClass: "btn btn-danger",
        cancelButtonClass: "btn btn-warning",
        confirmButtonText: "ยืนยัน",
        cancelButtonText: "ดำเนินการต่อ",
        buttonsStyling: false,
      }).then((result) => {
        if (result.isConfirmed) {
          this.modals["modalAddBlackList"] = false;
          this.modals["modalUpdateBlackList"] = false;
        }
      });
    },
  },
  mounted() {},
  watch: {
    searchQuery(newSearch, oldSearch) {
      this.searchQuery = newSearch;
    },
  },
};
</script>
