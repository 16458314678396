<template>
  <div v-if="!this.$func.checkPermission('สรุปภาพรวม')" class="row">
    <div class="col">
      <div class="card">
        <div class="card-body">
          <div class="alert alert-danger" role="alert">
            <strong>คุณไม่มีสิทธิ์เข้าถึงหน้านี้</strong>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div v-else>
    <div class="row">
      <div class="col-12 col-sm-6 col-md-4 col-lg-3">
        <stats-card
          :title="
            Number(total_deposit).toLocaleString(undefined, {
              minimumFractionDigits: 2,
            })
          "
          subTitle="ยอดฝากทั้งหมด"
          class="mb-2"
        >
          <div slot="header" class="icon-success">
            <i class="nc-icon nc-money-coins text-success"></i>
          </div>
        </stats-card>
      </div>

      <div class="col-12 col-sm-6 col-md-4 col-lg-3">
        <stats-card
          :title="
            Number(total_withdraw).toLocaleString(undefined, {
              minimumFractionDigits: 2,
            })
          "
          subTitle="ยอดถอนทั้งหมด"
          class="mb-2"
        >
          <div slot="header" class="icon-danger">
            <i class="nc-icon nc-money-coins text-danger"></i>
          </div>
        </stats-card>
      </div>

      <div class="col-12 col-sm-6 col-md-4 col-lg-3">
        <stats-card
          :title="
            Number(total_bonus).toLocaleString(undefined, {
              minimumFractionDigits: 2,
            })
          "
          subTitle="ฝากโบนัส"
          class="mb-2"
        >
          <div slot="header" class="icon-info">
            <i class="nc-icon nc-money-coins text-info"></i>
          </div>
        </stats-card>
      </div>
      <div class="col-12 col-sm-6 col-md-4 col-lg-3">
        <stats-card
          :title="
            Number(total_lose).toLocaleString(undefined, {
              minimumFractionDigits: 2,
            })
          "
          subTitle="คืนยอดเสีย"
          class="mb-2"
        >
          <div slot="header" class="icon-warning">
            <i class="nc-icon nc-money-coins text-warning"></i>
          </div>
        </stats-card>
      </div>
      <div class="col-12 col-sm-6 col-md-4 col-lg-3">
        <stats-card
          :title="
            Number(total_income).toLocaleString(undefined, {
              minimumFractionDigits: 2,
            })
          "
          subTitle="กำไร/ขาดทุน"
          class="mb-2"
        >
          <div slot="header" class="icon-success">
            <i class="nc-icon nc-money-coins text-success"></i>
          </div>
        </stats-card>
      </div>
      <div class="col-12 col-sm-6 col-md-4 col-lg-3">
        <stats-card
          :title="Number(active_today).toLocaleString()"
          subTitle="สมาชิกใช้งานวันนี้"
          class="mb-2"
        >
          <div slot="header" class="icon-info">
            <i class="nc-icon nc-single-02 text-info"></i>
          </div>
        </stats-card>
      </div>
      <div class="col-12 col-sm-6 col-md-4 col-lg-3">
        <stats-card
          :title="Number(regis_deposit).toLocaleString()"
          subTitle="สมาชิกใหม่ฝากเงิน"
          class="mb-2"
        >
          <div slot="header" class="icon-success">
            <i class="nc-icon nc-single-02 text-success"></i>
          </div>
        </stats-card>
      </div>
      <div class="col-12 col-sm-6 col-md-4 col-lg-3">
        <stats-card
          :title="Number(member_regis).toLocaleString()"
          subTitle="ลูกค้าสมัครวันนี้"
          class="mb-2"
        >
          <div slot="header" class="icon-warning">
            <i class="nc-icon nc-single-02 text-success"></i>
          </div>
        </stats-card>
      </div>
    </div>
    <div
      v-if="showCreditAgent"
      class="bg-danger blink mb-2 text-white text-center py-1"
    >
      แจ้งเตือน!!! Agent คงเหลือ : {{ Number(creditAgent).toLocaleString() }}
    </div>
    <div class="row">
      <div class="col-12">
        <card>
          <div slot="header">
            <h4 class="m-0">ประวัติการฝากเงิน</h4>
          </div>
          <div class="row">
            <div class="col-sm-4 col-md-3 d-inline-block">
              <div class="d-flex mb-2">
                <span class="my-auto mr-2" style="width: 30px">เริ่ม</span>
                <fg-input class="m-0 w-100">
                  <el-date-picker
                    v-model="start"
                    type="date"
                    placeholder="วันที่เริ่ม"
                    format="dd/MM/yyyy"
                    value-format="yyyy-MM-dd"
                    @change="changeDate()"
                  >
                  </el-date-picker>
                </fg-input>
              </div>
            </div>
            <div class="col-sm-4 col-md-3 d-inline-block">
              <div class="d-flex mb-2">
                <span class="my-auto mr-2" style="width: 30px">ถึง</span>
                <fg-input class="m-0 w-100">
                  <el-date-picker
                    v-model="end"
                    type="date"
                    placeholder="ถึง"
                    format="dd/MM/yyyy"
                    value-format="yyyy-MM-dd"
                    @change="changeDate()"
                  >
                  </el-date-picker>
                </fg-input>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-12 d-inline-block">
              <el-button type="info" plain class="mb-2" @click="allDate()"
                >ทั้งหมด</el-button
              >
              <el-button type="info" plain class="mb-2" @click="todayDate()"
                >วันนี้</el-button
              >
              <el-button type="info" plain class="mb-2" @click="yesterdayDate()"
                >เมื่อวาน</el-button
              >
              <el-button
                type="info"
                plain
                class="mb-2 ml-0 ml-sm-2"
                @click="lastDayOfMonthDate()"
                >เดือนนี้</el-button
              >
            </div>
          </div>
          <div class="row">
            <div class="col-sm-2 d-inline-block">
              <el-select
                class="select-default mb-3 w-100"
                v-model="pagination.perPage"
                placeholder="Per page"
                @change="getTransactions()"
              >
                <el-option
                  class="select-default"
                  v-for="item in pagination.perPageOptions"
                  :key="item"
                  :label="item"
                  :value="item"
                >
                </el-option>
              </el-select>
            </div>

            <div class="col-sm-3 ml-auto d-flex mb-3">
              <el-input
                type="search"
                class="my-auto mr-2"
                placeholder="ค้นหา"
                v-model="searchQuery"
                aria-controls="datatables"
              />
              <el-button
                type="danger"
                size="default"
                @click="getTransactions()"
                :loading="loadingTransactions"
              >
                <i class="fa fa-refresh" aria-hidden="true"></i>
              </el-button>
            </div>
          </div>
          <div class="row">
            <div class="col-sm-12">
              <el-table
                stripe
                style="width: 100%; text-align: center"
                :data="tableData"
                border
              >
                <el-table-column :min-width="120" label="เวลาทำรายการ">
                  <template v-slot="props">
                    <span v-html="props ? props.row.D13 : ''"> </span>
                  </template>
                </el-table-column>
                <el-table-column :min-width="100" label="วัน/เวลา">
                  <template v-slot="props">
                    <span v-html="props ? props.row.D1 : ''"></span>
                  </template>
                </el-table-column>
                <el-table-column :min-width="100" label="ประเภท">
                  <template v-slot="props">
                    <span v-html="props ? props.row.D2 : ''"></span>
                  </template>
                </el-table-column>
                <el-table-column :min-width="200" label="Username">
                  <template v-slot="props">
                    <span
                      class="mr-1"
                      v-html="props ? props.row.D3 : ''"
                    ></span>
                    <button
                      v-loading="loading"
                      @click="historyMember(props.row.D3)"
                      class="btn btn-primary btn-sm"
                    >
                      <i class="fa fa-search" aria-hidden="true"></i>
                    </button>
                  </template>
                </el-table-column>
                <!-- <el-table-column :min-width="120" label="Userbetflix">
                  <template v-slot="props">
                    <span v-html="props ? props.row.D4 : ''"></span>
                  </template>
                </el-table-column> -->
                <el-table-column :min-width="80" label="ธนาคาร">
                  <template v-slot="props">
                    <span v-html="props.row.D5 ? props.row.D5 : '-'"></span>
                  </template>
                </el-table-column>
                <el-table-column :min-width="80" label="จำนวน">
                  <template v-slot="props">
                    <span v-html="Number(props.row.D6).toLocaleString()"></span>
                  </template>
                </el-table-column>
                <el-table-column :min-width="80" label="โบนัส">
                  <template v-slot="props">
                    <span
                      v-html="
                        props
                          ? Number(props.row.D7).toLocaleString(undefined, {
                              minimumFractionDigits: 2,
                            })
                          : ''
                      "
                    ></span>
                  </template>
                </el-table-column>
                <el-table-column :min-width="100" label="จำนวนก่อน">
                  <template v-slot="props">
                    <span
                      v-html="
                        props
                          ? Number(props.row.D8).toLocaleString(undefined, {
                              minimumFractionDigits: 2,
                            })
                          : ''
                      "
                    ></span>
                  </template>
                </el-table-column>
                <el-table-column :min-width="100" label="จำนวนหลัง">
                  <template v-slot="props">
                    <span
                      v-html="
                        props
                          ? Number(props.row.D9).toLocaleString(undefined, {
                              minimumFractionDigits: 2,
                            })
                          : ''
                      "
                    ></span>
                  </template>
                </el-table-column>
                <el-table-column :min-width="150" label="รายละเอียด">
                  <template v-slot="props">
                    <span v-if="props.row.Qr">
                      {{ props.row.detail }}
                    </span>
                    <span
                      v-else-if="
                        props.row.type === '0' && props.row.way === 'AUTO'
                      "
                      class="badge badge-warning"
                    >
                      {{ props.row.D12 }}
                    </span>
                    <span
                      v-else-if="props.row.D12 === '9'"
                      class="badge badge-danger"
                    >
                      แอดมินถอนมือ
                    </span>
                    <span v-else>
                      {{ props.row.D12 }}
                    </span>
                  </template>
                </el-table-column>
                <el-table-column :min-width="150" label="ผู้ทำรายการ">
                  <template v-slot="props">
                    <span v-html="props ? props.row.D11 : ''"></span>
                  </template>
                </el-table-column>
                <el-table-column :min-width="100" label="จัดการ">
                  <template v-slot="props">
                    <div v-if="props.row.status === 1">
                      <button
                        @click="submitPullCredit(props.row.D3, props.row.D6)"
                        class="btn btn-danger btn-sm mr-1 w-80px"
                      >
                        <i class="fa fa-refresh" aria-hidden="true"></i>
                        ดึงคืน
                      </button>
                    </div>
                  </template>
                </el-table-column>
              </el-table>
            </div>
          </div>
          <div
            slot="footer"
            class="col-12 d-flex justify-content-center justify-content-sm-between flex-wrap"
          >
            <div class="">
              <p class="card-category">
                Showing {{ from + 1 }} to {{ to }} of {{ total }} entries
              </p>
            </div>
            <l-pagination
              class="pagination-no-border"
              v-model="pagination.currentPage"
              :per-page="pagination.perPage"
              :total="pagination.total"
            >
            </l-pagination>
          </div>
        </card>
      </div>
    </div>
    <el-dialog :visible.sync="modals.modalQrcode" width="20%">
      <span slot="title">
        <i class="fa fa-qrcode" aria-hidden="true"></i>
        Qrcode
      </span>
      <div>
        <qrcode :text="qrcodevalue" class="qrcode m-auto"></qrcode>
      </div>
      <span slot="footer" class="dialog-footer">
        <button class="btn btn-danger" @click="closeModal('modalQrcode')">
          ปิด
        </button>
      </span>
    </el-dialog>
    <el-dialog
      :visible.sync="modals.historyMember"
      width="90%"
      :lock-scroll="false"
    >
      <span slot="title">
        <i class="fa fa-mobile" aria-hidden="true"></i>
        เบอร์ {{ historyMemberPhone }}
      </span>
      <div>
        <div class="row">
          <div class="col-12 col-sm-6 col-md-4 col-lg-3">
            <stats-card
              :title="
                Number(deposit).toLocaleString(undefined, {
                  minimumFractionDigits: 2,
                })
              "
              subTitle="ยอดฝากทั้งหมด"
              class="mb-2"
            >
              <div slot="header" class="icon-success">
                <i class="nc-icon nc-money-coins text-success"></i>
              </div>
            </stats-card>
          </div>

          <div class="col-12 col-sm-6 col-md-4 col-lg-3">
            <stats-card
              :title="
                Number(withdraw).toLocaleString(undefined, {
                  minimumFractionDigits: 2,
                })
              "
              subTitle="ยอดถอนทั้งหมด"
              class="mb-2"
            >
              <div slot="header" class="icon-danger">
                <i class="nc-icon nc-money-coins text-danger"></i>
              </div>
            </stats-card>
          </div>

          <div class="col-12 col-sm-6 col-md-4 col-lg-3">
            <stats-card
              :title="
                Number(income).toLocaleString(undefined, {
                  minimumFractionDigits: 2,
                })
              "
              subTitle="กำไร/ขาดทุน"
              class="mb-2"
            >
              <div slot="header" class="icon-primary">
                <i class="nc-icon nc-money-coins text-primary"></i>
              </div>
            </stats-card>
          </div>

          <div class="col-12 col-sm-6 col-md-4 col-lg-3">
            <stats-card
              :title="
                Number(bonus).toLocaleString(undefined, {
                  minimumFractionDigits: 2,
                })
              "
              subTitle="ฝากโบนัส"
              class="mb-2"
            >
              <div slot="header" class="icon-info">
                <i class="nc-icon nc-money-coins text-info"></i>
              </div>
            </stats-card>
          </div>
        </div>

        <card>
          <div class="row">
            <div class="col-sm-2 d-inline-block">
              <el-select
                class="select-default mb-3 w-100"
                v-model="paginationHistoryMember.perPage"
                placeholder="Per page"
                @change="getDashboardAllByPhone()"
              >
                <el-option
                  class="select-default"
                  v-for="item in paginationHistoryMember.perPageOptions"
                  :key="item"
                  :label="item"
                  :value="item"
                >
                </el-option>
              </el-select>
            </div>

            <div class="col-sm-3 ml-auto text-right">
              <button
                @click="getDashboardAllByPhone()"
                type="button"
                class="btn btn-danger"
              >
                <i class="fa fa-refresh" aria-hidden="true"></i>
              </button>
            </div>
          </div>
          <div class="row">
            <div class="col-sm-12">
              <el-table
                stripe
                style="width: 100%; text-align: center"
                :data="tableDataHistoryMember"
                border
              >
                <el-table-column :min-width="120" label="เวลาทำรายการ">
                  <template v-slot="props">
                    <span v-html="props ? props.row.D13 : ''"> </span>
                  </template>
                </el-table-column>
                <el-table-column :min-width="100" label="วัน/เวลา">
                  <template v-slot="props">
                    <span v-html="props ? props.row.D1 : ''"></span>
                  </template>
                </el-table-column>
                <el-table-column :min-width="100" label="ประเภท">
                  <template v-slot="props">
                    <span v-html="props ? props.row.D2 : ''"></span>
                  </template>
                </el-table-column>
                <el-table-column :min-width="120" label="Username">
                  <template v-slot="props">
                    <span v-html="props ? props.row.D3 : ''"></span>
                  </template>
                </el-table-column>
                <!-- <el-table-column :min-width="100" label="Userbetflix">
                  <template v-slot="props">
                    <span v-html="props ? props.row.D4 : ''"></span>
                  </template>
                </el-table-column> -->
                <el-table-column :min-width="120" label="ธนาคาร">
                  <template v-slot="props">
                    <span v-html="props ? props.row.D5 : ''"></span>
                  </template>
                </el-table-column>
                <el-table-column :min-width="80" label="จำนวน">
                  <template v-slot="props">
                    <span
                      v-html="
                        props
                          ? Number(props.row.D6).toLocaleString(undefined, {
                              minimumFractionDigits: 2,
                            })
                          : ''
                      "
                    ></span>
                  </template>
                </el-table-column>
                <el-table-column :min-width="80" label="โบนัส">
                  <template v-slot="props">
                    <span
                      v-html="
                        props
                          ? Number(props.row.D7).toLocaleString(undefined, {
                              minimumFractionDigits: 2,
                            })
                          : ''
                      "
                    ></span>
                  </template>
                </el-table-column>
                <el-table-column :min-width="100" label="จำนวนก่อน">
                  <template v-slot="props">
                    <span
                      v-html="
                        props
                          ? Number(props.row.D8).toLocaleString(undefined, {
                              minimumFractionDigits: 2,
                            })
                          : ''
                      "
                    ></span>
                  </template>
                </el-table-column>
                <el-table-column :min-width="100" label="จำนวนหลัง">
                  <template v-slot="props">
                    <span
                      v-html="
                        props
                          ? Number(props.row.D9).toLocaleString(undefined, {
                              minimumFractionDigits: 2,
                            })
                          : ''
                      "
                    ></span>
                  </template>
                </el-table-column>
                <el-table-column :min-width="150" label="รายละเอียด">
                  <template v-slot="props">
                    <span
                      v-if="props.row.type === '0' && props.row.way === null"
                    >
                      <button
                        class="btn btn-info btn-sm"
                        @click="qrshow(props ? props.row.D12 : '')"
                      >
                        <i class="fa fa-qrcode" aria-hidden="true"></i>
                      </button>
                    </span>
                    <span
                      v-else-if="
                        props.row.type === '0' && props.row.way === 'AUTO'
                      "
                      class="badge badge-warning"
                    >
                      {{ props.row.D12 }}
                    </span>
                    <span
                      v-else-if="props.row.D12 === '9'"
                      class="badge badge-danger"
                    >
                      แอดมินถอนมือ
                    </span>
                    <span v-else>
                      {{ props.row.D12 }}
                    </span>
                  </template>
                </el-table-column>

                <el-table-column :min-width="180" label="ผู้ทำรายการ">
                  <template v-slot="props">
                    <span v-html="props ? props.row.D11 : ''"></span>
                  </template>
                </el-table-column>
              </el-table>
            </div>
          </div>
          <div
            slot="footer"
            class="col-12 d-flex justify-content-center justify-content-sm-between flex-wrap"
          >
            <div class="">
              <p class="card-category">
                Showing {{ fromMember + 1 }} to {{ toMember }} of
                {{ totalMember }} entries
              </p>
            </div>
            <l-pagination
              class="pagination-no-border"
              v-model="paginationHistoryMember.currentPage"
              :per-page="paginationHistoryMember.perPage"
              :total="paginationHistoryMember.total"
            >
            </l-pagination>
          </div>
        </card>
      </div>
      <span slot="footer" class="dialog-footer">
        <button class="btn btn-danger" @click="closeModal('historyMember')">
          ปิด
        </button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
import {
  DatePicker,
  Table,
  TableColumn,
  Select,
  Option,
  Button,
  Dialog,
} from "element-ui";
import moment from "moment";
import qrcode from "vue-qrcode-component";
import Swal from "sweetalert2";
import {
  StatsCard,
  Pagination as LPagination,
  FormGroupInput as FgInput,
} from "src/components/index";
import axios from "axios";

export default {
  components: {
    [Dialog.name]: Dialog,
    qrcode,
    [Button.name]: Button,
    FgInput,
    [DatePicker.name]: DatePicker,
    StatsCard,
    LPagination,
    [Select.name]: Select,
    [Option.name]: Option,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
  },

  data() {
    return {
      loading: false,
      loadingMember: false,
      loadingTransactions: false,
      deposit: 0,
      income: 0,
      bonus: 0,
      withdraw: 0,
      historyMemberPhone: "",
      modals: {
        historyMember: false,
        modalQrcode: false,
        centered: false,
        custom: false,
        confirm: false,
      },
      start: moment(new Date()).format("YYYY-MM-DD"),
      end: moment(new Date()).format("YYYY-MM-DD"),
      active_today: 0,
      regis_deposit: 0,
      total_bonus: 0,
      total_deposit: 0,
      total_income: 0,
      total_lose: 0,
      total_withdraw: 0,
      member_regis: 0,

      pagination: {
        perPage: 20,
        currentPage: 1,
        perPageOptions: [20, 50, 100, 200],
        total: 0,
      },
      paginationHistoryMember: {
        perPage: 10,
        currentPage: 1,
        perPageOptions: [10, 50, 100, 200],
        total: 0,
      },

      searchQuery: "",
      tableData: [],
      tableDataHistoryMember: [],

      qrcodevalue: "",
      timeTransactions: "",
      timeTransactionsMember: "",
      intervalTransactions: null,

      creditAgent: 0,
      showCreditAgent: false,
    };
  },
  computed: {
    to() {
      let highBound = this.from + this.pagination.perPage;
      if (this.total < highBound) {
        highBound = this.total;
      }

      return highBound;
    },
    from() {
      if (this.modals["historyMember"] == true) return;
      this.getTransactions();
      return this.pagination.perPage * (this.pagination.currentPage - 1);
    },
    total() {
      return this.pagination.total;
    },

    toMember() {
      let highBound = this.fromMember + this.paginationHistoryMember.perPage;
      if (this.totalMember < highBound) {
        highBound = this.totalMember;
      }
      return highBound;
    },
    fromMember() {
      if (this.modals["historyMember"] == false) return;
      this.getDashboardAllByPhone();
      return (
        this.paginationHistoryMember.perPage *
        (this.paginationHistoryMember.currentPage - 1)
      );
    },
    totalMember() {
      return this.paginationHistoryMember.total;
    },
  },
  mounted() {
    this.getDashboard();
    this.intervalTransactions = setInterval(async () => {
      if (this.modals["historyMember"] === true) return;
      if (this.loadingTransactions === true) return;
      await this.getTransactions();
    }, 10000);
  },
  methods: {
    async getCreditAgent() {
      let config = {
        method: "get",
        maxBodyLength: Infinity,
        url: `${this.$hostname}/creditagent`,
        headers: {
          authorization: this.$cookies.get("token"),
        },
        timeout: 2000,
      };

      await axios.request(config).then((res) => {
        const data = res.data.res_result.data;
        this.creditAgent = data.balance;
        if (data.balance < 300000) {
          this.showCreditAgent = true;
        }
      });
    },
    getDashboard() {
      if (this.start === null) {
        this.start = "";
      }
      if (this.end === null) {
        this.end = "";
      }
      let config = {
        method: "post",
        maxBodyLength: Infinity,
        url: `${this.$hostname}/countdashboard`,
        headers: {
          authorization: this.$cookies.get("token"),
        },
        data: {
          start: this.start,
          end: this.end,
        },
      };

      axios
        .request(config)
        .then((res) => {
          const data = res.data.res_result;
          this.active_today = data.active_today;
          this.total_bonus = data.total_bonus;
          this.total_deposit = data.total_deposit;
          this.total_lose = data.total_lose;
          this.total_withdraw = data.total_withdraw;
          this.member_regis = data.member_regis;
          this.total_income =
            this.total_deposit -
            this.total_withdraw -
            this.total_bonus -
            this.total_lose;
          this.getRegisDeposit();
        })
        .catch((error) => {});
    },
    async getTransactions() {
      if (this.modals["historyMember"] == true) return;

      this.loadingTransactions = true;

      let offset = this.pagination.perPage * (this.pagination.currentPage - 1);

      let config = {
        method: "get",
        maxBodyLength: Infinity,
        url: `${this.$hostname}/transactions?search=${this.searchQuery}&sort&order=desc&offset=${offset}&limit=${this.pagination.perPage}&action&start_date=${this.start}&end_date=${this.end}`,
        headers: {
          authorization: this.$cookies.get("token"),
        },
      };
      clearTimeout(this.timeTransactions);
      this.timeTransactions = setTimeout(async () => {
        await axios
          .request(config)
          .then((res) => {
            let data = res.data;
            if (data.res_code === "00") {
              this.tableData = data.res_result.rows;
              this.pagination.total = data.res_result.total;
              // this.getCreditAgent();
            }
            this.loadingTransactions = false;
          })
          .catch((error) => {
            this.loadingTransactions = false;
          });
      }, 500);
    },
    getRegisDeposit() {
      let config = {
        method: "post",
        maxBodyLength: Infinity,
        url: `${this.$hostname}/regis_deposit`,
        headers: {
          authorization: this.$cookies.get("token"),
        },
        data: {
          start: this.start,
          end: this.end,
        },
      };

      axios
        .request(config)
        .then((res) => {
          let data = res.data;
          if (data.res_code === "00") {
            this.regis_deposit = data.res_result.regis_deposit;
          }
        })
        .catch((error) => {});
    },

    changeDate() {
      this.getTransactions();
      this.getDashboard();
    },

    allDate() {
      this.start = "";
      this.end = "";
      this.getDashboard();
      this.getTransactions();
    },

    todayDate() {
      this.start = moment(new Date()).format("YYYY-MM-DD");
      this.end = moment(new Date()).format("YYYY-MM-DD");
      this.getDashboard();
    },
    yesterdayDate() {
      let date = new Date();
      date.setDate(date.getDate() - 1);

      this.start = moment(date).format("YYYY-MM-DD");
      this.end = moment(date).format("YYYY-MM-DD");
      this.getDashboard();
    },
    lastDayOfMonthDate() {
      let date = new Date();
      let month = ("0" + (date.getMonth() + 1)).slice(-2);
      let year = date.getFullYear();

      let start = `${year}-${month}-01`;
      let lastDayOfMonth = new Date(year, month, 0).getDate();
      let end = `${year}-${month}-${lastDayOfMonth}`;

      this.start = start;
      this.end = end;
      this.getDashboard();
    },

    qrshow(value) {
      this.qrcodevalue = value;
      this.modals["modalQrcode"] = true;
    },

    async historyMember(phone) {
      this.modals["historyMember"] = true;
      this.historyMemberPhone = phone;
      this.loading = true;

      let data = JSON.stringify({
        phone: phone,
      });

      let config = {
        method: "post",
        maxBodyLength: Infinity,
        url: `${this.$hostname}/historymember`,
        headers: {
          authorization: this.$cookies.get("token"),
          "Content-Type": "application/json",
        },
        data: data,
      };

      await axios
        .request(config)
        .then((res) => {
          let data = res.data;
          this.loading = false;

          if (data.res_code === "00") {
            data = data.res_result[0];

            if (!data.deposit && !data.withdraw && !data.bonus) {
              return Swal.fire("แจ้งเตือน", "ไม่พบรายการฝาก", "error");
            }

            this.bonus = data.bonus ? data.bonus : 0;
            this.deposit = data.deposit;
            this.withdraw = data.withdraw;
            this.income = data.deposit - data.withdraw - data.bonus;

            // this.getDashboardAllByPhone();
          }
        })
        .catch((error) => {
          this.loading = false;
        });
    },
    async getDashboardAllByPhone() {
      // this.loadingMember = true;
      let offset =
        this.paginationHistoryMember.perPage *
        (this.paginationHistoryMember.currentPage - 1);
      let config = {
        method: "get",
        maxBodyLength: Infinity,
        url: `${this.$hostname}/transactions?search=${this.historyMemberPhone}&sort&order=desc&offset=${offset}&limit=${this.paginationHistoryMember.perPage}&action&start_date=&end_date=`,
        headers: {
          authorization: this.$cookies.get("token"),
          "Content-Type": "application/json",
        },
      };

      clearTimeout(this.timeDataMember);
      this.timeDataMember = setTimeout(async () => {
        await axios
          .request(config)
          .then((res) => {
            let data = res.data;
            if (data.res_code === "00") {
              this.tableDataHistoryMember = data.res_result.rows;
              this.paginationHistoryMember.total = data.res_result.total;
            }
            this.loadingMember = false;
          })
          .catch((error) => {
            this.loadingMember = false;
          });
      }, 500);
    },
    closeModal(name) {
      // this.historyMemberPhone = "";
      this.modals[name] = false;
    },
    submitPullCredit(phone, credit_withdraw) {
      if (!phone || !credit_withdraw) {
        return Swal.fire("แจ้งเตือน", "กรุณากรอกข้อมูลให้ครบ", "warning");
      }
      Swal.fire({
        title: "แจ้งเตือน",
        html: `ยืนยันการดึงเครดิตคืน จำนวน: ${credit_withdraw} จากเบอร์: ${phone}`,
        icon: "info",
        showCancelButton: true,
        confirmButtonClass: "btn btn-success",
        cancelButtonClass: "btn btn-danger",
        confirmButtonText: "ยืนยัน",
        cancelButtonText: "ยกเลิก",
        buttonsStyling: false,
        allowOutsideClick: () => !Swal.isLoading(),
      }).then(async (result) => {
        if (result.isConfirmed) {
          let data = JSON.stringify({
            phone: phone,
            credit_withdraw: credit_withdraw,
            note: "ดึงเครดิตคืนด่วน",
          });

          let config = {
            method: "post",
            maxBodyLength: Infinity,
            url: `${this.$hostname}/withdrawback`,
            headers: {
              authorization: this.$cookies.get("token"),
              "Content-Type": "application/json",
            },
            data: data,
          };

          await axios
            .request(config)
            .then(async (res) => {
              let data = res.data;
              if (data.res_code === "00") {
                await this.getTransactions();

                phone = "";
                credit_withdraw = 0;
                return Swal.fire({
                  title: "แจ้งเตือน",
                  text: data.res_text,
                  icon: "success",
                  timer: 1500,
                  showConfirmButton: false,
                });
              } else {
                return Swal.fire("แจ้งเตือน", data.res_text, "error");
              }
            })
            .catch((error) => {
              return Swal.fire("แจ้งเตือน", error, "error");
            });
        }
      });
    },
  },

  watch: {
    searchQuery(newSearch, oldSearch) {
      this.searchQuery = newSearch;
    },
  },
  beforeDestroy() {
    clearInterval(this.intervalTransactions);
    this.intervalTransactions = 0;
  },
};
</script>
