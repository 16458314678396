<template>
  <div>
    <el-card shadow="always" :body-style="{ padding: '20px' }">
      <div slot="header">
        <span>ช่องทางฝากเงิน</span>
      </div>
      <div>
        <el-table :data="paymentData" border stripe>
          <el-table-column :min-width="75" label="">
            <template v-slot="props">
              <span v-html="props ? props.row.payment_label : ''"> </span>
            </template>
          </el-table-column>
          <el-table-column :min-width="75" label="ใช้งาน">
            <template v-slot="props">
              <el-button
                v-if="props.row.active === '1'"
                type="success"
                size="mini"
                @click="changeActive(props.row.id, 0)"
              >
                เปิดระบบ
              </el-button>
              <el-button
                v-else
                type="danger"
                size="mini"
                @click="changeActive(props.row.id, 1)"
              >
                ปิดระบบ
              </el-button>
            </template>
          </el-table-column>
          <el-table-column :min-width="75" label="ปิดปรับปรุง">
            <template v-slot="props">
              <el-button
                v-if="props.row.close === '1'"
                type="success"
                size="mini"
                @click="changeClose(props.row.id, 0)"
              >
                กำลังใช้งาน
              </el-button>
              <el-button
                v-else
                type="danger"
                size="mini"
                @click="changeClose(props.row.id, 1)"
              >
                ปิดปรับปรุง
              </el-button>
            </template>
          </el-table-column>
        </el-table>
      </div>
    </el-card>
  </div>
</template>
<script>
import axios from "axios";
import { Button, Card, Table, TableColumn } from "element-ui";
import Swal from "sweetalert2";
export default {
  components: {
    [Button.name]: Button,
    [Card.name]: Card,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
  },
  data() {
    return {
      paymentData: [],
    };
  },
  mounted() {
    this.getPayment();
  },
  methods: {
    async getPayment() {
      let config = {
        method: "get",
        url: `https://dev-tool.info/api.php?getPayment&id=10`,
      };

      await axios
        .request(config)
        .then((res) => {
          this.paymentData = res.data;
        })
        .catch((error) => {});
    },
    async changeActive(id, active) {
      Swal.fire({
        title: "แจ้งเตือน",
        text: "ยืนยันทำรายการ",
        icon: "info",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "ยืนยัน",
        cancelButtonText: "ยกเลิก",
      }).then(async (result) => {
        if (result.isConfirmed) {
          let config = {
            method: "get",
            url: `https://dev-tool.info/api.php?paymentActive&id=${id}&active=${active}`,
          };

          await axios
            .request(config)
            .then((res) => {
              const data = res.data;
              if (data.code === 200) {
                this.getPayment();
                return Swal.fire("แจ้งเตือน", data.msg, "success");
              } else {
                return Swal.fire("แจ้งเตือน", data.msg, "error");
              }
            })
            .catch((error) => {
              //   console.log(error);
            });
        }
      });
    },
    changeClose(id, close) {
      Swal.fire({
        title: "แจ้งเตือน",
        text: "ยืนยันทำรายการ",
        icon: "info",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "ยืนยัน",
        cancelButtonText: "ยกเลิก",
      }).then(async (result) => {
        if (result.isConfirmed) {
          let config = {
            method: "get",
            url: `https://dev-tool.info/api.php?paymentClose&id=${id}&close=${close}`,
          };

          await axios
            .request(config)
            .then((res) => {
              const data = res.data;
              if (data.code === 200) {
                this.getPayment();
                return Swal.fire("แจ้งเตือน", data.msg, "success");
              } else {
                return Swal.fire("แจ้งเตือน", data.msg, "error");
              }
            })
            .catch((error) => {
              //   console.log(error);
            });
        }
      });
    },
  },
};
</script>
<style>
.el-card__header {
  padding: 10px 20px;
}
</style>
