import Vue from "vue";
import VueRouter from "vue-router";
import VueCookies from "vue-cookies";
import LightBootstrap from "./light-bootstrap-main";
// Plugins
import App from "./App.vue";
import "./assets/css/style.css";
import dotenv from "dotenv";
import { func } from "./func";
dotenv.config();
import { Loading } from "element-ui";
import router from "./routes/routes";

// plugin setup
Vue.use(VueRouter);
Vue.use(LightBootstrap);
Vue.use(Loading.directive);
Vue.use(VueCookies);

Vue.prototype.console = console;
Vue.prototype.$func = func;
Vue.prototype.$hostname = process.env.VUE_APP_HOSTNAME;
/* eslint-disable no-new */
new Vue({
  el: "#app",
  render: (h) => h(App),
  router,
});
