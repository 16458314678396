<template>
  <div>
    <el-button type="info" size="default" @click="checkQrcode()">
      <i class="fa fa-qrcode" aria-hidden="true"></i>
      เช็ค Qrcode PomPay
    </el-button>
    <el-button type="info" size="default" @click="checkQrcode('mtpay')">
      <i class="fa fa-qrcode" aria-hidden="true"></i>
      เช็ค Qrcode MTPay
    </el-button>
    <el-dialog
      :visible.sync="modals.modalCheckQrcode"
      :lock-scroll="false"
      width="80%"
    >
      <span slot="title">
        <i class="fa fa-qrcode" aria-hidden="true"></i>
        เช็ค Qrcode
      </span>
      <div>
        <div class="row">
          <div class="col-12">
            <div class="row mb-2">
              <div class="col-12 col-md-4">
                <div class="mb-2">
                  <div class="d-flex">
                    <el-input
                      v-model="searchPhone"
                      type="text"
                      placeholder="กรอกเบอร์โทรศัพท์"
                      class="mr-2 w-100"
                    >
                      <span slot="label"> เบอร์โทร </span>
                    </el-input>
                    <el-button
                      type="primary"
                      size="default"
                      @click="submitSearchPhone()"
                      >ค้นหา</el-button
                    >
                  </div>
                </div>
              </div>
              <div class="col-12 col-md-4 offset-md-4">
                <div class="d-flex">
                  <el-input
                    type="search"
                    class="mr-2 w-100"
                    placeholder="Search Ref"
                    v-model="ref"
                    aria-controls="datatables"
                  />
                  <el-button
                    type="danger"
                    size="default"
                    @click="submitSearchPhone()"
                    >ค้นหา</el-button
                  >
                </div>
              </div>
            </div>
            <card>
              <div>
                <div
                  class="col-12 d-flex justify-content-center justify-content-sm-between flex-wrap"
                >
                  <el-select
                    class="select-default mb-3"
                    style="width: 200px"
                    v-model="pagination.perPage"
                    placeholder="Per page"
                  >
                    <el-option
                      class="select-default"
                      v-for="item in pagination.perPageOptions"
                      :key="item"
                      :label="item"
                      :value="item"
                    >
                    </el-option>
                  </el-select>
                  <!-- <el-input
              type="search"
              class="mb-3"
              style="width: 200px"
              placeholder="Search records"
              v-model="searchQuery"
              aria-controls="datatables"
            /> -->
                </div>
                <div class="col-sm-12">
                  <el-table
                    stripe
                    style="width: 100%"
                    :data="queriedData"
                    border
                  >
                    <el-table-column :min-width="100" label="ID">
                      <template v-slot="props">
                        <span v-html="props ? props.row.id : ''"> </span>
                      </template>
                    </el-table-column>
                    <el-table-column :min-width="150" label="TransactionId">
                      <template v-slot="props">
                        <span v-html="props ? props.row.ref : ''"> </span>
                      </template>
                    </el-table-column>
                    <el-table-column :min-width="100" label="ReferenceId">
                      <template v-slot="props">
                        <span v-html="props ? props.row.referenceId : '-'">
                        </span>
                      </template>
                    </el-table-column>
                    <el-table-column :min-width="100" label="จำนวนเงิน">
                      <template v-slot="props">
                        <span v-html="props ? props.row.amount : '-'"> </span>
                      </template>
                    </el-table-column>
                    <el-table-column :min-width="100" label="สถานะ">
                      <template v-slot="props">
                        <span v-html="props ? props.row.status : '-'"> </span>
                      </template>
                    </el-table-column>
                    <el-table-column :min-width="100" label="วันที่">
                      <template v-slot="props">
                        <span v-html="props ? props.row.createAt : '-'"> </span>
                      </template>
                    </el-table-column>
                    <el-table-column
                      :min-width="120"
                      fixed="right"
                      label="Actions"
                    >
                      <template v-slot="props">
                        <span v-if="props">
                          <button
                            type="button"
                            class="btn btn-danger btn-sm"
                            @click="
                              submitCheckStatus(
                                props.row.ref,
                                props.row.memberid
                              )
                            "
                          >
                            <i class="fa fa-search" aria-hidden="true"></i>
                            ตรวจสอบ
                          </button></span
                        >
                      </template>
                    </el-table-column>
                  </el-table>
                </div>
              </div>
              <div
                slot="footer"
                class="col-12 d-flex justify-content-center justify-content-sm-between flex-wrap"
              >
                <div class="">
                  <p class="card-category">
                    Showing {{ from + 1 }} to {{ to }} of {{ total }} entries
                  </p>
                </div>
                <l-pagination
                  class="pagination-no-border"
                  v-model="pagination.currentPage"
                  :per-page="pagination.perPage"
                  :total="pagination.total"
                >
                </l-pagination>
              </div>
            </card>
          </div>
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <button class="btn btn-danger" @click="closeModal('modalCheckQrcode')">
          ปิด
        </button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
import { Table, TableColumn, Select, Option, Button, Dialog } from "element-ui";
import { Pagination as LPagination } from "src/components/index";
import axios from "axios";
import Swal from "sweetalert2";
import { ref } from "vue";

export default {
  components: {
    LPagination,
    [Select.name]: Select,
    [Option.name]: Option,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    [Button.name]: Button,
    [Dialog.name]: Dialog,
  },
  data() {
    return {
      pagination: {
        perPage: 10,
        currentPage: 1,
        perPageOptions: [5, 10, 25, 50],
        total: 0,
      },

      tableData: [],

      searchPhone: null,
      searchTransId: null,
      ref: null,
      typePayment: "",

      modals: {
        modalCheckQrcode: false,
        centered: false,
        custom: false,
        confirm: false,
      },
    };
  },
  watch: {
    type(newVal, oldVal) {
      this.typePayment = newVal;
      this.searchPhone = "";
    },
  },
  beforeDestroy() {
    this.searchPhone = null;
    this.searchTransId = null;
    this.ref = null;
  },

  computed: {
    pagedData() {
      return this.tableData.slice(this.from, this.to);
    },

    queriedData() {
      let result = this.tableData;

      return result.slice(this.from, this.to);
    },

    to() {
      let highBound = this.from + this.pagination.perPage;
      if (this.total < highBound) {
        highBound = this.total;
      }
      return highBound;
    },
    from() {
      return this.pagination.perPage * (this.pagination.currentPage - 1);
    },
    total() {
      this.paginationTotal(this.tableData.length);
      return this.tableData.length;
    },
  },
  methods: {
    checkQrcode(type) {
      this.typePayment = type;
      this.tableData = [];
      this.modals["modalCheckQrcode"] = true;
    },
    async submitSearchPhone() {
      if (!this.searchPhone) {
        return Swal.fire("แจ้งเตือน", "กรุณากรอกเบอร์โทร", "info");
      }
      let data = JSON.stringify({
        phone: this.searchPhone,
        ref: this.ref,
        type: this.typePayment,
      });

      let config = {
        method: "post",
        maxBodyLength: Infinity,
        url: `${this.$hostname}/CheckGenQr`,
        headers: {
          authorization: this.$cookies.get("token"),
          "Content-Type": "application/json",
        },
        data: data,
      };

      await axios
        .request(config)
        .then((res) => {
          const data = res.data;
          this.tableData = [];
          if (data.res_code === "00") {
            this.tableData = data.res_result;
            this.pagination.total = data.res_result.length;
          }
        })
        .catch((err) => {
          return Swal.fire("แจ้งเตือน", err, "info");
        });
    },
    submitCheckStatus(ref, memberid) {
      axios({
        method: "post",
        url: `${this.$hostname}/check_payment`,
        headers: {
          authorization: this.$cookies.get("token"),
          "Content-Type": "application/json",
        },
        data: {
          ref: ref,
          memberid: memberid,
          type: this.typePayment,
        },
      })
        .then((res) => {
          const data = res.data;
          if (data.res_code === "00") {
            this.submitSearchPhone();
          } else {
            return Swal.fire("แจ้งเตือน", data.res_text, "info");
          }
        })
        .catch((err) => {
          return Swal.fire("แจ้งเตือน", err, "info");
        });
    },
    paginationTotal(value) {
      this.pagination.total = value;
    },
    openModal(name) {
      this.modals[name] = true;
    },
    closeModal(name) {
      this.modals[name] = false;
    },
  },
};
</script>
<style></style>
