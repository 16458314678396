<template>
  <div v-if="!this.$func.checkPermission('ตั้งค่าเว็บไซต์')" class="row">
    <div class="col">
      <div class="card">
        <div class="card-body">
          <div class="alert alert-danger" role="alert">
            <strong>คุณไม่มีสิทธิ์เข้าถึงหน้านี้</strong>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div v-else>
    <div class="row">
      <div class="col-12">
        <card v-loading="loading">
          <div slot="header">
            <h4 class="m-0">ตั้งค่าเว็บไซต์</h4>
          </div>
          <div class="row">
            <div class="col-12 col-lg-5">
              <form method="#" action="#" @submit.prevent>
                <div>
                  <fg-input
                    :disabled="disabled"
                    v-model="logo"
                    type="text"
                    placeholder="กรอก URL โลโก้"
                  >
                    <span slot="label"> URL โลโก้ </span>
                  </fg-input>
                </div>
                <div>
                  <fg-input
                    :disabled="disabled"
                    v-model="title"
                    type="text"
                    placeholder="กรอก title เว็บไซต์"
                  >
                    <span slot="label"> Title เว็บไซต์ </span>
                  </fg-input>
                </div>
                <!-- <div>
                  <fg-input
                    :disabled="disabled"
                    v-model="keyword"
                    type="text"
                    placeholder="กรอก Meta Keyword"
                  >
                    <span slot="label"> Meta Keyword </span>
                  </fg-input>
                </div> -->
                <div>
                  <fg-input
                    :disabled="disabled"
                    v-model="name"
                    type="text"
                    placeholder="กรอกชื่อเว็บไซต์"
                  >
                    <span slot="label"> ชื่อเว็บไซต์ </span>
                  </fg-input>
                </div>
                <div>
                  <fg-input
                    :disabled="disabled"
                    v-model="line_id"
                    type="text"
                    placeholder="กรอกลิ้งค์ไลน์"
                  >
                    <span slot="label"> ลิ้งค์ไลน์ </span>
                  </fg-input>
                </div>
                <div>
                  <fg-input
                    :disabled="disabled"
                    v-model="line_id2"
                    type="text"
                    placeholder="กรอกลิ้งค์ไลน์สำรอง"
                  >
                    <span slot="label"> ลิ้งค์ไลน์สำรอง </span>
                  </fg-input>
                </div>
                <!-- <div>
                  <fg-input
                    :disabled="disabled"
                    v-model="agent_betflix"
                    type="text"
                    placeholder="กรอกเอเย่น betflix"
                  >
                    <span slot="label"> เอเย่น betflix </span>
                  </fg-input>
                </div> -->

                <!-- <div>
                  <fg-input
                    :disabled="disabled"
                    v-model="commission"
                    type="number"
                    placeholder="กรอก % เเนะนำเพื่อน"
                    :min="0"
                    :max="100"
                  >
                    <span slot="label"> % เเนะนำเพื่อน </span>
                  </fg-input>
                </div> -->
                <hr />
                <div>
                  <div v-if="disabled === false">
                    <button
                      @click="updateWebsite()"
                      class="btn btn-primary"
                      type="submit"
                    >
                      <i class="fa fa-save"></i>
                      บันทึก
                    </button>
                  </div>
                  <div v-if="disabled === true">
                    <button
                      @click="disabled = !disabled"
                      type="button"
                      class="btn btn-warning mr-2"
                    >
                      <i class="fa fa-edit"></i>
                      แก้ไข
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </card>
      </div>
    </div>
  </div>
</template>
<script>
import { Button } from "element-ui";
import { FormGroupInput as FgInput } from "src/components/index";
import axios from "axios";
import Swal from "sweetalert2";
export default {
  components: {
    [Button.name]: Button,
    FgInput,
  },
  data() {
    return {
      loading: true,
      logo: "",
      title: "",
      keyword: "",
      name: "",
      line_id: "",
      line_id2: "",
      agent_betflix: "",
      commission: "",
      disabled: true,
    };
  },
  methods: {
    async getWebsite() {
      this.loading = true;
      let config = {
        method: "get",
        maxBodyLength: Infinity,
        url: `${this.$hostname}/website`,
        headers: {
          Authorization: this.$cookies.get("token"),
        },
      };

      await axios
        .request(config)
        .then((res) => {
          this.loading = false;
          let data = res.data;
          if (data.res_code === "00") {
            this.logo = data.res_result[0].logo;
            this.title = data.res_result[0].title;
            this.keyword = data.res_result[0].keyword;
            this.name = data.res_result[0].name;
            this.line_id = data.res_result[0].line_id;
            this.line_id2 = data.res_result[0].line_id2;
            this.agent_betflix = data.res_result[0].agent_betflix;
            this.commission = data.res_result[0].commission;
          }
        })
        .catch((error) => {
          this.loading = false;
        });
    },
    updateWebsite() {
      let data = JSON.stringify({
        logo: this.logo,
        title: this.title,
        name_website: this.name,
        line_id: this.line_id,
        line_id2: this.line_id2,
      });

      let config = {
        method: "post",
        maxBodyLength: Infinity,
        url: `${this.$hostname}/update_website`,
        headers: {
          Authorization: this.$cookies.get("token"),
          "Content-Type": "application/json",
        },
        data: data,
      };

      axios
        .request(config)
        .then((res) => {
          this.disabled = true;
          const data = res.data;
          if (data.res_code === "00") {
            this.getWebsite();
            Swal.fire({
              title: "แจ้งเตือน",
              text: data.res_text,
              icon: "success",
              timer: 1500,
              showConfirmButton: false,
            });
          } else {
            return Swal.fire("แจ้งเตือน", data.res_text, "error");
          }
        })
        .catch((error) => {});
    },
  },
  mounted() {
    this.getWebsite();
  },
};
</script>
<style lang=""></style>
