import axios from "axios";
import dotenv from "dotenv";

dotenv.config();

let intervalRefresh = null;

export default async function auth({ next, router }) {
  if (
    $cookies.get("token") === null &&
    $cookies.get("refresh_token") === null
  ) {
    window.location.href = "/login";
  }

  if (intervalRefresh) {
    clearInterval(intervalRefresh);
  }

  async function refresh() {
    let config = {
      method: "post",
      maxBodyLength: Infinity,
      url: `${process.env.VUE_APP_HOSTNAME}/auth/refresh`,
      headers: {
        authorization: $cookies.get("refresh_token"),
      },
      timeout: 15000,
    };

    await axios
      .request(config)
      .then((response) => {
        if (response.data.res_code == "00") {
          $cookies.set("token", response.data.res_result.token);
          $cookies.set("refresh_token", response.data.res_result.refresh_token);
          global.category = response.data.res_result.category;
        } else {
          removeCookies();
        }
      })
      .catch((err) => {
        // console.log(err);
        removeCookies();
      });
  }

  await refresh();

  intervalRefresh = setInterval(async () => {
    await refresh();
  }, 60000);

  function removeCookies() {
    // $cookies.remove("token");
    // $cookies.remove("refresh_token");
    window.location.href = "/login";
  }

  return next();
}
