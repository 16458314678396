<template>
  <div v-if="!this.$func.checkPermission('ผู้ดูแลADMIN')" class="row">
    <div class="col">
      <div class="card">
        <div class="card-body">
          <div class="alert alert-danger" role="alert">
            <strong>คุณไม่มีสิทธิ์เข้าถึงหน้านี้</strong>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div v-else>
    <div class="row">
      <div class="col-12">
        <h3 class="mb-3 mt-0">จัดการ ผู้ดูแลระบบ</h3>
        <card>
          <div slot="header">
            <button class="btn btn-primary btn-fill mb-0" @click="addAdmin()">
              <i class="fa fa-user-plus" aria-hidden="true"></i>
              เพิ่มผู้ดูแลระบบ
            </button>
          </div>

          <div class="row">
            <div class="col-sm-3 d-inline-block">
              <el-select
                class="select-default mb-3 w-100"
                v-model="pagination.perPage"
                placeholder="Per page"
              >
                <el-option
                  class="select-default"
                  v-for="item in pagination.perPageOptions"
                  :key="item"
                  :label="item"
                  :value="item"
                >
                </el-option>
              </el-select>
            </div>
            <div class="col-sm-3 offset-sm-6 d-flex mb-3">
              <el-input
                type="search"
                class="my-auto mr-2"
                placeholder="ค้นหา"
                v-model="searchQuery"
                aria-controls="datatables"
              />
              <el-button
                type="danger"
                size="medium"
                @click="getAdmin()"
                :loading="loading"
              >
                <i class="fa fa-refresh" aria-hidden="true"></i>
              </el-button>
            </div>
          </div>
          <div class="row">
            <div class="col-sm-12">
              <el-table stripe :data="queriedData" border v-loading="loading">
                <el-table-column :min-width="60" label="ลำดับ">
                  <template v-slot="props">
                    <span v-html="props ? props.row.id : ''"></span>
                  </template>
                </el-table-column>
                <el-table-column :min-width="80" label="ชื่อผู้ใช้">
                  <template v-slot="props">
                    <span v-html="props ? props.row.username : ''"></span>
                  </template>
                </el-table-column>
                <el-table-column :min-width="500" label="จำกัดสิทธิ์">
                  <template v-slot="props">
                    <span
                      v-for="(category, index) in props.row.category.split(',')"
                      :key="index"
                    >
                      <span class="badge badge-info mr-1">{{ category }}</span>
                    </span>
                  </template>
                </el-table-column>

                <el-table-column :min-width="100" label="ตำแหน่ง">
                  <template v-slot="props">
                    <span
                      v-if="props.row.status === 1"
                      class="badge badge-danger"
                    >
                      <i class="fa fa-user-circle" aria-hidden="true"></i>
                      ผู้ดูแลสุงสุด
                    </span>
                    <span v-else class="badge badge-primary">
                      <i class="fa fa-user" aria-hidden="true"></i>
                      แอดมิน
                    </span>
                  </template>
                </el-table-column>
                <el-table-column :min-width="100" fixed="right" label="จัดการ">
                  <template v-slot="props">
                    <div class="d-flex flex-nowrap justify-content-center">
                      <button
                        v-tooltip.top-center="'Edit'"
                        class="btn btn-warning btn-sm mr-1"
                        @click="editAdmin(props.row.username)"
                      >
                        <i class="fa fa-edit"></i>
                      </button>
                      <button
                        v-tooltip.top-center="'Delete'"
                        class="btn btn-danger btn-sm"
                        @click="deleteAdmin(props.row.username)"
                      >
                        <i class="fa fa-trash"></i>
                      </button>
                    </div>
                  </template>
                </el-table-column>
              </el-table>
            </div>
          </div>
          <div
            slot="footer"
            class="col-12 d-flex justify-content-center justify-content-sm-between flex-wrap"
          >
            <div class="">
              <p class="card-category">
                Showing {{ from + 1 }} to {{ to }} of {{ total }} entries
              </p>
            </div>
            <l-pagination
              class="pagination-no-border"
              v-model="pagination.currentPage"
              :per-page="pagination.perPage"
              :total="pagination.total"
            >
            </l-pagination>
          </div>
        </card>
      </div>
    </div>
    <el-dialog :visible.sync="modals.modalAddAdmin" width="30%">
      <span slot="title">
        <i class="fa fa-user-plus" aria-hidden="true"></i>
        เพิ่มผู้ดูแลระบบ
      </span>
      <div>
        <form @submit.prevent>
          <div>
            <fg-input
              v-model="formData.username"
              type="text"
              placeholder="ชื่อผู้ใช้"
            >
              <span slot="label">
                ชื่อผู้ใช้
                <span class="text-danger">(ห้ามใส่สัญลักษณ์พิเศษ)</span>
              </span>
            </fg-input>
          </div>
          <div>
            <fg-input
              v-model="formData.password"
              type="password"
              placeholder="รหัสผ่าน"
            >
              <span slot="label"> รหัสผ่าน </span>
            </fg-input>
          </div>
          <div>
            <fg-input
              v-model="formData.phone"
              type="tel"
              placeholder="เบอร์โทร"
            >
              <span slot="label"> เบอร์โทร </span>
            </fg-input>
          </div>
          <div class="mb-3">
            <div class="title">สถานะ</div>
            <el-select
              class="select-danger w-100 mb-3 w-100"
              v-model="formData.status"
              placeholder="สถานะ"
              @change="changeStatus(formData.status)"
            >
              <el-option
                class="select-danger"
                v-for="option in selects.status"
                :key="option.value"
                :label="option.label"
                :value="option.value"
              >
              </el-option>
            </el-select>

            <!-- <select
              v-model="formData.status"
              class="form-control text-danger border-danger w-100"
              size="large"
              placeholder="สถานะ"
            >
              <option
                v-for="option in selects.status"
                class="select-danger"
                :value="option.value"
                :label="option.label"
                :key="option.label"
              ></option>
            </select> -->
          </div>
          <div>
            <div class="title">จำกัดสิทธิ์</div>
            <el-select
              v-model="formData.category"
              multiple
              class="select-danger w-100"
              size="large"
              placeholder="จำกัดสิทธิ์"
            >
              <el-option
                v-for="option in selects.states"
                class="select-danger"
                :value="option.value"
                :label="option.label"
                :key="option.label"
              >
              </el-option>
            </el-select>
          </div>
        </form>
      </div>
      <span slot="footer" class="dialog-footer" v-loading="loadingSubmit">
        <button class="btn btn-success mr-2" @click="submitAddAdmin()">
          เพิ่ม
        </button>
        <button class="btn btn-danger" @click="closeModal('modalAddAdmin')">
          ยกเลิก
        </button>
      </span>
    </el-dialog>
    <el-dialog :visible.sync="modals.modalUpdateAdmin" width="30%">
      <span slot="title">
        <i class="fa fa-edit" aria-hidden="true"></i>
        แก้ไขแอดมิน
      </span>
      <div>
        <form @submit.prevent>
          <div>
            <fg-input
              v-model="formData.username"
              type="text"
              placeholder="ชื่อผู้ใช้"
              :disabled="true"
            >
              <span slot="label">
                ชื่อผู้ใช้
                <span class="text-danger">(ห้ามใส่สัญลักษณ์พิเศษ)</span>
              </span>
            </fg-input>
          </div>
          <div>
            <fg-input
              v-model="formData.password"
              type="password"
              placeholder="รหัสผ่าน"
            >
              <span slot="label"> รหัสผ่าน </span>
            </fg-input>
          </div>
          <div>
            <fg-input
              v-model="formData.phone"
              type="tel"
              placeholder="เบอร์โทร"
            >
              <span slot="label"> เบอร์โทร </span>
            </fg-input>
          </div>
          <div class="mb-3">
            <div class="title">สถานะ</div>
            <select
              v-model="formData.status"
              class="form-control text-danger border-danger w-100"
              size="large"
              placeholder="สถานะ"
              @change="changeStatus(formData.status)"
            >
              <option
                v-for="option in selects.status"
                class="select-danger"
                :value="option.value"
                :label="option.label"
                :key="option.label"
              ></option>
            </select>
          </div>
          <div>
            <div class="title">จำกัดสิทธิ์</div>
            <el-select
              v-model="formData.category"
              class="select-danger w-100"
              size="large"
              placeholder="จำกัดสิทธิ์"
              multiple
            >
              <el-option
                v-for="option in selects.states"
                class="select-danger"
                :value="option.value"
                :label="option.label"
                :key="option.label"
              >
              </el-option>
            </el-select>
          </div>
        </form>
      </div>
      <span slot="footer" class="dialog-footer" v-loading="loadingSubmit">
        <button class="btn btn-success mr-2" @click="submitUpdateAdmin()">
          บันทึก
        </button>
        <button class="btn btn-danger" @click="closeModal('modalUpdateAdmin')">
          ยกเลิก
        </button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
import {
  Dialog,
  Table,
  TableColumn,
  Select,
  Option,
  Button,
  Form,
} from "element-ui";

import Swal from "sweetalert2";

import { Pagination as LPagination } from "src/components/index";
import axios from "axios";
import Fuse from "fuse.js";

export default {
  components: {
    LPagination,
    [Form.name]: Form,
    [Button.name]: Button,
    [Select.name]: Select,
    [Option.name]: Option,
    [Dialog.name]: Dialog,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
  },
  data() {
    return {
      loading: true,
      loadingSubmit: false,
      formData: {
        username: "",
        phone: "",
        status: "",
        password: "",
        category: [],
      },
      selects: {
        states: [
          { value: "สรุปภาพรวม", label: "สรุปภาพรวม" },
          { value: "ผู้ดูแลADMIN", label: "ผู้ดูแลADMIN" },
          { value: "สมาชิก", label: "สมาชิก" },
          // { value: "สมาชิกไม่ฝาก", label: "สมาชิกไม่ฝาก" },
          // { value: "สมาชิกฝาก", label: "สมาชิกฝาก" },
          { value: "รายการฝาก", label: "รายการฝาก" },
          { value: "ติดตามลูกค้า", label: "ติดตามลูกค้า" },
          { value: "รายการฝากเงิน", label: "รายการฝากเงิน" },
          { value: "รายการคืนยอดเสีย", label: "รายการคืนยอดเสีย" },
          { value: "รายการถอนเงิน", label: "รายการถอนเงิน" },
          { value: "รายการแจ้งถอน", label: "รายการแจ้งถอน" },
          { value: "ระบบคูปอง", label: "ระบบคูปอง" },
          { value: "ระบบพันธมิตร", label: "ระบบพันธมิตร" },
          // { value: "ตั้งค่าระบบ", label: "ตั้งค่าระบบ" },
          { value: "ตั้งค่าเว็บไซต์", label: "ตั้งค่าเว็บไซต์" },
          // { value: "sms", label: "sms" },
          { value: "statement", label: "statement" },
          // { value: "ตั้งค่าโปรโมชั่น", label: "ตั้งค่าโปรโมชั่น" },
          // { value: "สถานะของระบบ", label: "สถานะของระบบ" },
          { value: "ตั้งค่าแจ้งเตือน", label: "ตั้งค่าแจ้งเตือน" },
          { value: "บัญชีดำ", label: "บัญชีดำ" },
          { value: "ระบบคูปองกงล้อ", label: "ระบบคูปองกงล้อ" },
          { value: "ธนาคาร", label: "ธนาคาร" },
          { value: "ส่งข้อความ", label: "ส่งข้อความ" },
          { value: "เทเลแกรม", label: "เทเลแกรม" },
          { value: "หวย", label: "หวย" },
        ],
        status: [
          { value: "", label: "เลือกสถานะ" },
          { value: 0, label: "แอดมินฝากถอน" },
          { value: 1, label: "ผู้ดูแลสูงสุด" },
          { value: 2, label: "แอดมินตอบลูกค้า" },
        ],
        multiple: "ARS",
      },
      modals: {
        modalAddAdmin: false,
        modalUpdateAdmin: false,
        centered: false,
        custom: false,
        confirm: false,
      },

      pagination: {
        perPage: 10,
        currentPage: 1,
        perPageOptions: [10, 20, 30, 50],
        total: 0,
      },
      searchQuery: "",
      propsToSearch: ["username"],
      tableColumns: [],
      tableData: [],
      fuseSearch: null,
      timeGetAdmin: "",
    };
  },
  computed: {
    pagedData() {
      return this.tableData.slice(this.from, this.to);
    },

    queriedData() {
      let result = this.tableData;
      if (this.searchQuery !== "") {
        result = this.fuseSearch.search(this.searchQuery);
        this.paginationTotal(result.length);
      }
      return result.slice(this.from, this.to);
    },
    to() {
      let highBound = this.from + this.pagination.perPage;
      if (this.total < highBound) {
        highBound = this.total;
      }
      return highBound;
    },
    from() {
      this.getAdmin();
      return this.pagination.perPage * (this.pagination.currentPage - 1);
    },
    total() {
      this.paginationTotal(this.tableData.length);
      return this.tableData.length;
    },
  },
  methods: {
    changeStatus(status) {
      if (status === "") {
        this.formData.category = [];
      }
      if (status === 0) {
        this.formData.category = [
          "สรุปภาพรวม",
          "สมาชิก",
          "รายการฝาก",
          "ติดตามลูกค้า",
          "รายการฝากเงิน",
          "รายการคืนยอดเสีย",
          "รายการถอนเงิน",
          "รายการแจ้งถอน",
          "ระบบคูปอง",
          "statement",
          "บัญชีดำ",
          "ระบบคูปองกงล้อ",
          "ธนาคาร",
        ];
      }
      if (status === 1) {
        this.formData.category = [];
        this.selects.states.forEach((el, index) => {
          this.formData.category[index] = el.value;
        });
      }
      if (status === 2) {
        this.formData.category = ["สรุปภาพรวม", "สมาชิก"];
      }
    },
    paginationTotal(value) {
      this.pagination.total = value;
    },
    addAdmin() {
      this.formData.username = "";
      this.formData.phone = "";
      this.formData.status = "";
      this.formData.password = "";
      this.formData.category = [];
      this.modals["modalAddAdmin"] = true;
    },
    async editAdmin(username) {
      let data = JSON.stringify({
        username: username,
      });

      let config = {
        method: "post",
        maxBodyLength: Infinity,
        url: `${this.$hostname}/getadmin`,
        headers: {
          authorization: this.$cookies.get("token"),
          "Content-Type": "application/json",
        },
        data: data,
      };

      await axios
        .request(config)
        .then((res) => {
          let data = res.data;
          if (data.res_code === "00") {
            this.formData.username = data.res_result[0].username;
            this.formData.phone = data.res_result[0].phone;
            this.formData.password = data.res_result[0].password;
            this.formData.status = data.res_result[0].status;

            let categoryArray = data.res_result[0].category.split(",");
            let category = [];
            categoryArray.forEach((element) => {
              if (element) {
                category.push(element);
              }
            });

            category = [...new Set(category)];

            this.formData.category = category;

            this.modals["modalUpdateAdmin"] = true;
          } else {
            return Swal.fire("แจ้งเตือน", data.res_text, "error");
          }
        })
        .catch((error) => {
          // console.log(error);
        });
    },

    openModal(name) {
      this.modals[name] = true;
    },
    closeModal(name) {
      this.modals[name] = false;
    },
    async getAdmin() {
      this.loading = true;
      let config = {
        method: "post",
        maxBodyLength: Infinity,
        url: `${this.$hostname}/getadmin`,
        headers: {
          authorization: this.$cookies.get("token"),
        },
        data: { username: "" },
      };

      clearTimeout(this.timeGetAdmin);

      this.timeGetAdmin = setTimeout(async () => {
        await axios
          .request(config)
          .then((res) => {
            const data = res.data;
            if (data.res_code === "00") {
              this.tableData = data.res_result;
              this.pagination.total = data.res_result.length;
            }
            this.loading = false;
          })
          .catch((error) => {
            this.loading = false;
          });
      }, 200);
    },

    async submitAddAdmin() {
      if (
        !this.formData.username ||
        !this.formData.password ||
        this.formData.status === ""
      ) {
        return Swal.fire("แจ้งเตือน", "กรุณากรอกข้อมูลให้ครบ", "warning");
      }

      let data = JSON.stringify({
        username: this.formData.username,
        phone: this.formData.phone,
        status: this.formData.status,
        password: this.formData.password,
        category: this.formData.category,
      });

      this.loadingSubmit = true;

      let config = {
        method: "post",
        maxBodyLength: Infinity,
        url: `${this.$hostname}/add_admin`,
        headers: {
          authorization: this.$cookies.get("token"),
          "Content-Type": "application/json",
        },
        data: data,
      };

      await axios
        .request(config)
        .then((res) => {
          this.loadingSubmit = false;
          let data = res.data;
          if (data.res_code === "00") {
            this.tableData = [];
            this.getAdmin();
            Swal.fire({
              title: "แจ้งเตือน",
              text: data.res_text,
              icon: "success",
              timer: 1500,
              showConfirmButton: false,
            });
            this.modals["modalAddAdmin"] = false;
          } else {
            return Swal.fire("แจ้งเตือน", data.res_text, "error");
          }
        })
        .catch((error) => {
          this.loadingSubmit = false;
        });
    },
    async submitUpdateAdmin() {
      if (!this.formData.username) {
        return Swal.fire("แจ้งเตือน", "กรุณากรอกข้อมูลให้ครบ", "warning");
      }

      this.loadingSubmit = true;

      let data = JSON.stringify({
        username: this.formData.username,
        phone: this.formData.phone,
        status: this.formData.status,
        password: this.formData.password,
        category: this.formData.category,
      });

      let config = {
        method: "post",
        maxBodyLength: Infinity,
        url: `${this.$hostname}/update_admin`,
        headers: {
          authorization: this.$cookies.get("token"),
          "Content-Type": "application/json",
        },
        data: data,
      };

      await axios
        .request(config)
        .then((res) => {
          this.loadingSubmit = false;
          let data = res.data;
          if (data.res_code === "00") {
            this.tableData = [];
            this.getAdmin();
            Swal.fire({
              title: "แจ้งเตือน",
              text: data.res_text,
              icon: "success",
              timer: 1500,
              showConfirmButton: false,
            });
            this.modals["modalUpdateAdmin"] = false;
          } else {
            return Swal.fire("แจ้งเตือน", data.res_text, "error");
          }
        })
        .catch((error) => {
          this.loadingSubmit = false;
        });
    },
    deleteAdmin(username) {
      let data = JSON.stringify({
        username: username,
      });

      let config = {
        method: "post",
        maxBodyLength: Infinity,
        url: `${this.$hostname}/delete_admin`,
        headers: {
          authorization: this.$cookies.get("token"),
          "Content-Type": "application/json",
        },
        data: data,
      };

      Swal.fire({
        title: "แจ้งเตือน",
        text: "ยืนยันการลบแอดมิน: " + username,
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "ยืนยัน",
        cancelButtonText: "ยกเลิก",
        confirmButtonClass: "btn btn-danger btn-fill",
        cancelButtonClass: "btn btn-default btn-fill",
        buttonsStyling: false,
      }).then(async (result) => {
        if (result.isConfirmed) {
          await axios
            .request(config)
            .then((res) => {
              let data = res.data;
              if (data.res_code == "00") {
                this.getAdmin();
                Swal.fire({
                  title: "แจ้งเตือน",
                  text: data.res_text,
                  icon: "success",
                  timer: 1500,
                  showConfirmButton: false,
                });
              } else {
                return Swal.fire("แจ้งเตือน", data.res_text, "error");
              }
            })
            .catch((error) => {
              // console.log(error);
            });
        }
      });
    },
    confirmClose() {
      Swal.fire({
        title: "แจ้งเตือน",
        text: "ต้องการยกเลิกการทำรายการ?",
        icon: "question",
        showCancelButton: true,
        confirmButtonClass: "btn btn-danger",
        cancelButtonClass: "btn btn-warning",
        confirmButtonText: "ยืนยัน",
        cancelButtonText: "ดำเนินการต่อ",
        buttonsStyling: false,
      }).then((result) => {
        if (result.isConfirmed) {
          this.modals["modalAddAdmin"] = false;
          this.modals["modalUpdateAdmin"] = false;
        }
      });
    },
  },
  mounted() {
    this.fuseSearch = new Fuse(this.tableData, { keys: ["username"] });
  },
  watch: {
    searchQuery(newSearch, oldSearch) {
      this.searchQuery = newSearch;
    },
  },
};
</script>
<style></style>
