<template>
  <div v-if="!this.$func.checkPermission('สมาชิก')" class="row">
    <div class="col">
      <div class="card">
        <div class="card-body">
          <div class="alert alert-danger" role="alert">
            <strong>คุณไม่มีสิทธิ์เข้าถึงหน้านี้</strong>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div v-else>
    <div class="row">
      <div class="col-12 col-sm-6 col-md-4 col-lg-3">
        <stats-card
          :title="Number(total_member).toLocaleString()"
          subTitle="สมาชิกทั้งหมด"
          class="mb-2"
        >
          <div slot="header" class="icon-success">
            <i class="nc-icon nc-single-02 text-success"></i>
          </div>
        </stats-card>
      </div>

      <div class="col-12 col-sm-6 col-md-4 col-lg-3">
        <stats-card
          :title="Number(total_member_month).toLocaleString()"
          subTitle="สมาชิกเดือนนี้"
          class="mb-2"
        >
          <div slot="header" class="icon-info">
            <i class="nc-icon nc-single-02 text-info"></i>
          </div>
        </stats-card>
      </div>

      <div class="col-12 col-sm-6 col-md-4 col-lg-3">
        <stats-card
          :title="Number(total_member_today).toLocaleString()"
          subTitle="สมาชิกวันนี้"
          class="mb-2"
        >
          <div slot="header" class="icon-danger">
            <i class="nc-icon nc-single-02 text-danger"></i>
          </div>
        </stats-card>
      </div>

      <div class="col-12 col-sm-6 col-md-4 col-lg-3">
        <stats-card
          :title="Number(status_pending).toLocaleString()"
          subTitle="สต็อกที่สมัครได้"
          class="mb-2"
        >
          <div slot="header" class="icon-info">
            <i class="nc-icon nc-single-02 text-warning"></i>
          </div>
        </stats-card>
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <card>
          <!-- <button
            class="btn btn-primary btn-fill mb-0"
            @click="openModal('addMember')"
          >
            <i class="fa fa-user-plus" aria-hidden="true"></i>
            เพิ่มผู้ใช้งาน
          </button>
          <hr /> -->
          <div class="row">
            <div class="col-sm-3 d-inline-block">
              <el-select
                class="select-default mb-3 w-100"
                v-model="pagination.perPage"
                placeholder="Per page"
              >
                <el-option
                  class="select-default"
                  v-for="item in pagination.perPageOptions"
                  :key="item"
                  :label="item"
                  :value="item"
                >
                </el-option>
              </el-select>
            </div>
            <div class="col-sm-3 offset-sm-6 d-flex mb-3">
              <el-input
                type="search"
                class="my-auto mr-2"
                placeholder="ค้นหา"
                v-model="searchQuery"
                aria-controls="datatables"
              />
              <el-button
                type="danger"
                size="default"
                @click="getMember()"
                :loading="loading"
              >
                <i class="fa fa-refresh" aria-hidden="true"></i>
              </el-button>
            </div>
          </div>
          <div class="row">
            <div class="col-sm-12">
              <el-table
                stripe
                style="width: 100%"
                :data="tableData"
                border
                v-loading="loading"
              >
                <el-table-column :min-width="60" label="ลำดับ">
                  <template v-slot="props">
                    <span v-html="props ? props.row.D0 : ''"> </span>
                  </template>
                </el-table-column>
                <el-table-column :min-width="100" label="วันที่เข้าร่วม">
                  <template v-slot="props">
                    <span v-html="props ? props.row.D1 : ''"></span>
                  </template>
                </el-table-column>
                <el-table-column :min-width="170" label="Username">
                  <template v-slot="props">
                    <span class="mr-2" v-html="props ? props.row.phone : ''">
                    </span>
                    <button
                      v-loading="loading"
                      @click="historyMember(props.row.phone)"
                      class="btn btn-primary btn-sm"
                    >
                      <i class="fa fa-search" aria-hidden="true"></i>
                    </button>
                  </template>
                </el-table-column>
                <el-table-column :min-width="140" label="ชื่อ-นามสกุล">
                  <template v-slot="props">
                    <span v-html="props ? props.row.D4 : ''"></span>
                  </template>
                </el-table-column>
                <el-table-column :min-width="140" label="Userbet">
                  <template v-slot="props">
                    <span v-html="props ? props.row.D5 : ''"></span>
                  </template>
                </el-table-column>
                <el-table-column :min-width="100" label="ไอดีพันธมิตร">
                  <template v-slot="props">
                    <span v-html="props.row.D6 ? props.row.D6 : '-'"></span>
                  </template>
                </el-table-column>
                <el-table-column :min-width="120" label="บัญชีธนาคาร">
                  <template v-slot="props">
                    <span v-html="props ? props.row.D7 : ''"></span>
                  </template>
                </el-table-column>
                <el-table-column :min-width="80" label="ธนาคาร">
                  <template v-slot="props">
                    <span v-html="props ? props.row.D14 : ''"></span>
                  </template>
                </el-table-column>
                <el-table-column :min-width="115" label="จำนวนครั้งที่ฝาก">
                  <template v-slot="props">
                    <span v-html="props ? props.row.D8 : ''"></span>
                  </template>
                </el-table-column>
                <el-table-column :min-width="100" label="เครดิต">
                  <template v-slot="props">
                    <span
                      v-html="
                        props
                          ? Number(props.row.D9).toLocaleString(undefined, {
                              minimumFractionDigits: 2,
                            })
                          : ''
                      "
                    ></span>
                  </template>
                </el-table-column>
                <el-table-column :min-width="80" label="Rank">
                  <template v-slot="props">
                    <span v-html="props ? props.row.D10 : ''"></span>
                  </template>
                </el-table-column>
                <el-table-column :min-width="100" label="ไลน์">
                  <template v-slot="props">
                    <a
                      target="_blank"
                      :href="props.row.D15"
                      v-if="props.row.D15"
                      >{{ props.row.D15 }}</a
                    >
                    <span v-else>-</span>
                  </template>
                </el-table-column>
                <el-table-column :min-width="110" fixed="right" label="จัดการ">
                  <template slot-scope="props" v-loading="loadingSubmit">
                    <!-- <button
                      class="btn btn-success btn-sm mr-1"
                      @click="handleLogin(props.row.user_betflix)"
                    >
                      <i class="fa fa-sign-in" aria-hidden="true"></i>
                    </button> -->
                    <button
                      class="btn btn-warning btn-sm mr-1"
                      @click="editMember(props.row.phone)"
                    >
                      <i class="fa fa-edit"></i>
                    </button>
                    <button
                      class="btn btn-info btn-sm mr-1"
                      @click="changePassword(props.row.phone, props.row.id)"
                    >
                      <i class="fa fa-key" aria-hidden="true"></i>
                    </button>
                    <button
                      class="btn btn-danger btn-sm mr-1"
                      @click="deleteMember(props.row.phone, props.row.id)"
                    >
                      <i class="fa fa-trash" aria-hidden="true"></i>
                    </button>
                  </template>
                </el-table-column>
              </el-table>
            </div>
          </div>
          <div
            slot="footer"
            class="col-12 d-flex justify-content-center justify-content-sm-between flex-wrap"
          >
            <div class="">
              <p class="card-category">
                Showing {{ from + 1 }} to {{ to }} of {{ total }} entries
              </p>
            </div>
            <l-pagination
              class="pagination-no-border"
              v-model="pagination.currentPage"
              :per-page="pagination.perPage"
              :total="pagination.total"
            >
            </l-pagination>
          </div>
        </card>
      </div>
    </div>
    <el-dialog :visible.sync="modals.addMember" width="30%">
      <span slot="title">
        <i class="fa fa-user-plus" aria-hidden="true"></i>
        เพิ่มผู้ใช้งาน
      </span>
      <div>
        <form method="#" action="#" @submit.prevent>
          <div>
            <fg-input type="text" placeholder="เลขบัญชี">
              <span slot="label"> เลขบัญชี </span>
            </fg-input>
          </div>
          <div>
            <div class="title">เลือกธนาคาร</div>
            <el-select
              class="select-default w-100"
              size="large"
              v-model="selects.simple"
              placeholder="เลือกธนาคาร"
            >
              <el-option
                v-for="option in selects.countries"
                class="select-default"
                :value="option.value"
                :label="option.label"
                :key="option.label"
              >
              </el-option>
            </el-select>
          </div>
        </form>
      </div>
      <span slot="footer" class="dialog-footer" v-loading="loadingSubmit">
        <button class="btn btn-success mr-2" @click="closeModal('addMember')">
          เพิ่ม
        </button>
        <button class="btn btn-danger" @click="closeModal('addMember')">
          ยกเลิก
        </button>
      </span>
    </el-dialog>
    <el-dialog :visible.sync="modals.editMember" width="30%">
      <span slot="title">
        <i class="fa fa-edit" aria-hidden="true"></i>
        แก้ไขสมาชิก
      </span>
      <div>
        <form>
          <div>
            <fg-input v-model="form.id" type="hidden" placeholder="">
            </fg-input>
          </div>
          <div class="row">
            <div class="col-12 col-md-6">
              <fg-input
                v-model="form.fname"
                type="text"
                placeholder="ชื่อ-สกุล"
              >
                <span slot="label"> ชื่อ-สกุล </span>
              </fg-input>
            </div>
            <div class="col-12 col-md-6">
              <fg-input
                v-model="form.phone"
                type="text"
                placeholder="เบอร์โทร"
                readonly
              >
                <span slot="label"> เบอร์โทร </span>
              </fg-input>
            </div>
          </div>
          <div class="row">
            <div class="col-12 col-md-6">
              <fg-input
                v-model="form.user_betflix"
                type="text"
                placeholder="User betflix"
                readonly
              >
                <span slot="label"> User betflix </span>
              </fg-input>
            </div>
            <div class="col-12 col-md-6">
              <fg-input
                v-model="form.pass_betflix"
                type="text"
                placeholder="Pass betflix"
                readonly
              >
                <span slot="label"> Pass betflix </span>
              </fg-input>
            </div>
          </div>
          <div class="row">
            <div class="col-12 col-md-6">
              <div>
                <div class="title">เลือกธนาคาร</div>
                <el-select
                  class="select-default"
                  size="large"
                  placeholder="เลือกธนาคาร"
                  v-model="form.bank_name"
                >
                  <el-option
                    v-for="option in selects.bank_name"
                    class="select-default"
                    :value="option.value"
                    :label="option.label"
                    :key="option.label"
                  >
                  </el-option>
                </el-select>
              </div>
            </div>

            <div class="col-12 col-md-6">
              <fg-input
                v-model="form.bank_number"
                type="text"
                placeholder="เลขบัญชี"
              >
                <span slot="label"> เลขบัญชี </span>
              </fg-input>
            </div>
            <div class="col-12 col-md-6">
              <fg-input v-model="form.line" type="text" placeholder="ไลน์">
                <span slot="label"> ไลน์ </span>
              </fg-input>
            </div>
          </div>
        </form>
      </div>
      <span slot="footer" class="dialog-footer" v-loading="loadingSubmit">
        <button class="btn btn-success mr-2" @click="saveMember()">
          บันทึก
        </button>
        <button class="btn btn-danger" @click="closeModal('editMember')">
          ยกเลิก
        </button>
      </span>
    </el-dialog>
    <el-dialog :visible.sync="modals.changePassword" width="30%">
      <span slot="title">
        <i class="fa fa-key" aria-hidden="true"></i>
        เปลี่ยนรหัสผ่าน
      </span>
      <div>
        <div>
          <fg-input
            v-model="password"
            type="text"
            placeholder="กรอกรหัสผ่านใหม่"
          >
            <span slot="label"> รหัสผ่านใหม่ </span>
          </fg-input>
        </div>
      </div>
      <span slot="footer" class="dialog-footer" v-loading="loadingSubmit">
        <button class="btn btn-success mr-2" @click="saveChangePassword()">
          บันทึก
        </button>
        <button class="btn btn-danger" @click="closeModal('changePassword')">
          ยกเลิก
        </button>
      </span>
    </el-dialog>
    <el-dialog
      :visible.sync="modals.historyMember"
      width="90%"
      :lock-scroll="false"
    >
      <span slot="title">
        <i class="fa fa-mobile" aria-hidden="true"></i>
        เบอร์ {{ historyMemberPhone }}
      </span>
      <div>
        <div class="row">
          <div class="col-12 col-sm-6 col-md-4 col-lg-3">
            <stats-card
              :title="
                Number(deposit).toLocaleString(undefined, {
                  minimumFractionDigits: 2,
                })
              "
              subTitle="ยอดฝากทั้งหมด"
              class="mb-2"
            >
              <div slot="header" class="icon-success">
                <i class="nc-icon nc-money-coins text-success"></i>
              </div>
            </stats-card>
          </div>

          <div class="col-12 col-sm-6 col-md-4 col-lg-3">
            <stats-card
              :title="
                Number(withdraw).toLocaleString(undefined, {
                  minimumFractionDigits: 2,
                })
              "
              subTitle="ยอดถอนทั้งหมด"
              class="mb-2"
            >
              <div slot="header" class="icon-danger">
                <i class="nc-icon nc-money-coins text-danger"></i>
              </div>
            </stats-card>
          </div>

          <div class="col-12 col-sm-6 col-md-4 col-lg-3">
            <stats-card
              :title="
                Number(income).toLocaleString(undefined, {
                  minimumFractionDigits: 2,
                })
              "
              subTitle="กำไร/ขาดทุน"
              class="mb-2"
            >
              <div slot="header" class="icon-primary">
                <i class="nc-icon nc-money-coins text-primary"></i>
              </div>
            </stats-card>
          </div>

          <div class="col-12 col-sm-6 col-md-4 col-lg-3">
            <stats-card
              :title="
                Number(bonus).toLocaleString(undefined, {
                  minimumFractionDigits: 2,
                })
              "
              subTitle="ฝากโบนัส"
              class="mb-2"
            >
              <div slot="header" class="icon-info">
                <i class="nc-icon nc-money-coins text-info"></i>
              </div>
            </stats-card>
          </div>
        </div>
        <card>
          <div class="row">
            <div class="col-sm-2 d-inline-block">
              <el-select
                class="select-default mb-3 w-100"
                v-model="paginationHistoryMember.perPage"
                placeholder="Per page"
                @change="getDashboardAllByPhone()"
              >
                <el-option
                  class="select-default"
                  v-for="item in paginationHistoryMember.perPageOptions"
                  :key="item"
                  :label="item"
                  :value="item"
                >
                </el-option>
              </el-select>
            </div>

            <div class="col-sm-3 ml-auto text-right">
              <button
                @click="getDashboardAllByPhone()"
                type="button"
                class="btn btn-danger"
              >
                <i class="fa fa-refresh" aria-hidden="true"></i>
              </button>
            </div>
          </div>
          <div class="row">
            <div class="col-sm-12">
              <el-table
                stripe
                style="width: 100%; text-align: center"
                :data="tableDataHistoryMember"
                border
                v-loading="loadingMember"
              >
                <el-table-column :min-width="120" label="เวลาทำรายการ">
                  <template v-slot="props">
                    <span v-html="props ? props.row.D13 : ''"> </span>
                  </template>
                </el-table-column>
                <el-table-column :min-width="100" label="วัน/เวลา">
                  <template v-slot="props">
                    <span v-html="props ? props.row.D1 : ''"></span>
                  </template>
                </el-table-column>
                <el-table-column :min-width="100" label="ประเภท">
                  <template v-slot="props">
                    <span v-html="props ? props.row.D2 : ''"></span>
                  </template>
                </el-table-column>
                <el-table-column :min-width="120" label="Username">
                  <template v-slot="props">
                    <span v-html="props ? props.row.D3 : ''"></span>
                  </template>
                </el-table-column>
                <!-- <el-table-column :min-width="100" label="Userbetflix">
                  <template v-slot="props">
                    <span v-html="props ? props.row.D4 : ''"></span>
                  </template>
                </el-table-column> -->
                <el-table-column :min-width="80" label="ธนาคาร">
                  <template v-slot="props">
                    <span v-html="props ? props.row.D5 : ''"></span>
                  </template>
                </el-table-column>
                <el-table-column :min-width="80" label="จำนวน">
                  <template v-slot="props">
                    <span
                      v-html="
                        props
                          ? Number(props.row.D6).toLocaleString(undefined, {
                              minimumFractionDigits: 2,
                            })
                          : ''
                      "
                    ></span>
                  </template>
                </el-table-column>
                <el-table-column :min-width="80" label="โบนัส">
                  <template v-slot="props">
                    <span
                      v-html="
                        props
                          ? Number(props.row.D7).toLocaleString(undefined, {
                              minimumFractionDigits: 2,
                            })
                          : ''
                      "
                    ></span>
                  </template>
                </el-table-column>
                <el-table-column :min-width="100" label="จำนวนก่อน">
                  <template v-slot="props">
                    <span
                      v-html="
                        props
                          ? Number(props.row.D8).toLocaleString(undefined, {
                              minimumFractionDigits: 2,
                            })
                          : ''
                      "
                    ></span>
                  </template>
                </el-table-column>
                <el-table-column :min-width="100" label="จำนวนหลัง">
                  <template v-slot="props">
                    <span
                      v-html="
                        props
                          ? Number(props.row.D9).toLocaleString(undefined, {
                              minimumFractionDigits: 2,
                            })
                          : ''
                      "
                    ></span>
                  </template>
                </el-table-column>
                <el-table-column :min-width="150" label="รายละเอียด">
                  <template v-slot="props">
                    <span v-if="props.row.Qr">
                      <button
                        class="btn btn-info btn-sm"
                        @click="qrshow(props ? props.row.D12 : '')"
                      >
                        <i class="fa fa-qrcode" aria-hidden="true"></i>
                      </button>
                    </span>
                    <span
                      v-else-if="
                        props.row.type === '0' && props.row.way === 'AUTO'
                      "
                      class="badge badge-warning"
                    >
                      {{ props.row.D12 }}
                    </span>
                    <span
                      v-else-if="props.row.D12 === '9'"
                      class="badge badge-danger"
                    >
                      แอดมินถอนมือ
                    </span>
                    <span v-else>
                      {{ props.row.D12 }}
                    </span>
                  </template>
                </el-table-column>
                <el-table-column :min-width="180" label="ผู้ทำรายการ">
                  <template v-slot="props">
                    <span v-html="props ? props.row.D11 : ''"></span>
                  </template>
                </el-table-column>
              </el-table>
            </div>
          </div>
          <div
            slot="footer"
            class="col-12 d-flex justify-content-center justify-content-sm-between flex-wrap"
          >
            <div class="">
              <p class="card-category">
                Showing {{ fromMember + 1 }} to {{ toMember }} of
                {{ totalMember }} entries
              </p>
            </div>
            <l-pagination
              class="pagination-no-border"
              v-model="paginationHistoryMember.currentPage"
              :per-page="paginationHistoryMember.perPage"
              :total="paginationHistoryMember.total"
            >
            </l-pagination>
          </div>
        </card>
      </div>
      <span slot="footer" class="dialog-footer">
        <button class="btn btn-danger" @click="closeModal('historyMember')">
          ปิด
        </button>
      </span>
    </el-dialog>
    <el-dialog :visible.sync="modals.modalQrcode" width="20%">
      <span slot="title">
        <i class="fa fa-qrcode" aria-hidden="true"></i>
        Qrcode
      </span>
      <div>
        <qrcode :text="qrcodevalue" class="qrcode m-auto"></qrcode>
      </div>
      <span slot="footer" class="dialog-footer">
        <button class="btn btn-danger" @click="closeModal('modalQrcode')">
          ปิด
        </button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
import {
  DatePicker,
  Table,
  TableColumn,
  Select,
  Option,
  Dialog,
  Button,
} from "element-ui";
import { StatsCard, Pagination as LPagination } from "src/components/index";
import axios from "axios";
import qrcode from "vue-qrcode-component";
import Swal from "sweetalert2";
import moment from "moment";
export default {
  name: "member",
  components: {
    [DatePicker.name]: DatePicker,
    StatsCard,
    LPagination,
    [Select.name]: Select,
    [Option.name]: Option,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    [Dialog.name]: Dialog,
    [Button.name]: Button,
    qrcode,
  },
  data() {
    return {
      loading: true,
      loadingMember: false,
      loadingSubmit: false,
      startDate: moment(new Date()).format("YYYY-MM-DD"),
      endDate: moment(new Date()).format("YYYY-MM-DD"),
      bonus: 0,
      deposit: 0,
      withdraw: 0,
      income: 0,
      form: {
        id: "",
        bank_number: "",
        bank_name: "",
        fname: "",
        user_betflix: "",
        pass_betflix: "",
        phone: "",
        line: "",
      },
      historyMemberPhone: "",
      phone: "",
      password: "",
      total_member: 0,
      total_member_month: 0,
      total_member_today: 0,
      status_pending: 0,
      modals: {
        historyMember: false,
        addMember: false,
        changePassword: false,
        modalQrcode: false,
        editMember: false,
        centered: false,
        custom: false,
        confirm: false,
      },
      selects: {
        bank_name: [
          { value: "ไทยพาณิชย์", label: "ไทยพาณิชย์" },
          { value: "กสิกรไทย", label: "กสิกรไทย" },
          { value: "กรุงเทพ", label: "กรุงเทพ" },
          { value: "กรุงไทย", label: "กรุงไทย" },
          { value: "ทหารไทยธนชาต", label: "ทหารไทยธนชาต" },
          { value: "กรุงศรีอยุธยา", label: "กรุงศรีอยุธยา" },
          { value: "เกียรตินาคิน", label: "เกียรตินาคิน" },
          { value: "ออมสิน", label: "ออมสิน" },
          { value: "ซีไอเอ็มบีไทย", label: "ซีไอเอ็มบีไทย" },
          { value: "ยูโอบี", label: "ยูโอบี" },
          { value: "แลนด์ แอนด์ เฮาส์", label: "แลนด์ แอนด์ เฮาส์" },
          { value: "ธกส", label: "ธกส" },
          { value: "อาคารสงเคราะห์", label: "อาคารสงเคราะห์" },
          { value: "อิสลามแห่งประเทศไทย", label: "อิสลามแห่งประเทศไทย" },
        ],
      },
      pagination: {
        perPage: 10,
        currentPage: 1,
        perPageOptions: [10, 50, 100, 200],
        total: 0,
      },
      paginationHistoryMember: {
        perPage: 10,
        currentPage: 1,
        perPageOptions: [10, 50, 100, 200],
        total: 0,
      },
      searchQuery: "",
      searchQueryHistoryMember: "",
      tableData: [],
      tableDataHistoryMember: [],
      qrcodevalue: "",
      timeData: "",
      timeDataMember: "",
    };
  },
  computed: {
    to() {
      let highBound = this.from + this.pagination.perPage;
      if (this.total < highBound) {
        highBound = this.total;
      }
      return highBound;
    },
    from() {
      if (this.modals["historyMember"] === true) return;
      this.getMember();
      return this.pagination.perPage * (this.pagination.currentPage - 1);
    },
    total() {
      return this.pagination.total;
    },

    toMember() {
      let highBound = this.fromMember + this.paginationHistoryMember.perPage;
      if (this.totalMember < highBound) {
        highBound = this.totalMember;
      }
      return highBound;
    },
    fromMember() {
      if (this.modals["historyMember"] === false) return;
      this.getDashboardAllByPhone();
      return (
        this.paginationHistoryMember.perPage *
        (this.paginationHistoryMember.currentPage - 1)
      );
    },
    totalMember() {
      return this.paginationHistoryMember.total;
    },
  },
  methods: {
    async countMember() {
      let config = {
        method: "get",
        maxBodyLength: Infinity,
        url: `${this.$hostname}/countmember`,
        headers: {
          authorization: this.$cookies.get("token"),
        },
      };

      axios
        .request(config)
        .then((res) => {
          let data = res.data.res_result[0];
          this.total_member = data.total_member;
          this.total_member_month = data.total_member_month;
          this.total_member_today = data.total_member_today;
          this.status_pending = data.status_pending;
        })
        .catch((error) => {
          // console.log(error);
        });
    },
    async getMember() {
      if (this.modals["historyMember"] == true) return;

      let offset = this.pagination.perPage * (this.pagination.currentPage - 1);

      let config = {
        method: "get",
        maxBodyLength: Infinity,
        url: `${this.$hostname}/getmember?search=${this.searchQuery}&sort=&order=desc&offset=${offset}&limit=${this.pagination.perPage}&action=&start_date=${this.startDate}&end_date=${this.endDate}`,
        headers: {
          authorization: this.$cookies.get("token"),
          "Content-Type": "application/json",
        },
      };
      this.loading = true;
      clearTimeout(this.timeData);
      this.timeData = setTimeout(async () => {
        await axios
          .request(config)
          .then((res) => {
            let data = res.data.res_result;
            this.pagination.total = data.total;
            this.tableData = data.rows;
            this.loading = false;
          })
          .catch((error) => {
            this.loading = false;
          });
      }, 500);
    },
    async handleLogin(phone) {
      this.loadingSubmit = true;
      let data = JSON.stringify({
        user: phone,
      });

      let config = {
        method: "post",
        maxBodyLength: Infinity,
        url: `${this.$hostname}/playgame`,
        headers: {
          authorization: this.$cookies.get("token"),
          "Content-Type": "application/json",
        },
        data: data,
      };

      await axios
        .request(config)
        .then((res) => {
          this.loadingSubmit = false;
          let data = res.data;
          if (data.res_code === "00") {
            window.open(data.res_result);
          }
        })
        .catch((error) => {
          this.loadingSubmit = false;
        });
    },
    async editMember(phone) {
      let data = JSON.stringify({
        phone: phone,
      });

      let config = {
        method: "post",
        maxBodyLength: Infinity,
        url: `${this.$hostname}/getmemberbyphone`,
        headers: {
          authorization: this.$cookies.get("token"),
          "Content-Type": "application/json",
        },
        data: data,
      };

      axios
        .request(config)
        .then((res) => {
          const data = res.data.res_result;
          this.form.id = data.id;
          this.form.phone = data.phone;
          this.form.bank_number = data.bank_number;
          this.form.bank_name = data.bank_name;
          this.form.fname = data.fname;
          this.form.user_betflix = data.user_betflix;
          this.form.pass_betflix = data.pass_betflix;
          this.form.line = data.line;
          this.modals["editMember"] = true;
        })
        .catch((error) => {
          // console.log(error);
        });
    },
    async saveMember() {
      let data = {
        id: this.form.id,
        phone: this.form.phone,
        bank_number: this.form.bank_number,
        bank_name: this.form.bank_name,
        fname: this.form.fname,
        user_betflix: this.form.user_betflix,
        pass_betflix: this.form.pass_betflix,
        line: this.form.line,
      };

      if (
        !data.id ||
        !data.bank_number ||
        !data.bank_name ||
        !data.fname ||
        !data.phone
      ) {
        Swal.fire("แจ้งเตือน", "กรุณากรอกข้อมูลให้ครบ", "error");
        return;
      }

      this.loadingSubmit = true;

      let config = {
        method: "post",
        maxBodyLength: Infinity,
        url: `${this.$hostname}/update_member`,
        headers: {
          authorization: this.$cookies.get("token"),
          "Content-Type": "application/json",
        },
        data: data,
      };
      await axios
        .request(config)
        .then((res) => {
          this.loadingSubmit = false;
          this.tableData = [];
          const data = res.data;
          if (data.res_code === "00") {
            this.getMember();

            Swal.fire({
              title: "แจ้งเตือน",
              text: data.res_text,
              icon: "success",
              timer: 1500,
              showConfirmButton: false,
            }).then(() => {
              this.form.id = "";
              this.form.phone = "";
              this.form.bank_number = "";
              this.form.bank_name = "";
              this.form.fname = "";
              this.form.user_betflix = "";
              this.form.pass_betflix = "";
              this.form.line = "";
              this.modals["editMember"] = false;
            });
          } else {
            Swal.fire("แจ้งเตือน", data.res_text, "error");
          }
        })
        .catch((error) => {
          this.loadingSubmit = false;
        });
    },

    changePassword(phone, id) {
      this.phone = phone;
      this.form.id = id;

      this.modals["changePassword"] = true;
    },

    async saveChangePassword() {
      let data = JSON.stringify({
        id: this.form.id,
        phone: this.phone,
        password: this.password,
      });

      if (!this.phone || !this.password) {
        return Swal.fire("แจ้งเตือน", "กรุณากรอกข้อมูลให้ครบ", "error");
      }

      this.loadingSubmit = true;

      let config = {
        method: "post",
        maxBodyLength: Infinity,
        url: `${this.$hostname}/changepass_member`,
        headers: {
          "Content-Type": "application/json",
          authorization: this.$cookies.get("token"),
        },
        data: data,
      };

      await axios
        .request(config)
        .then((res) => {
          this.loadingSubmit = false;
          const data = res.data;
          if (data.res_code === "00") {
            this.getMember();

            Swal.fire({
              title: "แจ้งเตือน",
              text: data.res_text,
              icon: "success",
              timer: 1500,
              showConfirmButton: false,
            }).then(() => {
              this.phone = "";
              this.password = "";
              this.modals["changePassword"] = false;
            });
          } else {
            Swal.fire("แจ้งเตือน", data.res_text, "error");
          }
        })
        .catch((error) => {
          this.loadingSubmit = false;
        });
    },
    async historyMember(phone) {
      this.modals["historyMember"] = true;
      this.historyMemberPhone = phone;
      this.loading = true;

      let data = JSON.stringify({
        phone: phone,
      });

      let config = {
        method: "post",
        maxBodyLength: Infinity,
        url: `${this.$hostname}/historymember`,
        headers: {
          authorization: this.$cookies.get("token"),
          "Content-Type": "application/json",
        },
        data: data,
      };

      await axios
        .request(config)
        .then((res) => {
          let data = res.data;
          this.loading = false;

          if (data.res_code === "00") {
            data = data.res_result[0];

            // if (!data.deposit && !data.withdraw && !data.bonus) {
            //   return Swal.fire("แจ้งเตือน", "ไม่พบรายการฝาก", "error");
            // }

            this.bonus = data.bonus ? data.bonus : 0;
            this.deposit = data.deposit;
            this.withdraw = data.withdraw;
            this.income = data.deposit - data.withdraw - data.bonus;
            // this.getDashboardAllByPhone();
          }
        })
        .catch((error) => {
          this.loading = false;
        });
    },
    async getDashboardAllByPhone() {
      this.tableDataHistoryMember = [];

      let offset =
        this.paginationHistoryMember.perPage *
        (this.paginationHistoryMember.currentPage - 1);
      let config = {
        method: "get",
        maxBodyLength: Infinity,
        url: `${this.$hostname}/transactions?search=${this.historyMemberPhone}&sort&order=desc&offset=${offset}&limit=${this.paginationHistoryMember.perPage}&action&start_date=&end_date=`,
        headers: {
          authorization: this.$cookies.get("token"),
          "Content-Type": "application/json",
        },
      };

      clearTimeout(this.timeDataMember);
      this.timeDataMember = setTimeout(async () => {
        await axios
          .request(config)
          .then((res) => {
            let data = res.data;
            if (data.res_code === "00") {
              this.tableDataHistoryMember = data.res_result.rows;
              this.paginationHistoryMember.total = data.res_result.total;
            }
            this.loadingMember = false;
          })
          .catch((error) => {
            this.loadingMember = false;
          });
      }, 500);
    },
    deleteMember(phone, id) {
      Swal.fire({
        title: "แจ้งเตือน",
        text: "ยืนยันการลบสมาชิก: " + phone,
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "ยืนยัน",
        cancelButtonText: "ยกเลิก",
        confirmButtonClass: "btn btn-danger btn-fill",
        cancelButtonClass: "btn btn-default btn-fill",
        buttonsStyling: false,
      }).then(async (result) => {
        if (result.isConfirmed) {
          this.loadingSubmit = true;
          let data = JSON.stringify({
            phone: phone,
            id: id,
          });

          let config = {
            method: "post",
            maxBodyLength: Infinity,
            url: `${this.$hostname}/delete_member`,
            headers: {
              authorization: this.$cookies.get("token"),
              "Content-Type": "application/json",
            },
            data: data,
          };

          await axios
            .request(config)
            .then((res) => {
              this.loadingSubmit = false;
              let data = res.data;
              if (data.res_code == "00") {
                this.getMember();
                Swal.fire({
                  title: "แจ้งเตือน",
                  text: data.res_text,
                  icon: "success",
                  timer: 1500,
                  showConfirmButton: false,
                });
              } else {
                Swal.fire("แจ้งเตือน", data.res_text, "error");
              }
            })
            .catch((error) => {
              this.loadingSubmit = false;
            });
        }
      });
    },
    confirmClose() {
      Swal.fire({
        title: "แจ้งเตือน",
        text: "ต้องการยกเลิกการทำรายการ?",
        icon: "question",
        showCancelButton: true,
        confirmButtonClass: "btn btn-danger",
        cancelButtonClass: "btn btn-warning",
        confirmButtonText: "ยืนยัน",
        cancelButtonText: "ดำเนินการต่อ",
        buttonsStyling: false,
      }).then((result) => {
        if (result.isConfirmed) {
          this.modals["editMember"] = false;
          this.modals["changePassword"] = false;
        }
      });
    },

    qrshow(value) {
      this.qrcodevalue = value;
      this.modals["modalQrcode"] = true;
    },
    qrhide() {
      this.modals["modalQrcode"] = false;
    },
    openModal(name) {
      this.modals[name] = true;
    },
    closeModal(name) {
      this.modals[name] = false;
    },
  },
  mounted() {
    this.countMember();
  },
};
</script>
