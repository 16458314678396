<template>
  <div v-if="!this.$func.checkPermission('รายการฝาก')" class="row">
    <div class="col">
      <div class="card">
        <div class="card-body">
          <div class="alert alert-danger" role="alert">
            <strong>คุณไม่มีสิทธิ์เข้าถึงหน้านี้</strong>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div v-else>
    <div class="row">
      <div class="col-12 col-sm-6 col-md-4 col-lg-3">
        <div class="d-flex mb-2">
          <span class="my-auto mr-2" style="width: 30px">เริ่ม</span>
          <fg-input class="m-0 w-100">
            <el-date-picker
              v-model="startDateCount"
              type="date"
              placeholder="วันที่เริ่ม"
              format="dd/MM/yyyy"
              value-format="yyyy-MM-dd"
              @change="countDeposit()"
            >
            </el-date-picker>
          </fg-input>
        </div>
      </div>
      <div class="col-12 col-sm-6 col-md-4 col-lg-3">
        <div class="d-flex mb-2">
          <span class="my-auto mr-2" style="width: 30px">ถึง</span>
          <fg-input class="m-0 w-100">
            <el-date-picker
              v-model="endDateCount"
              type="date"
              placeholder="ถึง"
              format="dd/MM/yyyy"
              value-format="yyyy-MM-dd"
              @change="countDeposit()"
            >
            </el-date-picker>
          </fg-input>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-12 col-sm-6 col-md-4 col-lg-3">
        <stats-card
          :title="
            Number(deposit).toLocaleString(undefined, {
              minimumFractionDigits: 2,
            })
          "
          subTitle="ยอดฝากทั้งหมด"
          class="mb-2"
        >
          <div slot="header" class="icon-success">
            <i class="nc-icon nc-money-coins text-success"></i>
          </div>
        </stats-card>
      </div>

      <div class="col-12 col-sm-6 col-md-4 col-lg-3">
        <stats-card
          :title="
            Number(deposit_auto).toLocaleString(undefined, {
              minimumFractionDigits: 2,
            })
          "
          subTitle="ฝากอัตโนมัติ"
          class="mb-2"
        >
          <div slot="header" class="icon-danger">
            <i class="nc-icon nc-money-coins text-danger"></i>
          </div>
        </stats-card>
      </div>

      <div class="col-12 col-sm-6 col-md-4 col-lg-3">
        <stats-card
          :title="
            Number(deposit_admin).toLocaleString(undefined, {
              minimumFractionDigits: 2,
            })
          "
          subTitle="ฝากแอดมิน"
          class="mb-2"
        >
          <div slot="header" class="icon-info">
            <i class="nc-icon nc-money-coins text-info"></i>
          </div>
        </stats-card>
      </div>
      <div class="col-12 col-sm-6 col-md-4 col-lg-3">
        <stats-card
          :title="
            Number(deposit_bonus).toLocaleString(undefined, {
              minimumFractionDigits: 2,
            })
          "
          subTitle="ฝากโบนัส"
          class="mb-2"
        >
          <div slot="header" class="icon-warning">
            <i class="nc-icon nc-money-coins text-warning"></i>
          </div>
        </stats-card>
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <card>
          <div slot="header">
            <div class="row">
              <div class="col-12 col-sm-3">
                <h4 class="my-auto mr-2">ประวัติการฝากเงิน</h4>
              </div>
              <div class="col-12 col-sm d-lg-flex">
                <el-button
                  type="primary"
                  size="default"
                  @click="addCredit()"
                  class="mr-2"
                >
                  <i class="fa fa-plus" aria-hidden="true"></i>
                  เพิ่มรายการ
                </el-button>
                <!-- <TableCheckQrcode></TableCheckQrcode> -->
              </div>
            </div>
          </div>
          <TableHistoryDeposit></TableHistoryDeposit>
        </card>
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <card>
          <div slot="header">
            <h4 class="m-0 mr-2">ประวัติการฝากเงิน ADMIN</h4>
          </div>
          <div>
            <TableHistoryDepositAdmin></TableHistoryDepositAdmin>
          </div>
        </card>
      </div>
    </div>

    <el-dialog
      :visible.sync="modals.modalAddCredit"
      :lock-scroll="false"
      width="50%"
    >
      <span slot="title">
        <i class="fa fa-plus" aria-hidden="true"></i>
        เพิ่มเครดิต โดย ADMIN!
      </span>
      <div>
        <form>
          <div class="row">
            <div class="col-12 col-sm-6">
              <div>
                <fg-input
                  v-model="formCredit.phone"
                  type="text"
                  placeholder="กรอกเบอร์โทรศัพท์"
                  @change="getMemberByPhone()"
                  :minlength="10"
                  :maxlength="10"
                  class="w-100"
                >
                  <span slot="label"> เบอร์โทร </span>
                </fg-input>
              </div>
              <div>
                <fg-input
                  v-model="formCredit.fname"
                  type="text"
                  placeholder="กรอกชื่อ-สกุล"
                  disabled
                >
                  <span slot="label"> ชื่อ-สกุล </span>
                </fg-input>
              </div>
              <div>
                <fg-input
                  v-model="formCredit.bank_number"
                  type="text"
                  placeholder="กรอกเลขบัญชี"
                  disabled
                >
                  <span slot="label"> เลขบัญชี </span>
                </fg-input>
              </div>
              <div class="mb-3">
                <div class="title">ช่องทาง</div>
                <el-select
                  class="select-default select-default-info w-100"
                  size="large"
                  v-model="formCredit.info"
                  placeholder="เลือกธนาคาร"
                  disabled
                >
                  <el-option
                    v-for="option in selects.bank_name"
                    class="select-default"
                    :value="option.value"
                    :label="option.label"
                    :key="option.label"
                  >
                  </el-option>
                </el-select>
              </div>
              <div>
                <fg-input
                  v-model="formCredit.deposit"
                  type="number"
                  placeholder="กรอกเลขบัญชี"
                  :min="1"
                  @click="formCredit.deposit = ''"
                >
                  <div slot="label">
                    ยอดเงิน <span class="text-danger">*ไม่ต้องใส่ทศนิยม</span>
                  </div>
                </fg-input>
              </div>
            </div>
            <div class="col-12 col-sm-6">
              <div class="mb-3">
                <fg-input class="m-0 w-100">
                  <el-date-picker
                    v-model="formCredit.date"
                    type="date"
                    placeholder="เลือกวัน"
                    format="dd/MM/yyyy"
                    value-format="yyyy-MM-dd"
                    :disabled="disabledDate"
                  >
                  </el-date-picker>
                  <span slot="label"> วัน </span>
                </fg-input>
              </div>
              <div>
                <div class="mb-3">
                  <fg-input>
                    <el-time-picker
                      v-model="formCredit.time"
                      placeholder="เวลา"
                      format="HH:mm"
                      :disabled="disabledTime"
                    />
                    <span slot="label"> เวลา </span>
                  </fg-input>
                </div>
              </div>
              <div class="mb-3">
                <div class="title">ประเภท</div>
                <el-select
                  class="select-default w-100"
                  size="large"
                  v-model="formCredit.type"
                  placeholder="เลือกหมวดหมู่"
                  @change="changeType()"
                >
                  <el-option
                    v-for="option in selects.type"
                    class="select-default"
                    :value="option.value"
                    :label="option.label"
                    :key="option.label"
                  >
                  </el-option>
                </el-select>
              </div>
              <fg-input label="Textarea">
                <textarea
                  v-model="formCredit.detail"
                  class="form-control"
                  placeholder="กรอกหมายเหตุ"
                  rows="4"
                ></textarea>
                <span slot="label"> หมายเหตุ </span>
              </fg-input>
            </div>
          </div>
        </form>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button
          :loading="loading"
          type="success"
          size="default"
          @click="submitAddCredit()"
        >
          เพิ่ม
        </el-button>

        <el-button
          type="danger"
          size="default"
          @click="closeModal('modalAddCredit')"
        >
          ยกเลิก
        </el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
import {
  DatePicker,
  Table,
  TableColumn,
  Select,
  Option,
  Button,
  Dialog,
  TimeSelect,
  TimePicker,
} from "element-ui";
import { StatsCard, FormGroupInput as FgInput } from "src/components/index";
import moment from "moment";
import axios from "axios";
import Swal from "sweetalert2";
import TableCheckQrcode from "../components/TableCheckQrcode.vue";
import TableHistoryDepositAdmin from "../components/TableHistoryDepositAdmin.vue";
import TableHistoryDeposit from "../components/TableHistoryDeposit.vue";

export default {
  components: {
    [TimePicker.name]: TimePicker,
    [Button.name]: Button,
    FgInput,
    [DatePicker.name]: DatePicker,
    StatsCard,
    [TimeSelect.name]: TimeSelect,
    [Select.name]: Select,
    [Option.name]: Option,
    [Table.name]: Table,
    [Dialog.name]: Dialog,
    [TableColumn.name]: TableColumn,
    // TableCheckQrcode,
    TableHistoryDepositAdmin,
    TableHistoryDeposit,
  },
  data() {
    return {
      loading: false,

      startDateCount: moment(new Date()).format("YYYY-MM-DD"),
      endDateCount: moment(new Date()).format("YYYY-MM-DD"),

      deposit: 0,
      deposit_bonus: 0,
      deposit_admin: 0,
      deposit_auto: 0,

      formCredit: {
        fname: "",
        bank_number: "",
        deposit: 0,
        time: "",
        date: "",
        info: "",
        type: "",
        phone: "",
        detail: "",
      },

      modals: {
        modalHistoryWithdraw: false,
        modalAddCredit: false,
        modalCheckQrcode: false,
        centered: false,
        custom: false,
        confirm: false,
      },
      selects: {
        type: [
          { value: "", label: "เลือกประเภท" },
          { value: "1", label: "เติมเงินปกติ" },
          { value: "2", label: "เติมเงินย้อนหลัง" },
          { value: "3", label: "โบนัส" },
        ],
        bank_name: [
          { value: "ไทยพาณิชย์", label: "ไทยพาณิชย์" },
          { value: "กรุงเทพ", label: "กรุงเทพ" },
          { value: "กรุงไทย", label: "กรุงไทย" },
          { value: "ทหารไทยธนชาต", label: "ทหารไทยธนชาต" },
          { value: "กรุงศรีอยุธยา", label: "กรุงศรีอยุธยา" },
          { value: "เกียรตินาคิน", label: "เกียรตินาคิน" },
          { value: "ออมสิน", label: "ออมสิน" },
          { value: "ซีไอเอ็มบีไทย", label: "ซีไอเอ็มบีไทย" },
          { value: "ยูโอบี", label: "ยูโอบี" },
          { value: "แลนด์ แอนด์ เฮาส์", label: "แลนด์ แอนด์ เฮาส์" },
          { value: "ธกส", label: "ธกส" },
          { value: "อาคารสงเคราะห์", label: "อาคารสงเคราะห์" },
          { value: "อิสลามแห่งประเทศไทย", label: "อิสลามแห่งประเทศไทย" },
        ],
      },

      disabledType: false,
      disabledDate: false,
      disabledTime: false,

      typePayment: "",
    };
  },
  computed: {},
  methods: {
    checkQrcode(type) {
      this.typePayment = type;
      this.modals["modalCheckQrcode"] = true;
    },

    changeType() {
      if (this.formCredit.type == 1) {
        this.disabledDate = true;
        this.disabledTime = false;
        this.formCredit.date = new Date();
      } else {
        this.disabledDate = false;
        this.disabledTime = false;
      }

      if (this.formCredit.type == 4) {
        this.disabledDate = true;
        this.disabledTime = true;
        this.formCredit.date = new Date();
        this.formCredit.time = new Date();
      }
    },
    addCredit() {
      this.formCredit.fname = "";
      this.formCredit.bank_number = "";
      this.formCredit.deposit = 0;
      this.formCredit.time = "";
      this.formCredit.date = moment(new Date()).format("YYYY-MM-DD");
      this.formCredit.info = "";
      this.formCredit.type = "";
      this.formCredit.phone = "";
      this.formCredit.detail = "";
      this.disabledDate = false;
      this.disabledTime = false;

      this.modals["modalAddCredit"] = true;
    },

    async countDeposit() {
      let data = JSON.stringify({
        start: this.startDateCount,
        end: this.endDateCount,
      });

      let config = {
        method: "post",
        maxBodyLength: Infinity,
        url: `${this.$hostname}/countdeposit`,
        headers: {
          authorization: this.$cookies.get("token"),
          "Content-Type": "application/json",
        },
        data: data,
      };

      await axios
        .request(config)
        .then((res) => {
          const data = res.data.res_result;
          this.deposit = data.deposit;
          this.deposit_admin = data.deposit_admin;
          this.deposit_auto = data.deposit_auto;
          this.deposit_bonus = data.deposit_bonus;
        })
        .catch((error) => {});
    },

    async submitAddCredit() {
      if (
        !this.formCredit.bank_number ||
        !this.formCredit.deposit ||
        !this.formCredit.time ||
        !this.formCredit.date ||
        !this.formCredit.info ||
        !this.formCredit.type ||
        !this.formCredit.phone ||
        !this.formCredit.detail
      ) {
        return Swal.fire("แจ้งเตือน", "กรุณากรอกข้อมูลให้ครบ", "error");
      }

      let data = JSON.stringify({
        bank_number: this.formCredit.bank_number,
        deposit: this.formCredit.deposit,
        time: moment(this.formCredit.time).format("HH:mm"),
        date: moment(this.formCredit.date).format("YYYY-MM-DD"),
        info: this.formCredit.info,
        type: this.formCredit.type,
        phone: this.formCredit.phone,
        detail: this.formCredit.detail,
      });

      let config = {
        method: "post",
        maxBodyLength: Infinity,
        url: `${this.$hostname}/addmin_add_credit`,
        headers: {
          authorization: this.$cookies.get("token"),
          "Content-Type": "application/json",
        },
        data: data,
      };

      this.loading = true;

      await axios
        .request(config)
        .then((res) => {
          this.loading = false;
          const data = res.data;
          if (data.res_code === "00") {
            Swal.fire({
              title: "แจ้งเตือน",
              text: data.res_text,
              icon: "success",
              timer: 1500,
              showConfirmButton: false,
            });
          } else {
            Swal.fire("แจ้งเตือน", data.res_text, "error");
          }

          this.formCredit.bank_number = "";
          this.formCredit.fname = "";
          this.formCredit.deposit = "";
          this.formCredit.time = "";
          this.formCredit.date = "";
          this.formCredit.info = "";
          this.formCredit.type = "";
          this.formCredit.phone = "";
          this.formCredit.detail = "";

          this.modals["modalAddCredit"] = false;
        })
        .catch((error) => {
          this.loading = false;
        });
    },

    async getMemberByPhone() {
      let data = JSON.stringify({
        phone: this.formCredit.phone,
      });

      let config = {
        method: "post",
        maxBodyLength: Infinity,
        url: `${this.$hostname}/getmemberbyphone`,
        headers: {
          authorization: this.$cookies.get("token"),
          "Content-Type": "application/json",
        },
        data: data,
      };

      axios
        .request(config)
        .then((res) => {
          let data = res.data.res_result;
          this.formCredit.fname = data.fname;
          this.formCredit.bank_number = data.bank_number;
          this.formCredit.info = data.bank_name;

          this.formUpdate.fname = data.fname;
        })
        .catch((error) => {});
    },
    async getMemberUpdateByPhone() {
      let data = JSON.stringify({
        phone: this.formUpdate.phone,
      });

      let config = {
        method: "post",
        maxBodyLength: Infinity,
        url: `${this.$hostname}/getmemberbyphone`,
        headers: {
          authorization: this.$cookies.get("token"),
          "Content-Type": "application/json",
        },
        data: data,
      };

      axios
        .request(config)
        .then((res) => {
          let data = res.data.res_result;
          this.formCredit.fname = data.fname;
          this.formCredit.bank_number = data.bank_number;
          this.formCredit.info = data.bank_name;

          this.formUpdate.fname = data.fname;
        })
        .catch((error) => {});
    },

    confirmClose() {
      Swal.fire({
        title: "แจ้งเตือน",
        text: "ต้องการยกเลิกการทำรายการ?",
        icon: "question",
        showCancelButton: true,
        confirmButtonClass: "btn btn-danger",
        cancelButtonClass: "btn btn-warning",
        confirmButtonText: "ยืนยัน",
        cancelButtonText: "ดำเนินการต่อ",
        buttonsStyling: false,
      }).then((result) => {
        if (result.isConfirmed) {
          this.modals["modalAddCredit"] = false;
        }
      });
    },

    openModal(name) {
      this.modals[name] = true;
    },
    closeModal(name) {
      this.modals[name] = false;
    },
  },
  mounted() {
    this.countDeposit();
  },
};
</script>
<style scoped>
.select-default-info {
  background-color: #f5f5f5;
}
</style>
