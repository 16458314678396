<template>
  <div>
    <el-button type="primary" size="default" @click="getBank()"
      >โยกเงิน</el-button
    >
    <el-dialog :visible.sync="modals.modalTransfer" width="35%">
      <span slot="title"> โยกเงิน </span>
      <div>
        <div class="mb-3">
          <span slot="label">บัญชีผู้รับ:</span>
          <el-select placeholder="บัญชีผู้รับ" v-model="form.phone">
            <el-option
              v-for="option in options.phone"
              :value="option.value"
              :label="option.label"
              :key="option.label"
            >
            </el-option>
          </el-select>
        </div>
        <div class="mb-3">
          <span slot="label">Payment:</span>
          <el-select
            v-model="form.payment"
            value-key=""
            placeholder="เลือกการชำระเงิน"
            clearable
            filterable
          >
            <el-option
              v-for="item in options.payment"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </div>
        <div class="mb-3">
          <span slot="label">ยอดเงิน:</span>
          <el-input
            v-model="form.deposit"
            placeholder="กรอกจำนวนเงิน"
            min="0"
            type="text"
            oninput="this.value = this.value.replace(/[^0-9]/g, '');"
          ></el-input>
          <small class="text-danger">*ไม่ต้องใส่ทศนิยม</small>
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button
          v-if="!bankAccount"
          type="success"
          size="default"
          @click="submitTransfer()"
          :loading="loading"
          >ยืนยัน</el-button
        >
        <el-button
          type="danger"
          size="default"
          @click="closeModal('modalTransfer')"
          >ปิด</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>
<script>
import { Button, Dialog, Option, Select } from "element-ui";
import Swal from "sweetalert2";
// import qrcode from "vue-qrcode-component";
import axios from "axios";
export default {
  components: {
    [Button.name]: Button,
    [Dialog.name]: Dialog,
    [Select.name]: Select,
    [Option.name]: Option,
  },
  data() {
    return {
      form: {
        phone: "",
        payment: "",
        deposit: "",
      },
      QRPromptpay: "",
      modals: {
        modalTransfer: false,
        centered: false,
        custom: false,
        confirm: false,
      },
      options: {
        phone: [],
        payment: [],
      },
      loading: false,

      bank: "",
      bankAccount: "",
      bankAccountName: "",
      depositAmount: "",
    };
  },
  methods: {
    resetFormFields() {
      this.form.phone = "";
      this.form.payment = "";
      this.form.deposit = "";
      this.bankAccount = "";
    },
    async copyBankAccount() {
      try {
        await navigator.clipboard.writeText(this.bankAccount);
        Swal.fire("แจ้งเตือน", "คัดลอกหมายเลขบัญชีสำเร็จ", "success");
      } catch (err) {
        // console.error("Failed to copy: ", err);
      }
    },
    async getBank() {
      this.resetFormFields();

      let config = {
        method: "get",
        maxBodyLength: Infinity,
        url: `${this.$hostname}/getbank?search=&sort&order=desc&offset=0&limit=10&action&start_date=&end_date=`,
        headers: {
          authorization: this.$cookies.get("token"),
        },
      };

      Swal.showLoading();

      await axios
        .request(config)
        .then((res) => {
          Swal.close();
          let data = res.data;
          this.options.payment = [];
          this.options.phone = [];

          if (data.res_code === "00") {
            const bankData = data.res_result.rows;
            bankData.forEach((bank) => {
              if (bank.m_withdraw == 1) {
                this.options.payment.push({
                  label: `${bank.fname} ${bank.lname}`,
                  value: bank.number,
                });

                this.form.payment = bank.number;
              } else {
                this.options.phone.push({
                  label: `${bank.fname} ${bank.lname}`,
                  value: bank.number,
                });
              }
            });

            this.modals.modalTransfer = true;
          } else {
            Swal.fire("แจ้งเตือน", data.res_text, "error");
          }
        })
        .catch((error) => {});
    },

    async submitTransfer() {
      if (!this.form.phone || !this.form.payment || !this.form.deposit) {
        return Swal.fire("แจ้งเตือน", "กรุณากรอกข้อมูลให้ครบ", "error");
      }

      const formData = {
        phone: this.form.phone,
        payment: this.form.payment,
        deposit: this.form.deposit,
      };

      this.loading = true;

      let config = {
        method: "post",
        maxBodyLength: Infinity,
        url: `${this.$hostname}/Qrcode`,
        headers: {
          authorization: this.$cookies.get("token"),
          "Content-Type": "application/json",
        },
        data: formData,
      };

      await axios
        .request(config)
        .then((res) => {
          this.loading = false;
          const data = res.data;

          this.resetFormFields();
          this.modals.modalTransfer = false;

          if (data.res_result.payurl) {
            return window.open(data.res_result.payurl);
          }

          if (data.res_result.p_url) {
            return window.open(data.res_result.p_url);
          }
          if (!data.res_result.bankAccount) {
            return Swal.fire(
              "แจ้งเตือน",
              "ไม่สามารถทำรายการได้ กรุณาลองใหม่อีกครั้ง",
              "error"
            );
          }

          this.bank = data.res_result.bank;
          this.bankAccount = data.res_result.bankAccount;
          this.bankAccountName = data.res_result.bankAccountName;
          this.depositAmount = data.res_result.depositAmount;
        })
        .catch((error) => {
          this.loading = false;
          if (error) {
            Swal.fire(
              "แจ้งเตือน",
              "ไม่สามารถทำรายการได้ กรุณาลองใหม่อีกครั้ง",
              "error"
            );
          }
        });
    },
    closeModal(name) {
      this.modals[name] = false;
    },
  },
};
</script>
<style>
.el-input__inner:hover,
.el-input__inner {
  color: #000 !important;
}
</style>
