<template>
  <div class="row">
    <div class="col-12">
      <div class="row">
        <div class="col-12 col-sm-3 col-md-4 col-lg-3">
          <div class="d-flex mb-2">
            <div class="my-auto mr-2" style="width: 30px">เริ่ม</div>
            <fg-input class="m-0 w-100">
              <el-date-picker
                v-model="startDate"
                type="date"
                placeholder="วันที่เริ่ม"
                format="dd/MM/yyyy"
                value-format="yyyy-MM-dd"
                @change="getDepositAdmin()"
              >
              </el-date-picker>
            </fg-input>
          </div>
        </div>
        <div class="col-12 col-sm-3 col-md-4 col-lg-3">
          <div class="d-flex mb-2">
            <div class="my-auto mr-2" style="width: 30px">ถึง</div>
            <fg-input class="m-0 w-100">
              <el-date-picker
                v-model="endDate"
                type="date"
                placeholder="ถึง"
                format="dd/MM/yyyy"
                value-format="yyyy-MM-dd"
                @change="getDepositAdmin()"
              >
              </el-date-picker>
            </fg-input>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-12 col-sm-4 col-md-3 col-lg-2 d-inline-block">
          <el-select
            class="select-default mb-3 w-100"
            v-model="pagination.perPage"
            placeholder="Per page"
          >
            <el-option
              class="select-default"
              v-for="item in pagination.perPageOptions"
              :key="item"
              :label="item"
              :value="item"
            >
            </el-option>
          </el-select>
        </div>

        <div class="col-12 col-sm-6 col-md-4 col-lg-3 ml-auto d-flex mb-3">
          <el-input
            type="search"
            class="my-auto mr-2"
            placeholder="ค้นหา"
            v-model="searchQuery"
            aria-controls="datatables"
          />
          <el-button
            type="danger"
            size="default"
            @click="getDepositAdmin()"
            :loading="loading"
          >
            <i class="fa fa-refresh" aria-hidden="true"></i>
          </el-button>
        </div>
      </div>
      <div class="row">
        <div class="col-sm-12">
          <el-table
            stripe
            style="width: 100%"
            :data="tableData"
            border
            v-loading="loading"
          >
            <el-table-column :min-width="75" label="ลำดับ">
              <template v-slot="props">
                <span v-html="props ? props.row.D0 + 1 : ''"> </span>
              </template>
            </el-table-column>
            <el-table-column :min-width="100" label="วันที่/เข้าร่วม">
              <template v-slot="props">
                <span v-html="props ? props.row.D1 : ''"> </span>
              </template>
            </el-table-column>
            <el-table-column :min-width="100" label="ยอดเงิน">
              <template v-slot="props">
                <span
                  v-html="
                    props
                      ? Number(props.row.D2).toLocaleString(undefined, {
                          minimumFractionDigits: 2,
                        })
                      : ''
                  "
                >
                </span>
              </template>
            </el-table-column>
            <el-table-column :min-width="100" label="Username">
              <template v-slot="props">
                <span v-html="props ? props.row.D3 : ''"> </span>
              </template>
            </el-table-column>
            <el-table-column :min-width="100" label="เลขบัญชี">
              <template v-slot="props">
                <span v-html="props ? props.row.D4 : ''"> </span>
              </template>
            </el-table-column>
            <el-table-column :min-width="100" label="ช่องทาง">
              <template v-slot="props">
                <span v-html="props ? props.row.D5 : ''"> </span>
              </template>
            </el-table-column>
            <el-table-column :min-width="100" label="ประเภท">
              <template v-slot="props">
                <span v-html="props ? props.row.D8 : ''"> </span>
              </template>
            </el-table-column>
            <el-table-column :min-width="100" label="หมายเหตุ">
              <template v-slot="props">
                <div v-if="props.row.D6 === 'ฝาก'">
                  <span class="badge badge-success">{{ props.row.D6 }}</span>
                </div>
                <div v-else>
                  <span class="badge badge-warning">{{ props.row.D6 }}</span>
                </div>
              </template>
            </el-table-column>
            <el-table-column :min-width="100" label="ผู้ทำรายการ">
              <template v-slot="props">
                <span
                  v-html="props ? props.row.D7 : ''"
                  class="badge badge-info"
                >
                </span>
              </template>
            </el-table-column>
          </el-table>
        </div>
      </div>
      <div
        slot="footer"
        class="col-12 d-flex justify-content-center justify-content-sm-between flex-wrap"
      >
        <div class="">
          <p class="card-category">
            Showing {{ from + 1 }} to {{ to }} of {{ total }} entries
          </p>
        </div>
        <l-pagination
          class="pagination-no-border"
          v-model="pagination.currentPage"
          :per-page="pagination.perPage"
          :total="pagination.total"
        >
        </l-pagination>
      </div>
    </div>
  </div>
</template>
<script>
import {
  Table,
  TableColumn,
  Select,
  Option,
  DatePicker,
  Button,
} from "element-ui";
import { Pagination as LPagination } from "src/components/index";
import moment from "moment";
import axios from "axios";

export default {
  components: {
    LPagination,
    [Select.name]: Select,
    [Option.name]: Option,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    [DatePicker.name]: DatePicker,
    [Button.name]: Button,
  },
  data() {
    return {
      startDate: moment(new Date()).format("YYYY-MM-DD"),
      endDate: moment(new Date()).format("YYYY-MM-DD"),

      pagination: {
        perPage: 10,
        currentPage: 1,
        perPageOptions: [5, 10, 25, 50],
        total: 0,
      },

      tableData: [],

      searchQuery: "",
      loading: false,
      timeoutDepositError: "",
    };
  },
  computed: {
    to() {
      let highBound = this.from + this.pagination.perPage;
      if (this.total < highBound) {
        highBound = this.total;
      }
      return highBound;
    },
    from() {
      this.getDepositAdmin();
      return this.pagination.perPage * (this.pagination.currentPage - 1);
    },
    total() {
      return this.pagination.total;
    },
  },

  methods: {
    async getDepositAdmin() {
      this.loading = true;
      let offset = this.pagination.perPage * (this.pagination.currentPage - 1);
      let config = {
        method: "get",
        maxBodyLength: Infinity,
        url: `${this.$hostname}/depositadmin?search=${this.searchQuery}&sort&order=desc&offset=${offset}&limit=${this.pagination.perPage}&action&start_date=${this.startDate}&end_date=${this.endDate}`,
        headers: {
          authorization: this.$cookies.get("token"),
        },
        timeout: 5000,
      };

      clearTimeout(this.timeoutDepositError);

      this.timeoutDepositError = setTimeout(async () => {
        await axios
          .request(config)
          .then((res) => {
            this.loading = false;
            const data = res.data.res_result;
            this.tableData = data.rows;
            this.pagination.total = data.total;
          })
          .catch((error) => {
            this.loading = false;
          });
      }, 1000);
    },
  },
};
</script>
<style></style>
