<template>
  <div class="row">
    <div class="col-12">
      <h3 class="mb-3 mt-0">ผู้เล่น</h3>
      <card>
        <!-- <div slot="header"></div> -->
        <div class="row mb-3">
          <div class="col-sm-3 d-inline-block">
            <el-select
              class="select-default mb-3 w-100"
              v-model="pagination.perPage"
              placeholder="Per page"
            >
              <el-option
                class="select-default"
                v-for="item in pagination.perPageOptions"
                :key="item"
                :label="item"
                :value="item"
              >
              </el-option>
            </el-select>
          </div>

          <div class="col-sm-3 offset-lg-6 d-inline-block text-right">
            <el-button
              type="danger"
              size="default"
              @click="getLottoMember()"
              :loading="loading"
            >
              <i class="fa fa-refresh" aria-hidden="true"></i>
            </el-button>
          </div>
        </div>
        <div class="row">
          <div class="col-sm-12">
            <el-table stripe :data="tableData" border v-loading="loading">
              <el-table-column :min-width="100" label="วันที่เล่น">
                <template v-slot="props">
                  <span v-html="props ? props.row.D1 : ''"></span>
                </template>
              </el-table-column>
              <el-table-column :min-width="150" label="ผู้เล่น">
                <template v-slot="props">
                  <span v-html="props ? props.row.D2 : ''"></span>
                </template>
              </el-table-column>
              <el-table-column :min-width="150" label="เลขที่เล่น">
                <template v-slot="props">
                  <span v-html="props ? props.row.D3 : ''"></span>
                </template>
              </el-table-column>
              <el-table-column :min-width="150" label="สถานะ">
                <template v-slot="props">
                  <span v-if="props.row.D4 === 0" class="badge badge-info"
                    >รอ</span
                  >
                  <span v-if="props.row.D4 === 1" class="badge badge-success"
                    >ถูกรางวัล</span
                  >
                  <span v-if="props.row.D4 === 2" class="badge badge-danger"
                    >ไม่ถูกรางวัล</span
                  >
                </template>
              </el-table-column>
            </el-table>
          </div>
        </div>
        <div
          slot="footer"
          class="col-12 d-flex justify-content-center justify-content-sm-between flex-wrap"
        >
          <div class="">
            <p class="card-category">
              Showing {{ from + 1 }} to {{ to }} of {{ total }} entries
            </p>
          </div>
          <l-pagination
            class="pagination-no-border"
            v-model="pagination.currentPage"
            :per-page="pagination.perPage"
            :total="pagination.total"
          >
          </l-pagination>
        </div>
      </card>
    </div>
  </div>
</template>
<script>
import { Table, TableColumn, Button, Select, Option } from "element-ui";
import { Pagination as LPagination } from "src/components/index";
import axios from "axios";
export default {
  props: ["lotto_id"],
  components: {
    LPagination,
    [Button.name]: Button,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    [Select.name]: Select,
    [Option.name]: Option,
  },
  data() {
    return {
      loading: true,

      pagination: {
        perPage: 10,
        currentPage: 1,
        perPageOptions: [10, 20, 30, 50],
        total: 0,
      },

      searchQuery: "",
      propsToSearch: ["username"],
      tableData: [],
      fuseSearch: null,
    };
  },

  computed: {
    pagedData() {
      return this.tableData.slice(this.from, this.to);
    },

    queriedData() {
      let result = this.tableData;
      if (this.searchQuery !== "") {
        result = this.fuseSearch.search(this.searchQuery);
        this.paginationTotal(result.length);
      }
      return result.slice(this.from, this.to);
    },
    to() {
      let highBound = this.from + this.pagination.perPage;
      if (this.total < highBound) {
        highBound = this.total;
      }
      return highBound;
    },
    from() {
      this.getLottoMember();
      return this.pagination.perPage * (this.pagination.currentPage - 1);
    },
    total() {
      return this.pagination.total;
    },
  },
  mounted() {},
  methods: {
    paginationTotal(value) {
      this.pagination.total = value;
    },
    async getLottoMember() {
      let offset = this.pagination.perPage * (this.pagination.currentPage - 1);

      let config = {
        method: "get",
        maxBodyLength: Infinity,
        url: `${this.$hostname}/get_lotto_member?search=&sort=&order=desc&offset=${offset}&limit=${this.pagination.perPage}&lotto_id=${this.lotto_id}`,
        headers: {
          authorization: this.$cookies.get("token"),
        },
      };
      this.loading = true;
      await axios
        .request(config)
        .then((res) => {
          this.loading = false;
          this.tableData = [];
          const data = res.data;
          if (data.res_code === "00") {
            this.tableData = data.res_result.rows;
            this.pagination.total = data.res_result.total;
          }
        })
        .catch((error) => {
          this.loading = false;
        });
    },
  },
  watch: {},
};
</script>
<style></style>
