<template>
  <div v-if="!this.$func.checkPermission('ตั้งค่าเว็บไซต์')" class="row">
    <div class="col">
      <div class="card">
        <div class="card-body">
          <div class="alert alert-danger" role="alert">
            <strong>คุณไม่มีสิทธิ์เข้าถึงหน้านี้</strong>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div v-else>
    <div class="row">
      <div class="col-12">
        <div><h3 class="mb-3 mt-0">ส่งข้อความ SMS</h3></div>
        <div class="card mb-2" v-loading="loadingCredit">
          <div class="card-body">
            <p class="card-text mb-2">เครดิต SMS คงเหลือ</p>
            <h4 class="card-title fw-bold blink">
              {{
                Number(credit).toLocaleString(undefined, {
                  minimumFractionDigits: 2,
                })
              }}
            </h4>
          </div>
        </div>
        <div class="card mb-2">
          <div class="card-body">
            <el-tabs
              type="border-card"
              v-model="activeName"
              @tab-click="handleClick"
            >
              <el-tab-pane label="ส่งข้อความ" name="send-sms">
                <div>
                  <div><h4 class="mt-0">ฟอร์มเงื่อนไข</h4></div>
                  <div class="mb-2">
                    <div class="row">
                      <div class="col-12 col-md-4">
                        <div>เงื่อนไข</div>
                        <el-select
                          v-model="form.condition"
                          placeholder="เลือกเงื่อนไข"
                          class="text-dark"
                          @change="form.date = ''"
                        >
                          <el-option
                            v-for="item in conditionOptions"
                            :key="item.value"
                            :label="item.label"
                            :value="item.value"
                          >
                          </el-option>
                        </el-select>
                      </div>
                      <div class="col-12 col-md-4">
                        <div v-if="form.condition === 1" class="d-block">
                          <div>ตั้งแต่วันที่ - ปัจจุบัน</div>
                          <el-date-picker
                            v-model="form.date"
                            type="date"
                            placeholder="เลือกวันที่"
                            format="yyyy-MM-dd"
                          >
                          </el-date-picker>
                        </div>
                        <div v-if="form.condition === 2" class="d-block">
                          <div>วันที่เริ่มต้น - วันที่สิ้นสุด</div>
                          <el-date-picker
                            v-model="form.date"
                            type="daterange"
                            range-separator="ถึง"
                            start-placeholder="วันที่เริ่มต้น"
                            end-placeholder="วันที่สิ้นสุด"
                          >
                          </el-date-picker>
                        </div>
                        <div v-if="form.condition === 3" class="d-block">
                          <div>Rank</div>
                          <el-select
                            v-model="form.rank"
                            placeholder="เลือก Rank"
                            class="text-dark"
                          >
                            <el-option
                              v-for="item in rankOptions"
                              :key="item.value"
                              :label="item.label"
                              :value="item.value"
                            >
                            </el-option>
                          </el-select>
                        </div>
                        <div v-if="form.condition === 4" class="d-block">
                          <div class="form-group">
                            <div>จำนวนวัน</div>
                            <input
                              v-model.number="form.date"
                              type="number"
                              class="form-control"
                              placeholder="กรอกจำนวนวัน"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div>
                    <el-button
                      type="primary"
                      icon="el-icon-check"
                      :loading="loadingNumberPhone"
                      @click="getNumberPhone()"
                      class="ml-auto"
                      >ดึงข้อมูล</el-button
                    >
                  </div>
                </div>
                <hr />
                <div>
                  <div><h4 class="mt-0">ฟอร์มข้อความ</h4></div>
                  <div class="mb-2" v-loading="loadingNumberPhone">
                    <h5>
                      จำนวนเบอร์ : {{ numberPhone.toLocaleString() }} เบอร์
                    </h5>
                  </div>
                  <div class="mb-2">
                    <div class="d-flex">
                      <div>
                        ข้อความที่ต้องการส่ง ({{ form.desc.length }} ตัวอักษร)
                      </div>
                      <div class="ml-auto">{{ form.desc.length }} / 160</div>
                    </div>
                    <div>
                      <textarea
                        class="form-control"
                        v-model="form.desc"
                        rows="6"
                        maxlength="160"
                        placeholder="กรอกข้อความที่ต้องการส่ง"
                      ></textarea>
                    </div>
                  </div>
                  <div
                    v-if="numberPhone === 0"
                    class="my-auto mr-2 text-danger"
                  >
                    *กรุณาดึงข้อมูล
                  </div>
                  <el-button
                    type="success"
                    icon="el-icon-s-promotion"
                    :disabled="numberPhone === 0"
                    :loading="loadingSubmitSendMsg"
                    @click="sendMessage()"
                    >ส่งข้อความ</el-button
                  >
                </div>
              </el-tab-pane>
              <el-tab-pane label="ประวัติส่งข้อความ" name="send-sms-history"
                ><h5 class="mb-2">ประวัติส่งข้อความ</h5>
                <div class="row">
                  <div class="col-sm-12">
                    <el-table stripe :data="tableData" border>
                      <el-table-column :min-width="60" label="ลำดับ">
                        <template v-slot="props">
                          <span v-html="props ? props.row.D0 : ''"></span>
                        </template>
                      </el-table-column>
                      <el-table-column :min-width="200" label="รายละเอียด">
                        <template v-slot="props">
                          <span v-html="props ? props.row.D1 : ''"></span>
                        </template>
                      </el-table-column>
                      <el-table-column :min-width="100" label="จาก">
                        <template v-slot="props">
                          <span v-html="props ? props.row.D4 : ''"></span>
                        </template>
                      </el-table-column>
                      <el-table-column :min-width="100" label="เวลาเติม">
                        <template v-slot="props">
                          <span v-html="props ? props.row.D2 : ''"></span>
                        </template>
                      </el-table-column>

                      <el-table-column :min-width="100" label="เวลาบันทึก">
                        <template v-slot="props">
                          <span v-html="props ? props.row.D3 : ''"></span>
                        </template>
                      </el-table-column>
                    </el-table>
                  </div>
                </div>
                <div
                  slot="footer"
                  class="col-12 d-flex justify-content-center justify-content-sm-between flex-wrap"
                >
                  <div class="">
                    <p class="card-category">
                      Showing {{ from + 1 }} to {{ to }} of {{ total }} entries
                    </p>
                  </div>
                  <l-pagination
                    class="pagination-no-border"
                    v-model="pagination.currentPage"
                    :per-page="pagination.perPage"
                    :total="pagination.total"
                  >
                  </l-pagination>
                </div>
              </el-tab-pane>
            </el-tabs>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import {
  Button,
  Select,
  Option,
  DatePicker,
  Tabs,
  TabPane,
  Switch,
  TableColumn,
  Table,
  Loading,
} from "element-ui";
import { Pagination as LPagination } from "src/components/index";
import axios from "axios";
import Swal from "sweetalert2";

export default {
  components: {
    [Button.name]: Button,
    [Select.name]: Select,
    [Option.name]: Option,
    [DatePicker.name]: DatePicker,
    [Tabs.name]: Tabs,
    [TabPane.name]: TabPane,
    [Switch.name]: Switch,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    [Loading.name]: Loading,
  },
  data() {
    return {
      activeName: "send-sms",
      credit: 0,
      numberPhone: 0,
      numberCreditPerPhone: 0,
      totalCredit: 0,

      form: {
        condition: "เลือกเงื่อนไข",
        desc: "",
        date: "",
        rank: "",
      },

      conditionOptions: [
        {
          value: 1,
          label: "เคยเล่นแล้วเลิกเล่น (กำหนดช่วง)",
        },
        {
          value: 2,
          label: "สมัครแต่ไม่ฝาก (กำหนดช่วง)",
        },
        {
          value: 3,
          label: "ระดับ Rank",
        },
        {
          value: 4,
          label: "สมัครแต่ไม่ฝาก (กำหนดจำนวนวันย้อนหลัง)",
        },
      ],

      rankOptions: [
        {
          value: 1,
          label: "1",
        },
        {
          value: 2,
          label: "2",
        },
        {
          value: 3,
          label: "3",
        },
        {
          value: 4,
          label: "4",
        },
        {
          value: 5,
          label: "5",
        },
      ],

      pagination: {
        perPage: 10,
        currentPage: 1,
        perPageOptions: [10, 20, 30, 50],
        total: 0,
      },
      searchQuery: "",
      tableData: [],

      loadingCredit: false,
      loadingNumberPhone: false,
      loadingSubmitSendMsg: false,
    };
  },
  computed: {
    queriedData() {
      let result = this.tableData;
      return result.slice(this.from, this.to);
    },
    to() {
      let highBound = this.from + this.pagination.perPage;
      if (this.total < highBound) {
        highBound = this.total;
      }
      return highBound;
    },
    from() {
      return this.pagination.perPage * (this.pagination.currentPage - 1);
    },
    total() {
      return this.pagination.total;
    },
  },
  mounted() {
    this.checkCredit();
  },

  methods: {
    async checkCredit() {
      this.loadingCredit = true;
      let config = {
        method: "get",
        maxBodyLength: Infinity,
        url: `${this.$hostname}/checkcredit`,
        headers: {
          authorization: this.$cookies.get("token"),
          "Content-Type": "application/json",
        },
      };

      await axios
        .request(config)
        .then((res) => {
          this.loadingCredit = false;
          let data = res.data;
          if (data.res_code === "00") {
            this.credit = data.res_result;
          }
        })
        .catch((err) => {
          this.loadingCredit = false;
        });
    },
    async getNumberPhone() {
      if (isNaN(this.form.condition)) {
        return Swal.fire("แจ้งเตือน", "กรุณาเลือกเงื่อนไข", "info");
      }

      if (this.form.condition === 1 || this.form.condition === 2) {
        if (!this.form.date) {
          return Swal.fire("แจ้งเตือน", "กรุณาเลือกวันที่", "info");
        }
      }

      if (this.form.condition === 4 || this.form.condition === 5) {
        if (!this.form.date) {
          return Swal.fire("แจ้งเตือน", "กรุณากรอกจำนวนวัน", "info");
        }
      }

      const data = {
        condition: this.form.condition,
        date: this.form.date,
      };

      this.loadingNumberPhone = true;
      let config = {
        method: "post",
        maxBodyLength: Infinity,
        url: `${this.$hostname}/phone_sms`,
        headers: {
          authorization: this.$cookies.get("token"),
          "Content-Type": "application/json",
        },
        data: data,
      };
      await axios
        .request(config)
        .then((res) => {
          let data = res.data;
          if (data.res_code === "00") {
            this.numberPhone = data.res_result;
          } else {
            return Swal.fire("แจ้งเตือน", data.res_text, "error");
          }
        })
        .catch((err) => {
          return Swal.fire("แจ้งเตือน", err, "error");
        });

      this.loadingNumberPhone = false;
    },
    sendMessage() {
      if (!this.form.desc) {
        return Swal.fire("แจ้งเตือน", "กรุณากรอกข้อความที่ต้องการส่ง", "info");
      }

      this.loadingSubmitSendMsg = true;

      setTimeout(() => {
        this.loadingSubmitSendMsg = false;
        Swal.fire("แจ้งเตือน", "ส่งข้อความสำเร็จ", "success");

        this.form.desc = "";
      }, 2000);
    },
    handleClick(tab, event) {
      // console.log(tab, event);
    },
  },
};
</script>
<style>
.el-range-separator {
  width: 15% !important;
}
.el-select .el-input:hover input {
  color: #606266 !important;
}
</style>
