<template>
  <div class="row d-flex justify-content-center align-items-center">
    <div class="col-lg-4 col-md-6 col-sm-8">
      <ValidationObserver v-slot="{ handleSubmit }">
        <!--You can specify transitions on initial render. The `card-hidden` class will be present initially and then it will be removed-->
        <form @submit.prevent="handleSubmit(submitLogin)">
          <Transition>
            <card class="py-2">
              <div>
                <div class="mb-3 text-center">
                  <img :src="logo" alt="" class="mw-100" width="200" />
                </div>
                <h3 class="card-title text-center mb-2">Login</h3>
                <ValidationProvider
                  name="username"
                  rules="required"
                  v-slot="{ passed, failed }"
                >
                  <fg-input
                    type="text"
                    :error="failed ? 'The Username field is required' : null"
                    :hasSuccess="passed"
                    label="Username"
                    name="username"
                    v-model="username"
                    class="mb-2"
                  >
                  </fg-input>
                </ValidationProvider>
                <ValidationProvider
                  name="password"
                  rules="required|min:5"
                  v-slot="{ passed, failed }"
                >
                  <fg-input
                    type="password"
                    :error="failed ? 'The Password field is required' : null"
                    :hasSuccess="passed"
                    name="password"
                    label="Password"
                    v-model="password"
                    autocomplete="false"
                  >
                  </fg-input>
                </ValidationProvider>
                <ValidationProvider
                  name="Authenticator"
                  rules="required|min:5"
                  v-slot="{ passed, failed }"
                >
                  <fg-input
                    type="text"
                    :error="
                      failed ? 'The Authenticator field is required' : null
                    "
                    :hasSuccess="passed"
                    name="Authenticator"
                    label="Google Authenticator"
                    v-model="Authenticator"
                    autocomplete="false"
                  >
                  </fg-input>
                </ValidationProvider>
              </div>
              <div class="text-center" v-loading="loading">
                <button
                  type="submit"
                  class="btn btn-fill btn-primary btn-round btn-wd"
                >
                  <i class="fa fa-sign-in" aria-hidden="true"></i> เข้าสู่ระบบ
                </button>
              </div>
            </card>
          </Transition>
        </form>
      </ValidationObserver>
    </div>
  </div>
</template>
<script>
import { extend } from "vee-validate";
import { required, min } from "vee-validate/dist/rules";
import Swal from "sweetalert2";

import axios from "axios";
extend("required", required);
extend("min", min);

export default {
  components: {},
  data() {
    return {
      loading: false,
      username: "",
      password: "",
      Authenticator: "",
      logo: "",
    };
  },
  created() {
    this.$cookies.remove("webname");
    this.$cookies.remove("logo");
    this.$cookies.remove("refresh_token");
    this.$cookies.remove("token");
    this.$cookies.remove("username");

    this.getWebsite();
  },
  methods: {
    async submitLogin() {
      if (!this.username || !this.password || !this.Authenticator) {
        alert("กรุณากรอกข้อมูลให้ครบ");
        return;
      }
      let data = JSON.stringify({
        username: this.username,
        password: this.password,
        Authenticator: this.Authenticator,
      });

      let config = {
        method: "post",
        maxBodyLength: Infinity,
        url: `${this.$hostname}/login`,
        headers: {
          "Content-Type": "application/json",
        },
        data: data,
        timeout: 10000,
      };

      this.loading = true;

      await axios
        .request(config)
        .then((response) => {
          this.loading = false;
          const res = response.data;
          if (res.res_code === "00") {
            Swal.fire({
              title: "แจ้งเตือน",
              text: "เข้าสู่ระบบสำเร็จ",
              icon: "success",
              timer: 1000,
              showConfirmButton: false,
            }).then(() => {
              this.$cookies.set("token", res.res_result.token);
              this.$cookies.set("refresh_token", res.res_result.refresh_token);
              this.$cookies.set("username", res.res_result.username);
              this.$router.push("/dashboardall");
            });
          } else {
            this.loading = false;
            return Swal.fire({
              title: "แจ้งเตือน",
              text: res.res_text,
              icon: "error",
              showConfirmButton: true,
            });
          }
        })
        .catch((error) => {
          this.loading = false;

          return Swal.fire(
            "แจ้งเตือน",
            "ไม่สามารถเข้าสู่ระบบได้ กรุณาลองใหม่อีกครั้ง",
            "error"
          );
        });
    },
    async getWebsite() {
      let config = {
        method: "get",
        maxBodyLength: Infinity,
        url: `${this.$hostname}/website`,
      };

      await axios
        .request(config)
        .then((res) => {
          let data = res.data;
          if (data.res_code === "00") {
            this.$cookies.set("webname", data.res_result[0].name);
            this.$cookies.set("logo", `/static` + data.res_result[0].logo);
            this.logo = `/static` + data.res_result[0].logo;
          }
        })
        .catch((error) => {
          // console.log(error);
        });
    },
    toggleNavbar() {
      document.body.classList.toggle("nav-open");
    },
    closeMenu() {
      document.body.classList.remove("nav-open");
      document.body.classList.remove("off-canvas-sidebar");
    },
  },
  beforeDestroy() {
    this.closeMenu();
  },
};
</script>
<style>
.navbar-nav .nav-item p {
  line-height: inherit;
  margin-left: 5px;
}
</style>
