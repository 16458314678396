<template>
  <div class="wrapper" :class="{ 'nav-open': $sidebar.showSidebar }">
    <side-bar>
      <user-menu :title="title"></user-menu>
      <!-- <mobile-menu></mobile-menu> -->
      <template slot="links">
        <li
          v-if="this.$func.checkPermission('สรุปภาพรวม')"
          :class="this.$route.path == '/dashboardall' ? 'active' : ''"
          class="nav-item"
        >
          <router-link to="/dashboardall" class="nav-link">
            <i class="nc-icon nc-chart-bar-32"></i>
            <p>สรุปภาพรวม</p>
          </router-link>
        </li>
        <li
          v-if="this.$func.checkPermission('สรุปภาพรวม')"
          :class="this.$route.path == '/summary' ? 'active' : ''"
          class="nav-item"
        >
          <router-link to="/summary" class="nav-link">
            <i class="nc-icon nc-chart-bar-32"></i>
            <p>สรุปภาพรวมรายเดือน</p>
          </router-link>
        </li>
        <li
          v-if="this.$func.checkPermission('ผู้ดูแลADMIN')"
          :class="this.$route.path == '/admin' ? 'active' : ''"
          class="nav-item"
        >
          <router-link to="/admin" class="nav-link">
            <i class="nc-icon nc-single-02"></i>
            <p>ผู้ดูแล ADMIN</p>
          </router-link>
        </li>
        <li
          v-if="this.$func.checkPermission('สมาชิก')"
          :class="this.$route.path == '/member' ? 'active' : ''"
          class="nav-item"
        >
          <router-link to="/member" class="nav-link">
            <i class="nc-icon nc-single-02"></i>
            <p>สมาชิก</p>
          </router-link>
        </li>
        <li
          v-if="this.$func.checkPermission('ติดตามลูกค้า')"
          :class="this.$route.path == '/followcustomer' ? 'active' : ''"
          class="nav-item"
        >
          <router-link to="/followcustomer" class="nav-link">
            <i class="nc-icon nc-tag-content"></i>
            <p>ติดตามลูกค้า</p>
          </router-link>
        </li>

        <li
          v-if="this.$func.checkPermission('รายการฝาก')"
          :class="this.$route.path == '/depositerror' ? 'active' : ''"
          class="nav-item"
        >
          <router-link to="/depositerror" class="nav-link">
            <i class="nc-icon nc-zoom-split"></i>
            <p class="d-flex">
              รายการฝาก
              <span class="ml-auto my-auto badge badge-danger">{{
                depositerrorAmount ? depositerrorAmount : 0
              }}</span>
            </p>
          </router-link>
        </li>
        <li
          v-if="this.$func.checkPermission('รายการแจ้งถอน')"
          :class="this.$route.path == '/withdraw' ? 'active' : ''"
          class="nav-item"
        >
          <router-link to="/withdraw" class="nav-link">
            <i class="nc-icon nc-bell-55"></i>
            <p class="d-flex">
              รายการแจ้งถอน
              <span class="ml-auto my-auto badge badge-danger">{{
                withdrawAmount ? withdrawAmount : 0
              }}</span>
            </p>
          </router-link>
        </li>
        <li
          v-if="this.$func.checkPermission('รายการถอนเงิน')"
          :class="this.$route.path == '/historywithdraw' ? 'active' : ''"
          class="nav-item"
        >
          <router-link to="/historywithdraw" class="nav-link">
            <i class="nc-icon nc-money-coins"></i>
            <p>รายการถอนเงิน</p>
          </router-link>
        </li>
        <li
          v-if="this.$func.checkPermission('รายการคืนยอดเสีย')"
          :class="this.$route.path == '/historycredit' ? 'active' : ''"
          class="nav-item"
        >
          <router-link to="/historycredit" class="nav-link">
            <i class="nc-icon nc-single-copy-04"></i>
            <p>รายการคืนยอดเสีย</p>
          </router-link>
        </li>
        <li
          v-if="this.$func.checkPermission('ระบบคูปอง')"
          :class="this.$route.path == '/coupon' ? 'active' : ''"
          class="nav-item"
        >
          <router-link to="/coupon" class="nav-link">
            <i class="nc-icon nc-spaceship"></i>
            <p>ระบบคูปอง</p>
          </router-link>
        </li>
        <li
          v-if="this.$func.checkPermission('ระบบคูปองกงล้อ')"
          :class="this.$route.path == '/couponspin' ? 'active' : ''"
          class="nav-item"
        >
          <router-link to="/couponspin" class="nav-link">
            <i class="fas fa-life-ring"></i>
            <p>ระบบคูปองกงล้อ</p>
          </router-link>
        </li>
        <li
          v-if="this.$func.checkPermission('หวย')"
          :class="this.$route.path == '/lotto' ? 'active' : ''"
          class="nav-item"
        >
          <router-link to="/lotto" class="nav-link">
            <i class="nc-icon nc-tag-content"></i>
            <p>ระบบหวย</p>
          </router-link>
        </li>
        <li
          v-if="this.$func.checkPermission('ระบบพันธมิตร')"
          :class="this.$route.path == '/affiliate' ? 'active' : ''"
          class="nav-item"
        >
          <router-link to="/affiliate" class="nav-link">
            <i class="nc-icon nc-single-02"></i>
            <p>ระบบพันธมิตร</p>
          </router-link>
        </li>

        <li
          v-if="this.$func.checkPermission('statement')"
          :class="this.$route.path == '/statement' ? 'active' : ''"
          class="nav-item"
        >
          <router-link to="/statement" class="nav-link">
            <i class="nc-icon nc-credit-card"></i>
            <p>Statement</p>
          </router-link>
        </li>
        <li
          v-if="this.$func.checkPermission('ส่งข้อความ')"
          :class="this.$route.path == '/sms' ? 'active' : ''"
          class="nav-item"
        >
          <router-link to="/sms" class="nav-link">
            <i class="nc-icon nc-send"></i>
            <p>ส่งข้อความ SMS</p>
          </router-link>
        </li>
        <li
          v-if="this.$func.checkPermission('เทเลแกรม')"
          :class="this.$route.path == '/telegram' ? 'active' : ''"
          class="nav-item"
        >
          <router-link to="/telegram" class="nav-link">
            <i class="nc-icon nc-send"></i>
            <p>เทเลแกรม</p>
          </router-link>
        </li>
        <li
          v-if="this.$func.checkPermission('บัญชีดำ')"
          :class="this.$route.path == '/blacklist' ? 'active' : ''"
          class="nav-item"
        >
          <router-link to="/blacklist" class="nav-link">
            <i class="nc-icon nc-simple-remove"></i>
            <p>บัญชีดำ</p>
          </router-link>
        </li>
        <li
          v-if="this.$func.checkPermission('ธนาคาร')"
          :class="this.$route.path == '/bank' ? 'active' : ''"
          class="nav-item"
        >
          <router-link to="/bank" class="nav-link">
            <i class="nc-icon nc-bank"></i>
            <p>ธนาคาร</p>
          </router-link>
        </li>
        <li
          v-if="this.$func.checkPermission('ตั้งค่าแจ้งเตือน')"
          :class="this.$route.path == '/notify' ? 'active' : ''"
          class="nav-item"
        >
          <router-link to="/notify" class="nav-link">
            <i class="nc-icon nc-notification-70"></i>
            <p>ตั้งค่าการแจ้งเตือน</p>
          </router-link>
        </li>

        <li
          v-if="this.$func.checkPermission('ผู้ดูแลADMIN')"
          :class="this.$route.path == '/log' ? 'active' : ''"
          class="nav-item"
        >
          <router-link to="/log" class="nav-link">
            <i class="nc-icon nc-preferences-circle-rotate"></i>
            <p>สถานะของระบบ</p>
          </router-link>
        </li>

        <li
          v-if="this.$func.checkPermission('ตั้งค่าเว็บไซต์')"
          :class="this.$route.path == '/website' ? 'active' : ''"
          class="nav-item"
        >
          <router-link to="/website" class="nav-link">
            <i class="nc-icon nc-settings-90"></i>
            <p>ตั้งค่าเว็บไซต์</p>
          </router-link>
        </li>
      </template>
    </side-bar>

    <div class="main-panel">
      <top-navbar></top-navbar>

      <dashboard-content @click.native="toggleSidebar"> </dashboard-content>

      <content-footer></content-footer>
    </div>
  </div>
</template>
<script>
import TopNavbar from "./TopNavbar.vue";
import ContentFooter from "./ContentFooter.vue";
import DashboardContent from "./Content.vue";
// import MobileMenu from "./Extra/MobileMenu.vue";
import UserMenu from "./Extra/UserMenu.vue";
import PerfectScrollbar from "perfect-scrollbar";
import "perfect-scrollbar/css/perfect-scrollbar.css";
import axios from "axios";

function hasElement(className) {
  return document.getElementsByClassName(className).length > 0;
}

function initScrollbar(className) {
  if (hasElement(className)) {
    new PerfectScrollbar(`.${className}`);
  } else {
    // try to init it later in case this component is loaded async
    setTimeout(() => {
      initScrollbar(className);
    }, 100);
  }
}
export default {
  components: {
    TopNavbar,
    ContentFooter,
    DashboardContent,
    // MobileMenu,
    UserMenu,
  },
  data() {
    return {
      title: "",
      category: global.category,
      withdrawAlert: global.withdrawAlert,
      withdrawAmount: "",
      depositerrorAmount: "",

      audioContext: null,
      audioBuffer: null,
      audioSource: null,

      intervalWithdraw: null,
    };
  },

  methods: {
    async fetchAudioData() {
      try {
        const audioContext = new (window.AudioContext ||
          window.webkitAudioContext)();
        this.audioContext = audioContext;

        // โหลดไฟล์เสียงของคุณที่นี่
        // const response = await fetch("/static/audio/notify.mp3");
        const response = await fetch("/static/audio/notify.mp3");
        const audioData = await response.arrayBuffer();

        audioContext.decodeAudioData(audioData, (buffer) => {
          this.audioBuffer = buffer;
        });
      } catch (error) {}
    },
    playAudio() {
      if (!this.audioBuffer || !this.audioContext) {
        // console.error("ยังไม่ได้โหลดข้อมูลเสียง.");
        return;
      }

      // ตรวจสอบว่าต้องการการสร้าง AudioContext และเรียก resume() หากจำเป็น
      if (!this.audioContext.state || this.audioContext.state === "suspended") {
        this.audioContext.resume().then(() => {
          this.internalPlayAudio();
        });
      } else {
        this.internalPlayAudio();
      }
    },
    internalPlayAudio() {
      if (this.audioSource) {
        this.audioSource.stop();
      }

      const audioSource = this.audioContext.createBufferSource();
      audioSource.buffer = this.audioBuffer;
      audioSource.connect(this.audioContext.destination);
      audioSource.start();

      this.audioSource = audioSource;
    },

    toggleSidebar() {
      if (this.$sidebar.showSidebar) {
        this.$sidebar.displaySidebar(false);
      }
    },
    initScrollbar() {
      let docClasses = document.body.classList;
      let isWindows = navigator.platform.startsWith("Win");
      if (isWindows) {
        // if we are on windows OS we activate the perfectScrollbar function
        initScrollbar("main-panel");

        docClasses.add("perfect-scrollbar-on");
      } else {
        docClasses.add("perfect-scrollbar-off");
      }
    },
    async fetchAmount() {
      await axios
        .get(`${this.$hostname}/amount?chk=1`)
        .then((res) => {
          this.withdrawAmount = res.data.withdrawAmount;
          this.depositerrorAmount = res.data.depositerrorAmount;

          if (res.data.withdrawAmount > 0) {
            this.playAudio();
          }
        })
        .catch((err) => {
          // console.log(err);
        });
    },
  },
  created() {
    this.fetchAudioData();

    if (!this.intervalWithdraw) {
      this.fetchAmount();
    }

    this.intervalWithdraw = setInterval(() => {
      this.fetchAmount();
    }, 15000);
  },
  beforeDestroy() {
    clearInterval(this.intervalWithdraw);

    if (this.audioSource) {
      this.audioSource.stop();
    }

    if (this.audioContext) {
      this.audioContext.close();
    }
  },
  mounted() {
    this.title = this.$cookies.get("username");
  },
  watch: {
    $route(to, from) {
      if (this.$sidebar.showSidebar) {
        this.$sidebar.displaySidebar(false);
      }
    },
  },
};
</script>
