<template>
  <div v-if="!this.$func.checkPermission('หวย')" class="row">
    <div class="col">
      <div class="card">
        <div class="card-body">
          <div class="alert alert-danger" role="alert">
            <strong>คุณไม่มีสิทธิ์เข้าถึงหน้านี้</strong>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div v-else>
    <div class="row">
      <div class="col-12">
        <h3 class="mb-3 mt-0">ระบบหวย</h3>
        <card>
          <div slot="header">
            <el-button type="primary" size="default" @click="addLotto()">
              <i class="fa fa-plus" aria-hidden="true"></i>
              สร้างงวดหวย
            </el-button>
          </div>
          <div class="row mb-3">
            <div class="col-sm-3 d-inline-block">
              <el-select
                class="select-default mb-3 w-100"
                v-model="pagination.perPage"
                placeholder="Per page"
              >
                <el-option
                  class="select-default"
                  v-for="item in pagination.perPageOptions"
                  :key="item"
                  :label="item"
                  :value="item"
                >
                </el-option>
              </el-select>
            </div>

            <div class="col-sm-3 offset-lg-6 d-inline-block text-right">
              <el-button
                type="danger"
                size="default"
                @click="getLotto()"
                :loading="loading"
              >
                <i class="fa fa-refresh" aria-hidden="true"></i>
              </el-button>
            </div>
          </div>
          <div class="row">
            <div class="col-sm-12">
              <el-table stripe :data="queriedData" border v-loading="loading">
                <el-table-column :min-width="100" label="งวดประจำวันที่">
                  <template v-slot="props">
                    <span v-html="props ? props.row.D1 : ''"></span>
                  </template>
                </el-table-column>
                <el-table-column :min-width="150" label="เริ่มทายผล">
                  <template v-slot="props">
                    <span v-html="props ? props.row.D2 : ''"></span>
                  </template>
                </el-table-column>
                <el-table-column
                  :min-width="150"
                  label="สิ้นสุดทายผล"
                  class="bg-danger"
                >
                  <template v-slot="props">
                    <span v-html="props ? props.row.D3 : ''"></span>
                  </template>
                </el-table-column>
                <el-table-column :min-width="100" label="เลขท้าย/ตัว">
                  <template v-slot="props">
                    <span v-html="props ? props.row.D4 : ''"></span>
                  </template>
                </el-table-column>
                <el-table-column :min-width="100" label="รางวัล">
                  <template v-slot="props">
                    <span v-html="props ? props.row.D5 : ''"></span>
                  </template>
                </el-table-column>
                <el-table-column :min-width="100" label="เลขที่ออก">
                  <template v-slot="props">
                    <span v-html="props.row.D6 ? props.row.D6 : '-'"></span>
                  </template>
                </el-table-column>
                <el-table-column :min-width="180" label="จัดการ">
                  <template v-slot="props">
                    <div v-if="props" class="d-flex justify-content-center">
                      <el-button
                        v-if="props.row.D6"
                        type="primary"
                        size="mini"
                        class="bg-secondary border-secondary mw-100"
                        :disabled="true"
                      >
                        เสร็จสิ้น</el-button
                      >
                      <span v-else>
                        <el-button
                          v-if="new Date() >= new Date(props.row.D3)"
                          type="success"
                          size="mini"
                          @click="
                            updateLottoNumber(
                              props.row.lotto_id,
                              props.row.D4,
                              props.row.D1,
                              props.row.D5
                            )
                          "
                        >
                          ออกรางวัล</el-button
                        >
                        <el-button
                          v-else
                          type="danger"
                          size="mini"
                          @click="deleteLotto(props.row.lotto_id)"
                        >
                          ลบ</el-button
                        >
                        <el-button
                          class="ml-1"
                          type="warning"
                          size="mini"
                          @click="editLotto(props.row)"
                        >
                          แก้ไข</el-button
                        >
                      </span>
                      <el-button
                        type="primary"
                        size="mini"
                        @click="showLottoMember(props.row.lotto_id)"
                        class="ml-1"
                        ><i class="fa fa-search" aria-hidden="true"></i>
                        ผู้เล่น</el-button
                      >
                    </div>
                  </template>
                </el-table-column>
              </el-table>
            </div>
          </div>
          <div
            slot="footer"
            class="col-12 d-flex justify-content-center justify-content-sm-between flex-wrap"
          >
            <div class="">
              <p class="card-category">
                Showing {{ from + 1 }} to {{ to }} of {{ total }} entries
              </p>
            </div>
            <l-pagination
              class="pagination-no-border"
              v-model="pagination.currentPage"
              :per-page="pagination.perPage"
              :total="pagination.total"
            >
            </l-pagination>
          </div>
        </card>
      </div>
    </div>
    <el-dialog :visible.sync="modals.modalAddLotto" width="30%">
      <span slot="title">
        <i class="fa fa-plus" aria-hidden="true"></i>
        สร้างงวดหวย
      </span>
      <div>
        <form>
          <div class="row">
            <div class="col-12 col-lg-6">
              <fg-input label="งวดประจำวันที่">
                <el-date-picker
                  v-model="formAdd.lotto_date"
                  type="date"
                  placeholder="งวดประจำวันที่"
                  value-format="yyyy-MM-dd"
                >
                </el-date-picker>
              </fg-input>
            </div>
            <div class="col-12 col-lg-6">
              <div class="form-group">
                <label for="lotto_number">รางวัลเลขท้าย/ตัว</label>
                <select
                  v-model="formAdd.lotto_length"
                  class="form-control"
                  name="lotto_number"
                  id="lotto_number"
                >
                  <option v-if="!formAdd.lotto_length" value="">
                    เลือกรางวัลเลขท้าย/ตัว
                  </option>
                  <option value="2">2</option>
                  <option value="3">3</option>
                </select>
              </div>
            </div>
            <div class="col-12 col-lg-6">
              <fg-input label="วันเริ่มต้น">
                <el-date-picker
                  v-model="formAdd.lotto_start"
                  type="datetime"
                  placeholder="วันเริ่มต้น"
                  value-format="yyyy-MM-dd hh:mm"
                >
                </el-date-picker>
              </fg-input>
            </div>
            <div class="col-12 col-lg-6">
              <fg-input label="วันสิ้นสุด">
                <el-date-picker
                  v-model="formAdd.lotto_end"
                  type="datetime"
                  placeholder="วันสิ้นสุด"
                  value-format="yyyy-MM-dd HH:mm"
                >
                </el-date-picker>
              </fg-input>
            </div>
            <div class="col-12 col-lg-6">
              <fg-input
                v-model="formAdd.lotto_credit"
                type="text"
                placeholder="จำนวนเครดิต"
                label="จำนวนเครดิต"
              >
              </fg-input>
            </div>
          </div>
        </form>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button
          type="success"
          size="default"
          @click="submitAddLotto()"
          :loading="loading"
          >บันทึก</el-button
        >
        <el-button
          type="danger"
          size="default"
          :loading="loading"
          @click="closeModal('modalAddLotto')"
          >ยกเลิก</el-button
        >
      </span>
    </el-dialog>
    <el-dialog :visible.sync="modals.modalUpdateLotto" width="30%">
      <span slot="title">
        <i class="fa fa-plus" aria-hidden="true"></i>
        แก้ไขรายการ
      </span>
      <div>
        <form>
          <div class="row">
            <div class="col-12 col-lg-6">
              <fg-input label="งวดประจำวันที่">
                <el-date-picker
                  v-model="formUpdate.lotto_date"
                  type="date"
                  placeholder="งวดประจำวันที่"
                  value-format="yyyy-MM-dd"
                  :disabled="true"
                >
                </el-date-picker>
              </fg-input>
            </div>
            <div class="col-12 col-lg-6">
              <div class="form-group">
                <label for="lotto_number">รางวัลเลขท้าย/ตัว</label>
                <select
                  v-model="formUpdate.lotto_length"
                  class="form-control"
                  name="lotto_number"
                  id="lotto_number"
                  :disabled="true"
                >
                  <option v-if="!formUpdate.lotto_length" value="">
                    เลือกรางวัลเลขท้าย/ตัว
                  </option>
                  <option value="2">2</option>
                  <option value="3">3</option>
                </select>
              </div>
            </div>
            <div class="col-12 col-lg-6">
              <fg-input label="วันเริ่มต้น">
                <el-date-picker
                  v-model="formUpdate.lotto_start"
                  type="datetime"
                  placeholder="วันเริ่มต้น"
                  value-format="yyyy-MM-dd hh:mm"
                  :disabled="true"
                >
                </el-date-picker>
              </fg-input>
            </div>
            <div class="col-12 col-lg-6">
              <fg-input label="วันสิ้นสุด">
                <el-date-picker
                  v-model="formUpdate.lotto_end"
                  type="datetime"
                  placeholder="วันสิ้นสุด"
                  value-format="yyyy-MM-dd HH:mm"
                >
                </el-date-picker>
              </fg-input>
            </div>
            <div class="col-12 col-lg-6">
              <fg-input
                v-model="formUpdate.lotto_credit"
                type="text"
                placeholder="จำนวนเครดิต"
                label="จำนวนเครดิต"
                :disabled="true"
              >
              </fg-input>
            </div>
          </div>
        </form>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button
          type="success"
          size="default"
          @click="submitUpdateLotto()"
          :loading="loading"
          >บันทึก</el-button
        >
        <el-button
          type="danger"
          size="default"
          :loading="loading"
          @click="closeModal('modalUpdateLotto')"
          >ยกเลิก</el-button
        >
      </span>
    </el-dialog>
    <el-dialog :visible.sync="modals.modalUpdateLottoNumber" width="40%">
      <span slot="title">
        <i class="fa fa-edit" aria-hidden="true"></i>
        ออกรางวัล
      </span>
      <div>
        <form>
          <div>
            รางวัลเลขท้าย:
            <span class="text-danger">{{ showLottoDetail.lotto_length }}</span>
            ตัว
          </div>
          <div>
            ประจำวันที่:
            <span class="text-danger">{{ showLottoDetail.lotto_date }}</span>
          </div>
          <div>
            จำนวนเครดิต:
            <span class="text-danger">{{ showLottoDetail.lotto_credit }}</span>
          </div>
          <hr />
          <div>
            <label>กรอกเลขที่ออก</label>
            <el-input
              v-model="formUpdateNumber.lotto_number"
              placeholder="กรอกเลขที่ออก"
              size="normal"
              clearable
              :minlength="showLottoDetail.lotto_length"
              :maxlength="showLottoDetail.lotto_length"
            ></el-input>
          </div>
        </form>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button
          type="success"
          size="default"
          @click="submitUpdateLottoNumber()"
          :loading="loading"
          >ยืนยัน</el-button
        >
        <el-button
          type="danger"
          size="default"
          :loading="loading"
          @click="closeModal('modalUpdateLottoNumber')"
          >ยกเลิก</el-button
        >
      </span>
    </el-dialog>
    <el-dialog :visible.sync="modals.modalLottoMember" width="50%">
      <span slot="title">
        <i class="fa fa-users" aria-hidden="true"></i>
        ผู้เล่น
      </span>
      <div><TableLottoMember :lotto_id="lottoMemberId" /></div>
      <span slot="footer" class="dialog-footer">
        <el-button
          type="danger"
          size="default"
          :loading="loading"
          @click="closeModal('modalLottoMember')"
          >ปิด</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>
<script>
import {
  Dialog,
  Table,
  TableColumn,
  Select,
  Option,
  Button,
  Form,
  DatePicker,
} from "element-ui";
import Swal from "sweetalert2";

import { Pagination as LPagination } from "src/components/index";
import axios from "axios";
import moment from "moment";
import TableLottoMember from "../components/TableLottoMember.vue";
export default {
  components: {
    [DatePicker.name]: DatePicker,
    LPagination,
    [Form.name]: Form,
    [Button.name]: Button,
    [Dialog.name]: Dialog,
    [Select.name]: Select,
    [Option.name]: Option,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    TableLottoMember,
  },
  data() {
    return {
      loading: true,

      formAdd: {
        lotto_date: "",
        lotto_length: "",
        lotto_start: "",
        lotto_end: "",
        lotto_credit: "",
      },
      formUpdate: {
        lotto_id: "",
        lotto_date: "",
        lotto_length: "",
        lotto_start: "",
        lotto_end: "",
        lotto_credit: "",
      },

      formUpdateNumber: {
        lotto_id: "",
        lotto_number: "",
      },

      showLottoDetail: {
        lotto_date: "",
        lotto_length: "",
        lotto_credit: "",
      },

      modals: {
        modalAddLotto: false,
        modalUpdateLottoNumber: false,
        modalUpdateLotto: false,
        modalLottoMember: false,
        centered: false,
        custom: false,
        confirm: false,
      },

      pagination: {
        perPage: 10,
        currentPage: 1,
        perPageOptions: [10, 20, 30, 50],
        total: 0,
      },

      searchQuery: "",
      propsToSearch: ["username"],
      tableData: [],
      fuseSearch: null,

      lottoMemberId: "",

      interValLotto: null,
    };
  },
  computed: {
    pagedData() {
      return this.tableData.slice(this.from, this.to);
    },

    queriedData() {
      let result = this.tableData;
      if (this.searchQuery !== "") {
        result = this.fuseSearch.search(this.searchQuery);
        this.paginationTotal(result.length);
      }
      return result.slice(this.from, this.to);
    },
    to() {
      let highBound = this.from + this.pagination.perPage;
      if (this.total < highBound) {
        highBound = this.total;
      }
      return highBound;
    },
    from() {
      this.getLotto();
      return this.pagination.perPage * (this.pagination.currentPage - 1);
    },
    total() {
      return this.pagination.total;
    },
  },
  mounted() {
    this.setNull();

    clearInterval(this.interValLotto);

    this.interValLotto = setInterval(async () => {
      await this.getLotto();
    }, 60000);
  },

  methods: {
    showLottoMember(lotto_id) {
      this.lottoMemberId = lotto_id;
      this.modals["modalLottoMember"] = true;
    },
    paginationTotal(value) {
      this.pagination.total = value;
    },

    closeModal(name) {
      this.modals[name] = false;
    },

    setNull() {
      this.formAdd.lotto_date = moment().format("YYYY-MM-DD");
      this.formAdd.lotto_length = "";
      this.formAdd.lotto_start = moment().format("YYYY-MM-DD HH:mm");
      this.formAdd.lotto_end = moment()
        .add(1, "hours")
        .format("YYYY-MM-DD HH:mm");

      this.formAdd.lotto_credit = "";

      this.formUpdateNumber.lotto_id = "";
      this.formUpdateNumber.lotto_number = "";

      this.formUpdate.lotto_id = "";
      this.formUpdate.lotto_date = "";
      this.formUpdate.lotto_length = "";
      this.formUpdate.lotto_start = "";
      this.formUpdate.lotto_end = "";
      this.formUpdate.lotto_credit = "";
    },

    addLotto() {
      this.setNull();
      this.modals["modalAddLotto"] = true;
    },

    async submitAddLotto() {
      if (!this.formAdd.lotto_date)
        return Swal.fire("แจ้งเตือน", "กรุณากรอกวันที่", "error");

      if (
        !this.formAdd.lotto_length ||
        this.formAdd.lotto_length < 2 ||
        isNaN(this.formAdd.lotto_length)
      )
        return Swal.fire("แจ้งเตือน", "กรุณากรอกรางวัลเลขท้าย", "error");

      if (!this.formAdd.lotto_start)
        return Swal.fire("แจ้งเตือน", "กรุณากรอกวันเริ่มต้น", "error");

      if (!this.formAdd.lotto_end)
        return Swal.fire("แจ้งเตือน", "กรุณากรอกวันสิ้นสุด", "error");

      if (
        !this.formAdd.lotto_credit ||
        this.formAdd.lotto_credit < 1 ||
        isNaN(this.formAdd.lotto_credit)
      )
        return Swal.fire("แจ้งเตือน", "กรุณากรอกเครดิตที่ได้รับ", "error");

      const formData = this.formAdd;

      let config = {
        method: "post",
        maxBodyLength: Infinity,
        url: `${this.$hostname}/insert_lotto`,
        headers: {
          authorization: this.$cookies.get("token"),
          "Content-Type": "application/json",
        },
        data: formData,
      };

      this.loading = true;

      await axios
        .request(config)
        .then((res) => {
          this.loading = false;
          let data = res.data;
          if (data.res_code == "00") {
            this.tableData = [];
            this.getLotto();
            this.modals["modalAddLotto"] = false;

            Swal.fire({
              title: "แจ้งเตือน",
              text: data.res_text,
              icon: "success",
              timer: 1500,
              showConfirmButton: false,
            });
          } else {
            this.loading = false;
            return Swal.fire("แจ้งเตือน", data.res_text, "error");
          }
        })
        .catch((error) => {
          this.loading = false;
        });
    },

    editLotto(lotto) {
      this.formUpdate.lotto_id = lotto.lotto_id;
      this.formUpdate.lotto_date = moment(lotto.lotto_date)
        .subtract(7, "hours")
        .format("YYYY-MM-DD");
      this.formUpdate.lotto_length = lotto.lotto_length;
      this.formUpdate.lotto_start = moment(lotto.lotto_start)
        .subtract(7, "hours")
        .format("YYYY-MM-DD HH:mm");
      this.formUpdate.lotto_end = moment(lotto.lotto_end)
        .subtract(7, "hours")
        .format("YYYY-MM-DD HH:mm");
      this.formUpdate.lotto_credit = lotto.lotto_credit;

      this.modals["modalUpdateLotto"] = true;
    },

    async submitUpdateLotto() {
      if (!this.formUpdate.lotto_date)
        return Swal.fire("แจ้งเตือน", "กรุณากรอกวันที่", "error");

      if (
        !this.formUpdate.lotto_length ||
        this.formUpdate.lotto_length < 2 ||
        isNaN(this.formUpdate.lotto_length)
      )
        return Swal.fire("แจ้งเตือน", "กรุณากรอกรางวัลเลขท้าย", "error");

      if (!this.formUpdate.lotto_start)
        return Swal.fire("แจ้งเตือน", "กรุณากรอกวันเริ่มต้น", "error");

      if (!this.formUpdate.lotto_end)
        return Swal.fire("แจ้งเตือน", "กรุณากรอกวันสิ้นสุด", "error");

      if (
        !this.formUpdate.lotto_credit ||
        this.formUpdate.lotto_credit < 1 ||
        isNaN(this.formUpdate.lotto_credit)
      )
        return Swal.fire("แจ้งเตือน", "กรุณากรอกเครดิตที่ได้รับ", "error");

      const formData = this.formUpdate;

      let config = {
        method: "post",
        maxBodyLength: Infinity,
        url: `${this.$hostname}/update_lotto`,
        headers: {
          authorization: this.$cookies.get("token"),
          "Content-Type": "application/json",
        },
        data: formData,
      };

      this.loading = true;

      await axios
        .request(config)
        .then((res) => {
          this.loading = false;
          let data = res.data;
          if (data.res_code == "00") {
            this.tableData = [];
            this.getLotto();
            this.setNull();
            this.modals["modalUpdateLotto"] = false;

            Swal.fire({
              title: "แจ้งเตือน",
              text: data.res_text,
              icon: "success",
              timer: 1500,
              showConfirmButton: false,
            });
          } else {
            this.loading = false;
            return Swal.fire("แจ้งเตือน", data.res_text, "error");
          }
        })
        .catch((error) => {
          this.loading = false;
        });
    },

    async getLotto() {
      let config = {
        method: "get",
        maxBodyLength: Infinity,
        url: `${this.$hostname}/get_lotto?search=&sort=&order=desc&offset=0&limit=10`,
        headers: {
          authorization: this.$cookies.get("token"),
        },
      };
      this.loading = true;
      await axios
        .request(config)
        .then((res) => {
          this.loading = false;
          const data = res.data;
          if (data.res_code === "00") {
            this.tableData = [];
            this.tableData = data.res_result.rows;
            this.pagination.total = data.res_result.total;
          }
        })
        .catch((error) => {
          this.loading = false;
        });
    },

    updateLottoNumber(lotto_id, lotto_length, lotto_date, lotto_credit) {
      this.formUpdateNumber.lotto_id = lotto_id;
      this.showLottoDetail.lotto_length = lotto_length;
      this.showLottoDetail.lotto_date = lotto_date;
      this.showLottoDetail.lotto_credit = lotto_credit;
      this.modals["modalUpdateLottoNumber"] = true;
    },

    async submitUpdateLottoNumber() {
      if (
        !this.formUpdateNumber.lotto_number ||
        isNaN(this.formUpdateNumber.lotto_number)
      )
        return Swal.fire("แจ้งเตือน", "กรุณากรอกเลขที่ออก", "error");

      const formData = this.formUpdateNumber;

      let config = {
        method: "post",
        maxBodyLength: Infinity,
        url: `${this.$hostname}/update_lotto_number`,
        headers: {
          authorization: this.$cookies.get("token"),
          "Content-Type": "application/json",
        },
        data: formData,
      };

      this.loading = true;

      await axios
        .request(config)
        .then((res) => {
          this.loading = false;
          let data = res.data;
          if (data.res_code == "00") {
            this.tableData = [];
            this.getLotto();
            this.modals["modalUpdateLottoNumber"] = false;

            Swal.fire({
              title: "แจ้งเตือน",
              text: data.res_text,
              icon: "success",
              timer: 1500,
              showConfirmButton: false,
            });
          } else {
            this.loading = false;
            return Swal.fire("แจ้งเตือน", data.res_text, "error");
          }
        })
        .catch((error) => {
          this.loading = false;
        });
    },

    deleteLotto(lotto_id) {
      Swal.fire({
        title: "แจ้งเตือน",
        text: "ยืนยันลบกิจกรรม",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "ยืนยัน",
        cancelButtonText: "ยกเลิก",
      }).then(async (result) => {
        if (result.isConfirmed) {
          let data = JSON.stringify({
            lotto_id: lotto_id,
          });

          let config = {
            method: "post",
            maxBodyLength: Infinity,
            url: `${this.$hostname}/detele_lotto`,
            headers: {
              authorization: this.$cookies.get("token"),
              "Content-Type": "application/json",
            },
            data: data,
          };

          this.loading = true;

          await axios
            .request(config)
            .then((res) => {
              this.loading = false;
              let data = res.data;
              if (data.res_code == "00") {
                this.tableData = [];
                this.getLotto();
                Swal.fire({
                  title: "แจ้งเตือน",
                  text: data.res_text,
                  icon: "success",
                  timer: 1500,
                  showConfirmButton: false,
                });
              } else {
                this.loading = false;
                return Swal.fire("แจ้งเตือน", data.res_text, "error");
              }
            })
            .catch((error) => {
              this.loading = false;
            });
        }
      });
    },
  },
  watch: {},
};
</script>
<style></style>
