<template>
  <div class="row">
    <div class="col-12">
      <div class="row">
        <div class="col-12 col-sm-6 col-md-4 col-lg-3">
          <div class="d-flex mb-2">
            <span class="my-auto mr-2" style="width: 30px">เริ่ม</span>
            <fg-input class="m-0 w-100">
              <el-date-picker
                v-model="startDate"
                type="date"
                placeholder="วันที่เริ่ม"
                format="dd/MM/yyyy"
                value-format="yyyy-MM-dd"
                @change="getDepositError()"
              >
              </el-date-picker>
            </fg-input>
          </div>
        </div>
        <div class="col-12 col-sm-6 col-md-4 col-lg-3">
          <div class="d-flex mb-2">
            <span class="my-auto mr-2" style="width: 30px">ถึง</span>
            <fg-input class="m-0 w-100">
              <el-date-picker
                v-model="endDate"
                type="date"
                placeholder="ถึง"
                format="dd/MM/yyyy"
                value-format="yyyy-MM-dd"
                @change="getDepositError()"
              >
              </el-date-picker>
            </fg-input>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-12 col-sm-4 col-md-3 col-lg-2 d-flex mb-3">
          <el-select
            class="select-default w-100"
            v-model="pagination.perPage"
            placeholder="Per page"
          >
            <el-option
              class="select-default"
              v-for="item in pagination.perPageOptions"
              :key="item"
              :label="item"
              :value="item"
            >
            </el-option>
          </el-select>
        </div>
        <div class="col-12 col-sm-6 col-md-4 col-lg-3 ml-auto d-flex mb-3">
          <el-input
            type="search"
            class="my-auto mr-2"
            placeholder="ค้นหา"
            v-model="searchQuery"
            aria-controls="datatables"
          />

          <el-button
            type="danger"
            size="default"
            @click="getDepositError()"
            :loading="loading"
          >
            <i class="fa fa-refresh" aria-hidden="true"></i>
          </el-button>
        </div>
      </div>
      <div class="row">
        <div class="col-sm-12">
          <el-table
            stripe
            style="width: 100%"
            :data="tableData"
            border
            v-loading="loading"
          >
            <el-table-column :min-width="75" label="ลำดับ">
              <template v-slot="props">
                <span v-html="props ? props.row.D0 + 1 : ''"> </span>
              </template>
            </el-table-column>
            <el-table-column :min-width="100" label="วันที่/เข้าร่วม">
              <template v-slot="props">
                <span v-html="props ? props.row.D1 : ''"> </span>
              </template>
            </el-table-column>
            <el-table-column :min-width="100" label="ยอดเงิน">
              <template v-slot="props">
                <span
                  v-html="
                    props
                      ? Number(props.row.D2).toLocaleString(undefined, {
                          minimumFractionDigits: 2,
                        })
                      : ''
                  "
                >
                </span>
              </template>
            </el-table-column>
            <!-- <el-table-column :min-width="100" label="Username">
                  <template v-slot="props">
                    <span v-html="props ? props.row.D3 : ''"> </span>
                  </template>
                </el-table-column> -->
            <el-table-column :min-width="100" label="เลขบัญชี">
              <template v-slot="props">
                <span v-html="props ? props.row.D4 : ''"> </span>
              </template>
            </el-table-column>
            <el-table-column :min-width="100" label="ช่องทาง">
              <template v-slot="props">
                <span v-html="props ? props.row.D5 : ''"> </span>
              </template>
            </el-table-column>
            <el-table-column :min-width="100" label="หมายเหตุ">
              <template v-slot="props">
                <span v-html="props ? props.row.D6 : ''"> </span>
              </template>
            </el-table-column>
            <el-table-column :min-width="150" label="จัดการ">
              <template v-slot="props">
                <div
                  v-if="props"
                  class="d-flex flex-nowrap justify-content-center"
                >
                  <button
                    v-if="props.row.refId"
                    @click="
                      checkHistoryDeposit(props.row.phone, props.row.refId)
                    "
                    type="button"
                    class="btn btn-warning btn-sm my-auto mr-1"
                  >
                    <span>ตรวจสอบ</span>
                  </button>
                  <button
                    @click="editHistoryWithdraw(props.row.id)"
                    type="button"
                    class="btn btn-success btn-sm my-auto mr-1"
                  >
                    <i class="fa fa-plus" aria-hidden="true"></i>
                    <span>เติมเงิน</span>
                  </button>
                  <button
                    @click="updateDepositPayment(props.row.id)"
                    type="button"
                    class="btn btn-info btn-sm my-auto mr-1"
                  >
                    <i class="fa fa-plus" aria-hidden="true"></i>
                    <span>สำเร็จ</span>
                  </button>
                  <button
                    @click="deleteDeposit(props.row.id)"
                    type="button"
                    class="btn btn-danger btn-sm my-auto"
                  >
                    <i class="fa fa-trash" aria-hidden="true"></i>
                    <span>ลบ</span>
                  </button>
                </div>
              </template>
            </el-table-column>
          </el-table>
        </div>
      </div>
      <hr>
      <div
        slot="footer"
        class="col-12 d-flex justify-content-center justify-content-sm-between flex-wrap"
      >
        <div class="">
          <p class="card-category">
            Showing {{ from + 1 }} to {{ to }} of {{ total }} entries
          </p>
        </div>
        <l-pagination
          class="pagination-no-border"
          v-model="pagination.currentPage"
          :per-page="pagination.perPage"
          :total="pagination.total"
        >
        </l-pagination>
      </div>

      <el-dialog
        :visible.sync="modals.modalHistoryWithdraw"
        :lock-scroll="false"
        width="30%"
      >
        <span slot="title">
          <i class="fa fa-edit" aria-hidden="true"></i>
          แก้ไขรายการ
        </span>
        <div>
          <form>
            <div>
              <fg-input
                v-model="formUpdate.phone"
                type="text"
                placeholder="กรอกเบอร์โทรศัพท์"
                @change="getMemberUpdateByPhone()"
              >
                <span slot="label"> เบอร์โทร </span>
              </fg-input>
            </div>
            <div>
              <fg-input
                v-model="formCredit.fname"
                type="text"
                placeholder="กรอกชื่อ-สกุล"
                disabled
              >
                <span slot="label"> ชื่อ-สกุล </span>
              </fg-input>
            </div>
          </form>
        </div>
        <span slot="footer" class="dialog-footer" v-loading="loading">
          <button class="btn btn-success mr-2" @click="updateDeposit()">
            อัพเดท
          </button>
          <button
            class="btn btn-danger"
            @click="closeModal('modalHistoryWithdraw')"
          >
            ยกเลิก
          </button>
        </span>
      </el-dialog>
      <el-dialog
        :visible.sync="modals.modalUpdateDepositPayment"
        :lock-scroll="false"
        width="30%"
      >
        <span slot="title">
          <i class="fa fa-edit" aria-hidden="true"></i>
          แก้ไขรายการ
        </span>
        <div>
          <form>
            <div>
              <fg-input
                v-model="formUpdate.phone"
                type="text"
                placeholder="กรอกเบอร์โทรศัพท์"
                @change="getMemberUpdateByPhone()"
              >
                <span slot="label"> เบอร์โทร </span>
              </fg-input>
            </div>
            <div>
              <fg-input
                v-model="formCredit.fname"
                type="text"
                placeholder="กรอกชื่อ-สกุล"
                disabled
              >
                <span slot="label"> ชื่อ-สกุล </span>
              </fg-input>
            </div>
          </form>
        </div>
        <span slot="footer" class="dialog-footer" v-loading="loading">
          <button
            class="btn btn-success mr-2"
            @click="submitUpdateDepositPayment()"
          >
            อัพเดท
          </button>
          <button
            class="btn btn-danger"
            @click="closeModal('modalUpdateDepositPayment')"
          >
            ยกเลิก
          </button>
        </span>
      </el-dialog>
    </div>
  </div>
</template>
<script>
import {
  Table,
  TableColumn,
  Select,
  Option,
  DatePicker,
  Button,
  Dialog,
} from "element-ui";
import { Pagination as LPagination } from "src/components/index";
import moment from "moment";
import axios from "axios";
import Swal from "sweetalert2";

export default {
  components: {
    LPagination,
    [Select.name]: Select,
    [Option.name]: Option,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    [DatePicker.name]: DatePicker,
    [Button.name]: Button,
    [Dialog.name]: Dialog,
  },
  data() {
    return {
      startDate: moment(new Date()).format("YYYY-MM-DD"),
      endDate: moment(new Date()).format("YYYY-MM-DD"),

      pagination: {
        perPage: 10,
        currentPage: 1,
        perPageOptions: [5, 10, 25, 50],
        total: 0,
      },

      tableData: [],

      searchQuery: "",
      loading: false,
      timeoutDepositError: "",

      modals: {
        modalUpdateDepositPayment: false,
        modalHistoryWithdraw: false,
        modalAddCredit: false,
        modalCheckQrcode: false,
        centered: false,
        custom: false,
        confirm: false,
      },

      formUpdate: {
        id: "",
        phone: "",
        memberid: "",
      },

      formCredit: {
        fname: "",
        bank_number: "",
        deposit: 0,
        time: "",
        date: "",
        info: "",
        type: "",
        phone: "",
        detail: "",
      },
    };
  },
  computed: {
    to() {
      let highBound = this.from + this.pagination.perPage;
      if (this.total < highBound) {
        highBound = this.total;
      }
      return highBound;
    },
    from() {
      this.getDepositError();
      return this.pagination.perPage * (this.pagination.currentPage - 1);
    },
    total() {
      return this.pagination.total;
    },
  },
  methods: {
    async checkHistoryDeposit(phone, refId) {
      let data = JSON.stringify({
        phone: phone,
        refId: refId,
      });

      let config = {
        method: "post",
        maxBodyLength: Infinity,
        url: `${this.$hostname}/getTransactionByRefId`,
        headers: {
          authorization: this.$cookies.get("token"),
          "Content-Type": "application/json",
        },
        data: data,
      };

      this.loading = true;

      await axios
        .request(config)
        .then((res) => {
          const data = res.data;
          this.loading = false;
          this.getDepositError();

          if (data.res_code === "00") {
            if (data.res_result.data.transaction === "success") {
              return Swal.fire({
                title: "แจ้งเตือน",
                text: "เติม AG แล้ว -> กดปุ่ม สำเร็จ",
                icon: "success",
              });
            } else if (data.res_result.data.transaction === "pending") {
              return Swal.fire({
                title: "แจ้งเตือน",
                text: "เติมไม่สำเร็จ",
                icon: "error",
              });
            } else {
              return Swal.fire({
                title: "แจ้งเตือน",
                text: JSON.stringify(data.res_result.data),
                icon: "error",
              });
            }
          } else {
            return Swal.fire("แจ้งเตือน", data.res_result, "error");
          }
        })
        .catch((error) => {
          this.loading = false;
          return Swal.fire("แจ้งเตือน", error.message, "error");
        });
    },
    async submitUpdateDepositPayment() {
      if (
        !this.formUpdate.id ||
        !this.formUpdate.phone ||
        !this.formUpdate.memberid
      ) {
        return Swal.fire("แจ้งเตือน", "กรุณากรอกข้อมูลให้ครบ", "error");
      }

      let data = JSON.stringify({
        id: this.formUpdate.id,
        memberid: this.formUpdate.memberid,
        phone: this.formUpdate.phone,
      });

      let config = {
        method: "post",
        maxBodyLength: Infinity,
        url: `${this.$hostname}/update_deposit_admin`,
        headers: {
          authorization: this.$cookies.get("token"),
          "Content-Type": "application/json",
        },
        data: data,
      };

      this.loading = true;

      await axios
        .request(config)
        .then((res) => {
          const data = res.data;
          this.loading = false;
          if (data.res_code === "00") {
            this.getDepositError();
            Swal.fire({
              title: "แจ้งเตือน",
              text: data.res_text,
              icon: "success",
              timer: 1500,
              showConfirmButton: false,
            }).then(() => {
              Swal.close();
            });

            this.formUpdate.id = "";
            this.formUpdate.fname = "";
            this.formUpdate.phone = "";
            this.formUpdate.memberid = "";
            this.modals["modalUpdateDepositPayment"] = false;

            this.getDepositError();
          } else {
            return Swal.fire("แจ้งเตือน", data.res_text, "error");
          }
        })
        .catch((error) => {});
    },
    updateDepositPayment(id) {
      this.formUpdate.id = id;
      this.modals["modalUpdateDepositPayment"] = true;
    },
    async getMemberUpdateByPhone() {
      let data = JSON.stringify({
        phone: this.formUpdate.phone,
      });

      let config = {
        method: "post",
        maxBodyLength: Infinity,
        url: `${this.$hostname}/getmemberbyphone`,
        headers: {
          authorization: this.$cookies.get("token"),
          "Content-Type": "application/json",
        },
        data: data,
      };

      axios
        .request(config)
        .then((res) => {
          let data = res.data.res_result;
          this.formCredit.fname = data.fname;
          this.formCredit.bank_number = data.bank_number;
          this.formCredit.info = data.bank_name;

          this.formUpdate.fname = data.fname;
          this.formUpdate.memberid = data.id;
        })
        .catch((error) => {});
    },
    async updateDeposit() {
      if (!this.formUpdate.id || !this.formUpdate.phone) {
        return Swal.fire("แจ้งเตือน", "กรุณากรอกข้อมูลให้ครบ", "error");
      }

      let data = JSON.stringify({
        id: this.formUpdate.id,
        phone: this.formUpdate.phone,
      });

      let config = {
        method: "post",
        maxBodyLength: Infinity,
        url: `${this.$hostname}/update_deposit`,
        headers: {
          authorization: this.$cookies.get("token"),
          "Content-Type": "application/json",
        },
        data: data,
      };

      this.loading = true;

      await axios
        .request(config)
        .then((res) => {
          const data = res.data;
          this.loading = false;
          if (data.res_code === "00") {
            this.getDepositError();
            Swal.fire({
              title: "แจ้งเตือน",
              text: data.res_text,
              icon: "success",
              timer: 1500,
              showConfirmButton: false,
            }).then(() => {
              Swal.close();
            });

            this.formUpdate.id = "";
            this.formUpdate.fname = "";
            this.formUpdate.phone = "";
            this.modals["modalHistoryWithdraw"] = false;
            this.getDepositError();
          } else {
            return Swal.fire("แจ้งเตือน", data.res_text, "error");
          }
        })
        .catch((error) => {});
    },
    deleteDeposit(id) {
      Swal.fire({
        title: "แจ้งเตือน",
        text: "ยืนยันการลบรายการ",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "ยืนยัน",
        cancelButtonText: "ยกเลิก",
      }).then(async (result) => {
        if (result.isConfirmed) {
          let data = JSON.stringify({
            id: id,
          });

          let config = {
            method: "post",
            maxBodyLength: Infinity,
            url: `${this.$hostname}/delete_deposit`,
            headers: {
              authorization: this.$cookies.get("token"),
              "Content-Type": "application/json",
            },
            data: data,
          };

          await axios
            .request(config)
            .then((res) => {
              const data = res.data;
              if (data.res_code === "00") {
                this.getDepositError();
                Swal.fire({
                  title: "แจ้งเตือน",
                  text: data.res_text,
                  icon: "success",
                  timer: 1500,
                  showConfirmButton: false,
                });
              } else {
                return Swal.fire("แจ้งเตือน", data.res_text, "error");
              }
            })
            .catch((error) => {});
        }
      });
    },
    editHistoryWithdraw(id) {
      this.formUpdate.id = id;
      this.modals["modalHistoryWithdraw"] = true;
    },
    confirmClose() {
      Swal.fire({
        title: "แจ้งเตือน",
        text: "ต้องการยกเลิกการทำรายการ?",
        icon: "question",
        showCancelButton: true,
        confirmButtonClass: "btn btn-danger",
        cancelButtonClass: "btn btn-warning",
        confirmButtonText: "ยืนยัน",
        cancelButtonText: "ดำเนินการต่อ",
        buttonsStyling: false,
      }).then((result) => {
        if (result.isConfirmed) {
          this.modals["modalAddCredit"] = false;
          this.modals["modalHistoryWithdraw"] = false;
        }
      });
    },

    openModal(name) {
      this.modals[name] = true;
    },
    closeModal(name) {
      this.modals[name] = false;
    },
    async getDepositError() {
      this.loading = true;
      let offset = this.pagination.perPage * (this.pagination.currentPage - 1);

      let config = {
        method: "get",
        maxBodyLength: Infinity,
        url: `${this.$hostname}/depositerror?search=${this.searchQuery}&sort&order=desc&offset=${offset}&limit=${this.pagination.perPage}&action&start_date=${this.startDate}&end_date=${this.endDate}`,
        headers: {
          Authorization: this.$cookies.get("token"),
        },
        timeout: 5000,
      };

      clearTimeout(this.timeoutDepositError);

      this.timeoutDepositError = setTimeout(async () => {
        await axios
          .request(config)
          .then((res) => {
            this.tableData = [];
            this.loading = false;
            const data = res.data.res_result;
            this.tableData = data.rows;
            this.pagination.total = data.total;
          })
          .catch((error) => {
            this.loading = false;
          });
      }, 1000);
    },
  },
};
</script>
<style></style>
