<template>
  <div v-if="!this.$func.checkPermission('statement')" class="row">
    <div class="col">
      <div class="card">
        <div class="card-body">
          <div class="alert alert-danger" role="alert">
            <strong>คุณไม่มีสิทธิ์เข้าถึงหน้านี้</strong>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div v-else>
    <div class="row">
      <div
        class="col-12 col-sm-6 col-md-6 col-lg-4"
        v-for="bank in banks"
        :key="bank.id"
      >
        <div class="card mb-2" v-loading="loadingBank">
          <div class="card-body row">
            <div class="col-4 col-sm-3 d-flex">
              <img
                src="/static/img/bank-scb.png"
                alt=""
                class="mw-100 rounded m-auto"
              />
            </div>
            <div class="col">
              <div class="d-flex">
                <span v-if="bank.D7 == 1" class="badge my-auto badge-warning">
                  APPLICATION
                </span>
                <span v-else class="badge my-auto badge-info"> SMS ALERT </span>
                <div
                  @click="getBalance(bank.number)"
                  role="button"
                  class="bg-primary text-white rounded-pill ml-auto cursor-poiter d-flex"
                  style="width: 25px; height: 25px"
                >
                  <i
                    class="fa fa-refresh m-auto"
                    aria-hidden="true"
                    :id="'spin_' + bank.number"
                  ></i>
                </div>
              </div>
              <hr class="m-1" />
              <div>
                {{ `${bank.fname} ${bank.lname}` }} |
                <b>{{ bank.number }}</b>
              </div>
              <div></div>
              <hr class="m-1" />
              <!-- <div>ยอดคงเหลือ:</div> -->
              <div>
                <h4 class="m-0 fw-bold pt-2">
                  <span :id="`balance_${bank.number}`">{{
                    Number(bank.total).toLocaleString(undefined, {
                      minimumFractionDigits: 2,
                    })
                  }}</span>
                </h4>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <card>
          <div slot="header">
            <h4 class="m-0">ระบบดึงรายการเคลื่อนไหวบัญชี</h4>
          </div>
          <div class="row">
            <div class="col-12 mb-3">
              <div class="row">
                <div class="col-sm-3 col-md-4 col-lg-3 ml-auto">
                  <el-select
                    class="select-default w-100"
                    v-model="status"
                    placeholder="ทั้งหมด"
                    onchange="getStatement()"
                  >
                    <el-option
                      class="select-default"
                      v-for="item in statusOption"
                      :key="item.value"
                      :label="item.label"
                      :value="item.value"
                    >
                    </el-option>
                  </el-select>
                </div>
              </div>
            </div>
            <div class="col-sm-3 col-md-4 col-lg-2 d-inline-block">
              <el-select
                class="select-default mb-3 w-100"
                v-model="pagination.perPage"
                placeholder="Per page"
              >
                <el-option
                  class="select-default"
                  v-for="item in pagination.perPageOptions"
                  :key="item"
                  :label="item"
                  :value="item"
                >
                </el-option>
              </el-select>
            </div>
            <div class="col-sm-5 col-md-4 col-lg-3 ml-auto d-flex mb-3">
              <el-input
                type="search"
                class="my-auto mr-2"
                placeholder="ค้นหา"
                v-model="searchQuery"
                aria-controls="datatables"
              />
              <el-button
                type="danger"
                size="medium"
                @click="getStatement()"
                :loading="loading"
                ><i class="fa fa-refresh" aria-hidden="true"></i
              ></el-button>
            </div>
          </div>
          <div class="row">
            <div class="col-sm-12">
              <el-table stripe :data="tableData" border v-loading="loading">
                <el-table-column :min-width="60" label="ลำดับ">
                  <template v-slot="props">
                    <span v-html="props ? props.row.D0 : ''"></span>
                  </template>
                </el-table-column>
                <el-table-column :min-width="200" label="รายละเอียด">
                  <template v-slot="props">
                    <span v-html="props ? props.row.D1 : ''"></span>
                  </template>
                </el-table-column>
                <el-table-column :min-width="100" label="จาก">
                  <template v-slot="props">
                    <span v-html="props ? props.row.D4 : ''"></span>
                  </template>
                </el-table-column>
                <el-table-column :min-width="100" label="เวลาเติม">
                  <template v-slot="props">
                    <span v-html="props ? props.row.D2 : ''"></span>
                  </template>
                </el-table-column>

                <el-table-column :min-width="100" label="เวลาบันทึก">
                  <template v-slot="props">
                    <span v-html="props ? props.row.D3 : ''"></span>
                  </template>
                </el-table-column>
              </el-table>
            </div>
          </div>
          <div
            slot="footer"
            class="col-12 d-flex justify-content-center justify-content-sm-between flex-wrap"
          >
            <div class="">
              <p class="card-category">
                Showing {{ from + 1 }} to {{ to }} of {{ total }} entries
              </p>
            </div>
            <l-pagination
              class="pagination-no-border"
              v-model="pagination.currentPage"
              :per-page="pagination.perPage"
              :total="pagination.total"
            >
            </l-pagination>
          </div>
        </card>
      </div>
    </div>
  </div>
</template>
<script>
import { Table, TableColumn, Select, Option, Button } from "element-ui";
import { Pagination as LPagination } from "src/components/index";
import axios from "axios";
import Swal from "sweetalert2";
export default {
  components: {
    LPagination,
    [Button.name]: Button,
    [Select.name]: Select,
    [Option.name]: Option,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
  },
  data() {
    return {
      loadingBank: true,
      banks: [],
      loading: true,
      status: 1,
      statusOption: [
        { value: 1, label: "SMS" },
        { value: 2, label: "APP" },
      ],
      pagination: {
        perPage: 10,
        currentPage: 1,
        perPageOptions: [10, 20, 30, 50],
        total: 0,
      },
      searchQuery: "",
      propsToSearch: ["username"],
      tableColumns: [],
      tableData: [],
      fuseSearch: null,
      timeGetStatement: "",
    };
  },
  computed: {
    queriedData() {
      let result = this.tableData;
      return result.slice(this.from, this.to);
    },
    to() {
      let highBound = this.from + this.pagination.perPage;
      if (this.total < highBound) {
        highBound = this.total;
      }
      return highBound;
    },
    from() {
      this.getStatement();
      return this.pagination.perPage * (this.pagination.currentPage - 1);
    },
    total() {
      return this.pagination.total;
    },
  },
  methods: {
    async getStatement() {
      let offset = this.pagination.perPage * (this.pagination.currentPage - 1);
      let config = {
        method: "get",
        maxBodyLength: Infinity,
        url: `${this.$hostname}/statement?search=&sort&order=desc&offset=${offset}&limit=${this.pagination.perPage}&action&start_date=&end_date=&id=${this.status}`,
        headers: {
          authorization: this.$cookies.get("token"),
        },
      };

      clearTimeout(this.timeGetStatement);

      this.timeGetStatement = setTimeout(async () => {
        await axios
          .request(config)
          .then((res) => {
            this.loading = false;
            this.tableData = [];
            const data = res.data;
            if (data.res_code === "00") {
              this.tableData = data.res_result.rows;
              this.pagination.total = data.res_result.total;
            }
          })
          .catch((error) => {
            this.loading = false;
          });
      }, 200);
    },
    async getBank() {
      this.loadingBank = true;
      let config = {
        method: "get",
        maxBodyLength: Infinity,
        url: `${this.$hostname}/getbank?search=&sort&order=desc&offset=0&limit=10&action&start_date=&end_date=`,
        headers: {
          authorization: this.$cookies.get("token"),
        },
      };

      await axios
        .request(config)
        .then((res) => {
          this.loadingBank = false;
          let data = res.data;
          if (data.res_code === "00") {
            this.banks = [];
            // this.banks = data.res_result.rows;

            data.res_result.rows.forEach((bank) => {
              if (bank.m_deposit != 0 || bank.m_withdraw != 0) {
                this.banks.push(bank);
              }
            });
          }
        })
        .catch((error) => {
          this.loadingBank = false;
        });
    },
    async getBalance(number) {
      let spin = document.getElementById("spin_" + number);
      spin.classList.add("fa-spin");

      let data = JSON.stringify({
        number: number,
      });

      let config = {
        method: "post",
        maxBodyLength: Infinity,
        url: `${this.$hostname}/get_balance`,
        headers: {
          authorization: this.$cookies.get("token"),
          "Content-Type": "application/json",
        },
        data: data,
      };

      await axios
        .request(config)
        .then((res) => {
          spin.classList.remove("fa-spin");
          let data = res.data;
          if (data.res_code === "00") {
            let balance = document.getElementById("balance_" + number);
            balance.innerHTML = data.res_result.toLocaleString(undefined, {
              minimumFractionDigits: 2,
            });
          }
        })
        .catch((error) => {
          spin.classList.remove("fa-spin");
          // return Swal.fire("แจ้งเตือน", "ไม่สามารถดึงข้อมูลได้", "error");
        });
    },
  },
  async mounted() {
    await this.getBank();

    // if (this.banks) {
    //   this.banks.forEach(async (bank) => {
    //     await this.getBalance(bank.number);
    //   });
    // }
  },
  watch: {
    searchQuery(newSearch, oldSearch) {
      this.searchQuery = newSearch;
    },
  },
};
</script>
<style scoped>
.fa-refresh {
  width: 22px;
}
</style>
